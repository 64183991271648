import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function quoteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, orderBeingRead: action.subId, order: null, orderError: false };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, orderBeingRead: null, order: action.data };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, orderBeingRead: null, order: null, orderError: true };

        case getNamedAction(ACTION_NAMES.RECENT_ORDER, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingRecentOrder: true, recentOrder: null };
        case getNamedAction(ACTION_NAMES.RECENT_ORDER, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingRecentOrder: false, recentOrder: action.data };
        case getNamedAction(ACTION_NAMES.RECENT_ORDER, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingRecentOrder: false, recentOrder: null };

        case getNamedAction(ACTION_NAMES.ORDER_ADDRESSES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, orderBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.ORDER_ADDRESSES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, orderBeingUpdated: null, order: { ...state.order, billing_address: action.body.billing, shipping_address: action.body.shipping } };
        case getNamedAction(ACTION_NAMES.ORDER_ADDRESSES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, orderBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, orderBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, orderBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.ORDER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, orderBeingUpdated: null };

        default:
            return state;
    }
}


export default quoteReducer;
