import React from 'react';
import { Table, ControlledPopup, STATUS_TYPES, Notification, StatusLabel } from 'dyl-components';
import { Popup, Icon } from 'semantic-ui-react';

import RingGroupEditModal from '../RingGroupEditModal';
import { connect } from 'react-redux';

import callQueuesActions from 'actions/call_queues';

const CallQueueRow = ({ queue, onOpenCallQueueEditModal, onDelete, callQueueBeingDeleted }) => (
    <Table.Row>
        <Table.Cell>
            {queue.label}
        </Table.Cell>
        <Table.Cell>
            {queue.members && queue.members.data ? queue.members.data.map(({ member }) => member).join(', ') : ''}
        </Table.Cell>
        <Table.Cell>
            {queue.members && queue.members.data ? queue.members.data.map(({ member, active }) => <StatusLabel
                text={member}
                type={active ? 'success' : 'error'}
            />) : ''}
        </Table.Cell>
        <Table.Cell>
            <Popup
                trigger={(
                    <Icon
                        name='cog'
                        size='large'
                        color='blue'
                        link
                        onClick={onOpenCallQueueEditModal}
                    />
                )}
                content={'Edit Call Queue'}
                inverted
            />
            {queue.members && queue.members.data && queue.members.data.length > 0 ? (
                <Popup
                    trigger={<span>
                        <Icon
                            name='trash'
                            size='large'
                            color='blue'
                            link
                            disabled
                        />
                    </span>}
                    content="Cannot delete queue. It has members"
                    inverted
                />
            ) : (
                    <ControlledPopup
                        trigger={<Icon
                            name='trash'
                            size='large'
                            color='blue'
                            link
                        />}
                        onConfirm={() => { onDelete(queue.id) }}
                        content={'Delete'}
                        inverted
                    />
                )}
            {callQueueBeingDeleted === queue.id && <Icon name='circle notched' loading />}
        </Table.Cell>
    </Table.Row>
)

class CallQueueRowContainer extends React.Component {
    state = {
        isCallQueueEditModalOpen: false
    }

    onOpenCallQueueEditModal = () => {
        this.setState({ isCallQueueEditModalOpen: true });
    }

    onCloseCallQueueEditModal = () => {
        this.setState({ isCallQueueEditModalOpen: false });
    }

    onDelete = (id) => {
        this.props.onDelete(this.props.domain, id).then(() => {
            Notification.alert('Successfully deleted call queue', STATUS_TYPES.SUCCESS, true);
            this.props.onReadCallQueues(this.props.domain);
        }).catch(() => {
            Notification.alert('Failed to delete call queue', STATUS_TYPES.ERROR, true);
        })
    }

    render() {
        return (
            <React.Fragment>
                <CallQueueRow
                    queue={this.props.queue}
                    onOpenCallQueueEditModal={this.onOpenCallQueueEditModal}
                    onDelete={this.onDelete}
                    callQueueBeingDeleted={this.props.callQueueBeingDeleted}
                />
                <RingGroupEditModal
                    group={{ ...this.props.queue }}
                    onClose={this.onCloseCallQueueEditModal}
                    open={this.state.isCallQueueEditModalOpen}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    domain: state.pbx_config.domain,
    callQueueBeingDeleted: state.call_queue.callQueueBeingDeleted
})

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, call_queue) => {
        return dispatch(callQueuesActions.deleteCallQueue(domain, null, call_queue));
    },
    onReadCallQueues: (domain) => {
        dispatch(callQueuesActions.readCallQueues(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CallQueueRowContainer);
