import React from 'react';
import { STATUS_TYPES, Table, Notification, TableLoader } from 'dyl-components';
import PhoneExtensionRow from './PhoneExtensionRow';

import { connect } from 'react-redux';

import EditExtensionModal from './EditExtensionModal';
import pbxConfigActions from 'actions/pbx_config';

const PhoneExtensionsTable = ({ extensions, isVoicemailExitEnabled, isReading, onEdit }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Ext.</Table.HeaderCell>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Voicemail</Table.HeaderCell>
                <Table.HeaderCell>PIN</Table.HeaderCell>
                {isVoicemailExitEnabled && (<Table.HeaderCell>Voicemail Exit</Table.HeaderCell>)}
                <Table.HeaderCell>Device</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <TableLoader isLoading={isReading} colspan={isVoicemailExitEnabled ? 7 : 6} />
            {!isReading && extensions.map(extension => (
                <PhoneExtensionRow
                    key={extension.number}
                    extension={extension}
                    isVoicemailExitEnabled={isVoicemailExitEnabled}
                    onEdit={onEdit}
                />
            ))}
        </Table.Body>
    </Table>
);

class PhoneExtensionsTableContainer extends React.Component {
    state = {
        extensionBeingEdited: null
    }

    onEdit = (extension) => {
        this.setState({ extensionBeingEdited: { ...extension } });
    }

    onCancelEdit = () => {
        this.setState({ extensionBeingEdited: null })
    }

    onChange = (_, { name, value }) => {
        const extensionBeingEdited = { ...this.state.extensionBeingEdited, [name]: value };
        this.setState({ extensionBeingEdited });
    }

    onUpdate = () => {
        const {
            extension_number,
            location,
            voicemail_box_id,
            user_id,
            callerid,
            id
        } = this.state.extensionBeingEdited;
        const payload = {
            location: location.id ? location.id : null,
            extension_number,
            callerid,
            voicemail_box_id,
            create_voicemail: true,
            user_id
        }
        this.props.onUpdate(this.props.domain, payload, id).then(() => {
            this.setState({
                extensionBeingEdited: null,
            });
            this.props.onReadExtensions(this.props.domain);
            Notification.alert('Successfully updated extension', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to update extension', STATUS_TYPES.ERROR, true);
        })
    }

    render() {

        return (
            <React.Fragment>
                <PhoneExtensionsTable
                    devices={this.props.devices}
                    extensions={this.props.extensions}
                    isVoicemailExitEnabled={this.props.isVoicemailExitEnabled}
                    isReading={this.props.isReading}
                    onEdit={this.onEdit}
                    onCancelEdit={this.onCancelEdit}
                />
                {this.state.extensionBeingEdited && <EditExtensionModal
                    extension={this.state.extensionBeingEdited}
                    open={this.state.extensionBeingEdited !== null}
                    onChange={this.onChange}
                    onClose={this.onCancelEdit}
                    onUpdate={this.onUpdate}
                />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    extensions: state.pbx_config.extensions.map(({ voicemail, ...extension }) => ({
        ...extension,
        voicemail_box_id: voicemail.id,
        voicemail_box: voicemail.box
    })),
    isReading: state.pbx_config.isReadingDomains || state.pbx_config.isReadingExtensions,
    isReadingDomains: state.pbx_config.isReadingDomains,
    domain: state.pbx_config.domain
});

const mapDispatchToProps = dispatch => ({
    onUpdate: (domain, payload, id) => {
        return dispatch(pbxConfigActions.updateExtension(domain, payload, null, id));
    },
    onReadExtensions: (domain) => {
        dispatch(pbxConfigActions.getExtensions(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PhoneExtensionsTableContainer);
