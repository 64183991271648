import React, { useContext } from 'react';
import { Table, EllipsisDropdown, Person, ControlledPopup } from 'dyl-components';
import { Checkbox, DropdownItem, Header, Icon } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { PhoneUtil } from 'utils';

const Row = ({ device = {}, onOpenModal, onEditDevice, onDeleteDevice }) => {
    const { id, type, model, ip_addr, user, extension, label, mac_addr, active, location } = device;
    const [selectedDevices, setSelectedDevices] = useContext(BulkActionsContext);

    const isSelected = (device_id) => {
        return selectedDevices.includes(device_id);
    }

    const onToggleDevice = (device_id) => {
        const newSelectedDevices = isSelected(device_id) ? selectedDevices.filter(id => device_id !== id) : [...selectedDevices, device_id];
        setSelectedDevices(newSelectedDevices);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(id)} onChange={() => { onToggleDevice(id) }} />
            </Table.Cell>
            <Table.Cell>
                {PhoneUtil.DEVICE_TYPES_OPTIONS.find((option) => option.key === type)?.text}
            </Table.Cell>
            <Table.Cell>
                {model}
            </Table.Cell>
            <Table.Cell>
                {mac_addr}
            </Table.Cell>
            <Table.Cell>
                {ip_addr}
            </Table.Cell>
            <Table.Cell>
                {user && (
                    <Person username={`${user.first_name} ${user.last_name}`} email={user.email} />
                )}
            </Table.Cell>
            <Table.Cell>
                {extension}
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                <>
                    <Icon name='circle' className={`Devices__statusIcon${active ? "--green" : "--red"}`} />
                    {active ? "Online" : "Offline"}
                </>
            </Table.Cell>
            <Table.Cell>
                {location && (
                    <div>
                        <strong>{location.label}</strong>
                        <p>{location.address}<br />{`${location.city} ${location.state}, ${location.zip}`}</p>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    {type === "softphone" && (
                        <DropdownItem
                            text={"View credentials"}
                            icon={"fa-solid fa-eye"}
                            onClick={() => onOpenModal()}
                        />
                    )}
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => onEditDevice(id)}
                    />
                    {type === "hardphone" && (
                        <DropdownItem
                            text={"Reset"}
                            icon="fa-solid fa-window-restore"
                            onClick={() => onOpenModal()}
                        />
                    )}
                    {type !== "browser" && (
                        <ControlledPopup
                            header={
                                <Header as='h4' textAlign='center'>
                                    Are you sure?
                                    <Header.Subheader style={{ marginTop: 5 }}>
                                        The softphone will be disconnected.
                                    </Header.Subheader>
                                </Header>
                            }
                            trigger={<DropdownItem
                                text='Delete'
                                icon='fa-solid fa-trash'
                            />}
                            onConfirm={() => onDeleteDevice(id)}
                            inverted
                        />
                    )}
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


