import React from 'react';
import { ButtonLink, Table, ControlledPopup, MessagingIcon } from 'dyl-components';
import { Button, Checkbox, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import './SoftphoneRow.scss';
import provisionActions from 'actions/provision';

const SoftphoneRow = ({
    softphone,
    softphoneBeingRead,
    isEnabled,
    onToggleEnabling,
    isCredentialHidden,
    toggleCredential,
    onEnableEditMode,
    onDelete,
    domain_name,
    extensionExists,
    onReactivateSoftphone,
    onDeactivateSoftphone
}) => (
    <Table.Row>
        <Table.Cell>
            {softphone.name}
        </Table.Cell>
        <Table.Cell>
            {softphone.extension_number} {!extensionExists && <em>(deleted)</em>}
        </Table.Cell>
        <Table.Cell>
            {!isCredentialHidden &&
                <div>
                    {softphoneBeingRead === softphone.id ?
                        <Loader inline='centered' active /> :
                        softphone.credentials &&
                        <div>
                            <div>Domain: {domain_name}</div>
                            <div>Server: {softphone.credentials.server}</div>
                            <div>Password: {softphone.credentials.password}</div>
                        </div>
                    }
                </div>
            }
            <span><ButtonLink className='Softphone__showButton' onClick={toggleCredential}>
                {isCredentialHidden ? "Show" : "Hide"}</ButtonLink></span>
        </Table.Cell>
        <Table.Cell>
            {softphone.location_name}
        </Table.Cell>

        <Table.Cell>
            <Checkbox />
        </Table.Cell>

        <Table.Cell>
            <Button onClick={onReactivateSoftphone} size='tiny' basic color='white'>Enable</Button>
            <Button onClick={onDeactivateSoftphone} size='tiny' basic color='white'>Disable</Button>
            <MessagingIcon
                icon='edit'
                onClick={() => { onEnableEditMode(softphone) }}
            />
            <ControlledPopup
                trigger={<MessagingIcon icon='trash' />}
                onConfirm={() => { onDelete(softphone.id) }}
                content={'Delete'}
                inverted
            />
        </Table.Cell>
    </Table.Row>
);

class SoftphoneRowContainer extends React.Component {
    state = {
        isEditMode: false,
        isCredentialHidden: true,
        isEnabled: false,
        softphone: null
    }

    onDelete = (id) => {
        this.props.onDelete(this.props.domain, id).then(() => {
            this.props.onReadSoftphones(this.props.domain);
        })
    }

    toggleCredential = () => {
        this.setState(prevState => ({
            isCredentialHidden: !prevState.isCredentialHidden
        }), () => {
            if (!this.state.isCredentialHidden) {
                this.props.onReadSoftphone(this.props.domain, this.props.softphone.id)
            }
        });
    }

    onToggleEnabling = () => {
        this.setState(prevState => ({
            isEnabled: !prevState.isEnabled
        }));
    }

    onReactivateSoftphone = () => {
        const { domain, softphone } = this.props;
        this.props.onReactivateSoftphone(domain, softphone.id).then(() => {
            this.props.onReadSoftphones(this.props.domain);
        })
    }

    onDeactivateSoftphone = () => {
        const { domain, softphone } = this.props;
        this.props.onDeactivateSoftphone(domain, softphone.id).then(() => {
            this.props.onReadSoftphones(this.props.domain);
        })
    }

    extensionExistsForSoftphoneWithExtension = (id) => {
        return this.props.extensions.findIndex(extension => extension.value === id) !== -1;
    }

    render() {
        return (
            <SoftphoneRow
                isCredentialHidden={this.state.isCredentialHidden}
                isEnabled={this.state.isEnabled}
                softphoneBeingRead={this.props.softphoneBeingRead}
                softphone={this.props.softphone}
                onEnableEditMode={this.props.onEdit}
                onDelete={this.onDelete}
                toggleCredential={this.toggleCredential}
                onToggleEnabling={this.onToggleEnabling}
                domain_name={this.props.domain_name}
                extensionExists={this.extensionExistsForSoftphoneWithExtension(this.props.softphone.extension_id)}
                onReactivateSoftphone={this.onReactivateSoftphone}
                onDeactivateSoftphone={this.onDeactivateSoftphone}
            />
        )
    }
}

const mapStateToProps = state => {
    return ({
        locations: state.provision.locations.map(({ id, label }) => ({
            key: id,
            value: id,
            text: label
        })),
        domain: state.provision.domain,
        domain_name: state.pbx_config.domain_name,
        softphoneBeingRead: state.provision.softphoneBeingRead,
        extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
            key: id,
            value: id,
            text: extension_number
        }))
    })
};

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, softphone) => {
        return dispatch(provisionActions.deleteSoftphone(domain, null, softphone));
    },
    onReadSoftphone: (domain, softphone) => {
        dispatch(provisionActions.getSoftphone(domain, null, softphone))
    },
    onReadSoftphones: (domain) => {
        dispatch(provisionActions.getSoftphones(domain));
    },
    onReactivateSoftphone: (domain, softphone) => {
        return dispatch(provisionActions.reactivateSoftphone(domain, softphone))
    },
    onDeactivateSoftphone: (domain, softphone) => {
        return dispatch(provisionActions.deactivateSoftphone(domain, softphone))
    },
    onUpdate: (domain, softphone) => {
        return dispatch(provisionActions.updateSoftphone(domain, softphone, null, softphone.id))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SoftphoneRowContainer);
