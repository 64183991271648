import moduleInformationActions from "actions/module_information";
import { CloseableContent } from "dyl-components";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Dropdown, Button, Popup, Header, Ref } from "semantic-ui-react";
import ConvertNewCustomerPopup from "shared/ConvertNewCustomerPopup";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

import ConvertToCustomerForm from "shared/forms/ConvertToCustomerForm";
import ConvertToOpportunityForm from "shared/forms/ConvertToOpportunityForm";

import LostForm from "shared/forms/LostForm";

const ConversionControls = () => {
    const {
        account_id,
        contact_id,
        canConvertToOpportunity,
        account_stage,
        master_source,
        secondary_source,
        current_primary_contact_id,
        contactName,
        account_name,
        account_is_a_customer
    } = useSelector((state) => {
        const {
            module: account_stage,
            contact,
            master_source,
            secondary_source,
        } = state.module_information.pipeline;

        return {
            account_id: contact?.account?.id,
            contact_id: contact?.id,

            canConvertToOpportunity: account_stage === "lead",
            account_stage,

            master_source,
            secondary_source: secondary_source?.id || null,
            current_primary_contact_id: contact?.account?.primary_contact_id,

            contactName: `${contact?.first_name} ${contact?.last_name} ${
                contact?.suffix || ""
            }`,
            account_name: contact?.account?.name,
            account_is_a_customer: contact?.account?.customer
        };
    });

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const location = useLocation();

    const refresh = (pipeline_id) => {
        const query = new URLSearchParams(params);
        query.set("module_id", pipeline_id);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(
            `${location.pathname}${query_string ? `?${query_string}` : ""}`
        );
    };

    const modalRef = React.createRef();

    const [conversionModalOpen, setConversionModalOpen] = useState("");
    const [isConversionFormModified, setIsConversionFormModified] =
        useState(false);

    const [lostFormOpen, setLostFormOpen] = useState(false);

    const dispatch = useDispatch();

    const onClose = () => {
        setConversionModalOpen("");
        setIsConversionFormModified(false);
    };

    const onCloseLostForm = () => {
        setLostFormOpen(false);
    };

    const module_id = params.get("module_id");

    const getPopupContent = () => {
        switch (conversionModalOpen) {
            case "opportunity":
                return (
                    <React.Fragment>
                        <Header color="primary">Convert to Opportunity</Header>
                        <ConvertToOpportunityForm
                            size="small"
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            master_source={master_source}
                            secondary_source={secondary_source}
                            account_stage={account_stage}
                        />
                    </React.Fragment>
                );
            case "customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Convert{" "}
                            <Popup
                                trigger={
                                    <span className="Convert__account-name">
                                        {account_name || contactName}
                                    </span>
                                }
                                content={account_name || contactName}
                                inverted
                            />{" "}
                            to Customer
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            account_id={account_id}
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                refresh(module_id);
                            }}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            current_primary_contact_id={
                                current_primary_contact_id
                            }
                            pipeline_id={module_id}
                        />
                    </React.Fragment>
                );
            case "new-customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Create Customer-Account
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                refresh(module_id);
                            }}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            pipeline_id={module_id}
                            current_primary_contact_id={contact_id}
                            action="convert-new-customer"
                            account_id={account_id}
                        />
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    const isPrimaryContact = current_primary_contact_id === contact_id;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { onOpenAddProducts, onEditQuote } = useContext(QuoteBuilderContext);
    const recentQuote = useSelector(state => state.quote.recentQuote?.quote_version?.id);

    const onUpsell = () => {
        if (recentQuote) {
            onEditQuote(recentQuote)
        } else {
            onOpenAddProducts({ isUpsell: true });
        }
    }

    return (
        <React.Fragment>
            <Popup
                content={
                    <CloseableContent onClose={onClose}>
                        {getPopupContent()}
                    </CloseableContent>
                }
                open={conversionModalOpen}
                position="bottom left"
                context={modalRef}
                closeOnDocumentClick={!isConversionFormModified}
                onClose={onClose}
            />
            <Ref innerRef={modalRef}>
                <Dropdown
                    as={Button}
                    size="tiny"
                    color="blue"
                    text="Convert"
                    style={{ background: "#2635DE" }}
                    onOpen={() => {setIsDropdownOpen(true)}}
                    onClose={() => {setIsDropdownOpen(false)}}
                >
                    <Dropdown.Menu>
                        {canConvertToOpportunity && (
                            <Dropdown.Item
                                onClick={() => {
                                    setConversionModalOpen("opportunity");
                                }}
                            >
                                Convert to Opportunity
                            </Dropdown.Item>
                        )}
                        {!account_is_a_customer ? (
                            <Dropdown.Item
                                onClick={() => {
                                    setConversionModalOpen("customer");
                                }}
                            >
                                Convert to Customer
                            </Dropdown.Item>
                        ) : (
                            [
                                <Dropdown.Item onClick={onUpsell}>
                                    Upsell Existing Customer
                                </Dropdown.Item>,
                                <ConvertNewCustomerPopup 
                                    open={isDropdownOpen && (isPrimaryContact)}
                                    isPrimaryContact={isPrimaryContact}
                                    account_id={account_id}
                                    trigger={(
                                        <Dropdown.Item
                                            disabled={isPrimaryContact}
                                            onClick={() => {
                                                setConversionModalOpen(
                                                    "new-customer"
                                                );
                                            }}
                                        >
                                            Convert New Customer
                                        </Dropdown.Item>
                                    )}
                                />,
                            ]
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
            <Popup
                trigger={
                    <Button size="tiny" color="red">
                        Lost
                    </Button>
                }
                content={
                    <CloseableContent onClose={onCloseLostForm}>
                        <LostForm
                            account_id={account_id}
                            account_stage={account_stage}
                            contact_id={contact_id}
                            pipeline_id={module_id}
                            refresh={(pipeline_id) => {
                                dispatch(
                                    moduleInformationActions.getOne(pipeline_id)
                                );
                                refresh(pipeline_id);
                            }}
                        />
                    </CloseableContent>
                }
                on="click"
                open={lostFormOpen}
                position="bottom left"
                onClose={() => {
                    if (!lostFormOpen) {
                        onCloseLostForm();
                    }
                }}
                onOpen={() => {
                    setLostFormOpen(true);
                }}
            />
        </React.Fragment>
    );
};

export default ConversionControls;
