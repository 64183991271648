import React from 'react';
import { STATUS_TYPES, Table, Notification, TableLoader } from 'dyl-components';
import DeviceRow from './DeviceRow';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import EditDeviceModal from './EditDeviceModal';

import provisionActions from 'actions/provision';

const DevicesTable = ({ devices, isReading, onEdit }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Mac ID</Table.HeaderCell>
                <Table.HeaderCell>Display Name</Table.HeaderCell>
                <Table.HeaderCell>E911 Location</Table.HeaderCell>
                <Table.HeaderCell>Timezone</Table.HeaderCell>
                <Table.HeaderCell>Model</Table.HeaderCell>
                <Table.HeaderCell>IP Restrictions</Table.HeaderCell>
                <Table.HeaderCell> DND on Mute <Popup
                    trigger={<Icon name='question circle outline' />}
                    content='For Grandstream phones the mute key will toggle auto rejecting all inbound calls.'
                    inverted
                /></Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <TableLoader isLoading={isReading} colspan={10} />
            {!isReading && devices.map(device => (
                <DeviceRow
                    key={device.id}
                    device={device}
                    onEdit={onEdit}
                />
            ))}
        </Table.Body>
    </Table>
);

class DevicesTableContainer extends React.Component {
    state = {
        deviceBeingEdited: null
    }

    onEdit = (device) => {
        this.setState({ deviceBeingEdited: { ...device } });
    }

    onCancelEdit = () => {
        this.setState({ deviceBeingEdited: null })
    }

    onChange = (_, { name, value }) => {
        const deviceBeingEdited = { ...this.state.deviceBeingEdited, [name]: value };
        this.setState({ deviceBeingEdited });
    }

    onUpdate = () => {
        const { 
            extension_id, 
            phone_name, 
            screen_name, 
            timezone, 
            location_id, 
            mac_addr,
            id 
        } = this.state.deviceBeingEdited;
        const payload = {
            extension: {
                id: extension_id,
                number: (this.props.extensions.find(extension => extension.value === extension_id) || { text: '' }).text 
            },
            phone_name,
            screen_name,
            timezone,
            location_id,
            mac: mac_addr
        };
        
        this.props.onUpdate(this.props.domain, payload, id).then(() => {
            this.props.onReadDevices(this.props.domain);
            this.setState({ deviceBeingEdited: null });
            Notification.alert('Successfully updated device!', STATUS_TYPES.SUCCESS, true);
        }).catch(() => {
            Notification.alert('Failed to update device', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <React.Fragment>
                <DevicesTable
                    isReading={this.props.isReading}
                    devices={this.props.devices}
                    onEdit={this.onEdit}
                />
                <EditDeviceModal
                    device={this.state.deviceBeingEdited || {}}
                    open={this.state.deviceBeingEdited !== null}
                    onChange={this.onChange}
                    onClose={this.onCancelEdit}
                    onUpdate={this.onUpdate}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    devices: state.provision.devices.map(({ location, extension, ...device }) => ({
        ...device,
        location_id: location.id,
        extension_id: extension.id,
        location_name: location.name,
        extension_number: extension.number
    })),
    isReading: state.provision.isReadingDevices || state.pbx_config.isReadingDomains || state.provision.isReadingDomain,
    extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    })),
    domain: state.provision.domain
});

const mapDispatchToProps = dispatch => ({
    onReadDevices: (domain) => {
        dispatch(provisionActions.getDevices(domain));
    },
    onUpdate: (domain, payload, device_id) => {
        return dispatch(provisionActions.updateDevice(domain, payload, null, device_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DevicesTableContainer);
