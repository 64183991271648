import React from 'react';
import { Table, MessagingIcon, STATUS_TYPES, Notification } from 'dyl-components';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';

import AddGreetingSection from '../AddGreetingSection';
import { RecordUtils } from 'utils';

const PhoneExtensionRow = ({
    extension,
    onEnableEditMode,
    isVoicemailExitEnabled,
    onOpenAddGreetingSection,
    users,

    isAddGreetingSectionOpen,
    onCloseAddGreetingSection,
    onAddGreeting
}) => (
    <React.Fragment>
        <Table.Row>
            <Table.Cell>
                {extension.extension_number}
            </Table.Cell>
            <Table.Cell>
                {extension.username}
            </Table.Cell>
            <Table.Cell>
                {extension.voicemail_box}
            </Table.Cell>
            <Table.Cell>

            </Table.Cell>
            {isVoicemailExitEnabled && (
                <Table.Cell>

                </Table.Cell>
            )}
            <Table.Cell>

            </Table.Cell>
            <Table.Cell>
                <MessagingIcon
                    icon='edit'
                    onClick={() => { onEnableEditMode(extension) }}
                />
                <Popup
                    trigger={<Icon color='blue' size='large' name={'upload'} link onClick={onOpenAddGreetingSection} />}
                    content={'Add Greeting'}
                    inverted
                />
            </Table.Cell>
        </Table.Row>
        <AddGreetingSection
            isVoicemailExitEnabled={isVoicemailExitEnabled}
            isAddGreetingSectionOpen={isAddGreetingSectionOpen}
            onCloseAddGreetingSection={onCloseAddGreetingSection}
            onAddGreeting={onAddGreeting}
        />
    </React.Fragment>
)

class PhoneExtensionRowContainer extends React.Component {
    state = {
        extension: null,
        isAddGreetingSectionOpen: false
    }

    onAddGreeting = (newGreeting) => {
        this.setState({ isAddGreetingSectionOpen: false }, () => {
            Notification.alert('Successfully added greeting!', STATUS_TYPES.SUCCESS, true);
        });
    }

    onOpenAddGreetingSection = () => {
        this.setState({ isAddGreetingSectionOpen: true });
    }

    onCloseAddGreetingSection = () => {
        this.setState({ isAddGreetingSectionOpen: false });
    }

    render() {
        return (
            <PhoneExtensionRow
                devices={this.props.devices}
                isVoicemailExitEnabled={this.props.isVoicemailExitEnabled}
                users={this.props.users}
                voicemailOptions={this.props.voicemail_boxes}
                extension={this.props.extension}
                onEnableEditMode={this.props.onEdit}
                isAddGreetingSectionOpen={this.state.isAddGreetingSectionOpen}
                onAddGreeting={this.onAddGreeting}
                onOpenAddGreetingSection={this.onOpenAddGreetingSection}
                onCloseAddGreetingSection={this.onCloseAddGreetingSection}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { extension } = ownProps;
    return ({
        users: RecordUtils.generateUserOptions(state.users.users),
        voicemail_boxes: state.pbx_config.voicemail_boxes.filter(({ extension_number }) => extension_number.startsWith("3") || extension.extension_number === extension_number).map(({ id, label }) => ({
            key: id,
            value: id,
            text: label
        })),
        domain: state.pbx_config.domain
    });
}

export default connect(mapStateToProps)(PhoneExtensionRowContainer);
