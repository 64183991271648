import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid, Transition } from 'semantic-ui-react';
import SoftphonesTable from './SoftphonesTable';
import SoftphonesHeader from './SoftphonesHeader';
import LeftPaddedContent from 'shared/LeftPaddedContent';
import AddSoftphoneSection from './AddSoftphoneSection';


const Softphones = ({ isAddSoftphoneSectionOpen, onOpenAddSoftphoneSection, onCloseAddSoftphoneSection }) => (
    <TableWithHeader
        header={
            <SoftphonesHeader onOpenAddSoftphoneSection={onOpenAddSoftphoneSection} />
        }
        table={
            <Grid style={{ margin: 0 }}>
                <Transition visible={isAddSoftphoneSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddSoftphoneSection
                                    onClose={onCloseAddSoftphoneSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ padding: 0, overflowX: 'scroll' }}>
                        <SoftphonesTable />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }

    />
);

class SoftphonesContainer extends React.Component {

    state = {
        isAddSoftphoneSectionOpen: false
    }

    onOpenAddSoftphoneSection = () => {
        this.setState({
            isAddSoftphoneSectionOpen: true
        })
    }

    onCloseAddSoftphoneSection = () => {
        this.setState({
            isAddSoftphoneSectionOpen: false
        })
    }

    render() {
        return (
            <Softphones
                {...this.state}
                {...this.props}
                onOpenAddSoftphoneSection={this.onOpenAddSoftphoneSection}
                onCloseAddSoftphoneSection={this.onCloseAddSoftphoneSection} />
        )
    }
}

export default SoftphonesContainer;
