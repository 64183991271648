import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generateResolver, VALIDATORS, yup, DateTimeUtils } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import userActions from 'actions/user';
import Utils from "shared/EventForm/Utils";

const useEventForm = ({ contact_id, GUEST_LIMIT = 25, TOTAL_FILE_SIZE_LIMIT = 10485760}) => {
    let timeDropdownDefaults = DateTimeUtils.getTimeDropdownDefaults();
    const [isAllowedToModify, setIsAllowedToModify] = useState(false);
    const dispatch = useDispatch();
    const { user_id } = useSelector((state) => state.auth);

    const { current_user, tz, current_user_email } = useSelector(state => ({
        current_user: state.auth,
        tz: state.auth.timezone,
        current_user_email: state.user.userProfile?.email
    }));

    useEffect(() => {
        const getLoggedUserProfile = async () => {
            await dispatch(userActions.viewUserProfile(user_id));
        }

        getLoggedUserProfile();
    }, [dispatch, user_id])

    const formatEvent = (event) => {
        const start_epoch = event?.all_day ? DateTimeUtils.getUnixTime(event.start?.date, 'YYYY-MM-DD') : event?.start?.date_time
        const end_epoch = event?.all_day ? DateTimeUtils.getUnixTime(event.end?.date, 'YYYY-MM-DD') : event?.end?.date_time
        const getUsersAndContacts = () => {
            if (event?.id) {
                return {
                    users: event.attendees?.data?.filter((attendee) => attendee.user_id).map(({ user_id }) => user_id) || [],
                    contacts: event.attendees?.data?.filter((attendee) => attendee.contact_id).map(({ contact_id }) => contact_id) || [],
                    usersSelected: event.attendees?.data?.filter((attendee) => attendee.user_id).map(({user_id, email, first_name, last_name}) => ({
                        user_id, email, name: `${first_name} ${last_name}`
                    })) || [],
                    contactsSelected: event.attendees?.data?.filter((attendee) => attendee.contact_id).map(({id, email, first_name, last_name}) => ({
                        id, email, name: `${first_name} ${last_name}`
                    })) || [],
                }
            }
            if (contact_id) {
                return {
                    users: [],
                    contacts: [Number(contact_id)],
                    usersSelected: [],
                    contactsSelected: []
                }
            }
            return {
                //TO DO, get the email from the state.auth
                users: [],
                contacts: [],
                usersSelected: [{user_id: current_user.user_id, name: current_user.name, email: current_user_email}],
                contactsSelected: []
            }
        }

        const {users, contacts, usersSelected, contactsSelected} = getUsersAndContacts();
        const emptyContent = ["<html><body></body></html>", "<html><body><p><br></p></body></html>", "<html><body><p></p></body></html>"];
        let eventContentNoSpace = event?.content || "";
        eventContentNoSpace = eventContentNoSpace.replace(/\s/g, '')
        
        return {
            id: event?.id || undefined,
            name: event?.name || "",
            start: event?.start, // start datetime in the google-api format { date, date_time }
            end: event?.end, // end datetime in the google-api format { date, date_time }
            start_epoch, // unix timestamp for event start
            end_epoch, // unix timestamp for event end
            start_date: event ? DateTimeUtils.formatEpoch(start_epoch, DateTimeUtils.WORD_DATE_FORMAT) : timeDropdownDefaults.startDate,
            end_date: event ? DateTimeUtils.formatEpoch(end_epoch, DateTimeUtils.WORD_DATE_FORMAT) : timeDropdownDefaults.endDate,
            start_time: event ? DateTimeUtils.formatEpoch(start_epoch) : timeDropdownDefaults.startTime,
            end_time: event ? DateTimeUtils.formatEpoch(end_epoch) : timeDropdownDefaults.endTime,
            all_day: event?.all_day || false,
            content: emptyContent.includes(eventContentNoSpace) ? "" : (event?.content || ""),
            label: event?.event_label?.id || "",
            related_to: event?.relation ? `${event.relation.related_type}-${event.relation.related_id}` : null,
            location: event?.location || "",
            conference_line: event?.conference_line || event?.conference_url || "",
            phone_number: event?.phone_number || event?.conference_phone || "",
            pin: event?.pin || "",
            recurring: false, // TODO: add support for recurring events
            frequency: 'monthly',
            repeat_days: ['M', 'T', 'W', 'Th', 'F'],
            attachments: (event?.attachments?.data || []).map(attachment => ({
                path: attachment.name,
                id: attachment.id,
                file_id: attachment.file_id,
                name: attachment.name
            })),
            timezone: tz,
            organizer_id: event?.created_by?.user_id,
            organizer_email: event?.created_by?.email,
            organizer: `${event?.created_by?.first_name} ${event?.created_by?.last_name}`,
            attendees: { users, contacts },
            users,
            contacts,
            usersSelected,
            contactsSelected,
            contact_name: ""
        }
    }

    const [eventBeingEdited, setEventBeingEdited] = useState(formatEvent(null));

    const { control, watch, formState: { isValid, isDirty }, trigger, setValue, getValues, reset, handleSubmit, resetField } = useForm({
        mode: 'onChange',
        defaultValues: formatEvent(null),
        resolver: generateResolver({
            name: VALIDATORS.EVENT_NAME().required('This field is required'),
            content: VALIDATORS.EVENT_DESCRIPTION(),
            start_date: yup.date().required('This field is required')
                .test('invalid_start_date', 'Start Date Error', function (value) {
                    if (DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)) {
                        return true;
                    } else {
                        return this.createError({ message: 'Invalid Date' });
                    }
                })
                .test('start_date_before_current', 'Start Date Error', function (value) {
                    let { current_start } = getCurrentStartAndEnd();
                    let current_day = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false);
                 
                    if (eventBeingEdited) {
                        if (!DateTimeUtils.dateIsBeforeAnotherDate(DateTimeUtils.formatEpoch(value, DateTimeUtils.WORD_DATE_FORMAT), current_day, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                            return true;
                        } else {
                            return this.createError({ message: 'Invalid Start Date' });
                        }
                    } else {
                        if (!DateTimeUtils.dateIsBeforeAnotherDate(current_start, current_day, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                            return true;
                        } else {
                            return this.createError({ message: 'Invalid Date' });
                        }
                    }
                }),
            end_date: yup.date().required('This field is required')
                .test('invalid_end_date', 'End Date Error', function (value) {
                    if (DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)) {
                        return true;
                    } else {
                        return this.createError({ message: 'Invalid Date' });
                    }
                })
                .test('end_date_before_start', 'End Date Error', function () {
                    let { current_end, current_start } = getCurrentStartAndEnd();
                    if (!DateTimeUtils.dateIsBeforeAnotherDate(current_end, current_start, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                        return true;
                    } else {
                        return this.createError({ message: 'Invalid Date' });
                    }
                }),
            start_time: yup.string().when(["all_day"], {
                is: false,
                then: schema => schema.required('This field is required')
                    .test('start_time_before_current', 'End Time Error', function (value) {
                        let { current_start } = getCurrentStartAndEnd();
                        let current_day = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATETIME_FORMAT, false, false);
                      
                        if (eventBeingEdited) {
                            if (!DateTimeUtils.dateIsBeforeAnotherDate(DateTimeUtils.formatEpoch(value, DateTimeUtils.WORD_DATETIME_FORMAT), current_day, 'minutes', DateTimeUtils.WORD_DATETIME_FORMAT)) {
                                return true;
                            } else {
                                return this.createError({ message: 'Invalid Time' });
                            }
                        } else {
                            if (!DateTimeUtils.dateIsBeforeAnotherDate(current_start, current_day, 'minutes', DateTimeUtils.WORD_DATETIME_FORMAT)) {
                                return true;
                            } else {
                                return this.createError({ message: 'Invalid Time' });
                            };
                        }
                    }),
            }),
            end_time: yup.string().when(["all_day"], {
                is: false,
                then: schema => schema.required('This field is required')
                .test('end_time_before_start', 'End Time Error', function () {
                    let { current_end, current_start } = getCurrentStartAndEnd();
                    if (!DateTimeUtils.dateIsBeforeAnotherDate(current_end, current_start, 'minutes', DateTimeUtils.WORD_DATETIME_FORMAT, true)) {
                        return true;
                    } else {
                        return this.createError({ message: 'Invalid Time' });
                    };
                }),
            }),
            repeat_days: yup.array().when(['recurring', 'frequency'], {
                is: (recurring, frequency) => recurring === true && frequency === 'daily',
                then: schema => schema.test('recur_at_least_once', 'Should recur for at least one day', value => {
                    return value.length > 0
                })
            }),
            timezone: yup.string().required('This field is required'),
            contacts: yup.array()
                .test('guest_limit_reached', 'Contacts Error', function (value) {
                    const current_number_of_guests = value.length + current_number_of_contacts;
                    if (current_number_of_guests <= GUEST_LIMIT) {
                        return true;
                    } else {
                        return this.createError({ message: `Max ${GUEST_LIMIT} guests` });
                    }
                }),
            location: yup.string().maxlength(512).no_whitespace_only().no_excessive_whitespaces(),
            attachments: yup.array().test("max_file_size_limit", "Total size exceeds 10MB", (value) => {
                return getTotalFileSize(value) < TOTAL_FILE_SIZE_LIMIT
            }),
        })
    });

    const current_number_of_contacts = eventBeingEdited?.contacts?.length || 0;

    const getTotalFileSize = files => files.reduce((a, b) => a + b.size || 0, 0);

    const getCurrentStartAndEnd = () => {
        const { start_date, end_date, start_time, end_time } = getValues();
        return { start_date, end_date, current_end: `${end_date} ${end_time}`, current_start: `${start_date} ${start_time}` };
    }

    const loadEvent = (event) => {
        setEventBeingEdited(formatEvent(event))
    }

    useEffect(() => {
        const allowedToModify = Utils.isAllowedToModify(eventBeingEdited, current_user.user_id);
        setIsAllowedToModify(allowedToModify)
        reset(eventBeingEdited || formatEvent(null));
        resetField();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventBeingEdited, reset, resetField])

    return {
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        formatEvent,
        handleSubmit,
        eventBeingEdited,
        setEventBeingEdited,
        isAllowedToModify,
        loadEvent,
        reset,
        resetField
    }
}
export default useEventForm;