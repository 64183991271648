import { AndOrToggle, DateInput, InnerRuleContainer, MultipleNestedDropdown } from 'dyl-components';
import { ASSIGMENT_RULES_MULTIPLE_TYPES, ASSIGNMENTS_EXISTS_OPTIONS, ASSIGNMENTS_OPERATORS_OPTIONS, ASSIGNMENT_DATE_FIELD_TYPES, ASSIGNMENT_RANGE_FIELD_TYPES, ASSIGNMENT_RANGE_OPERATORS, ASSIGNMENT_RULES_OPERATOR_SINGLE, NO_ASSIGNMENTS_EXISTS_FIELDS } from 'pages/DataMapping/DYLFieldsConstants';
import { Controller } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';

export const Condition = ({
    condition,
    control,
    displayIndex,
    index,
    isLast,
    name,
    parentName,
    trigger,
    watch,
    setValue
}) => {
    const { type, text, options: fieldOptions, field } = condition;

    const valueName = `${name}[${index}].value`;
    const watchedOperator = watch(`${name}[${index}].operator`)

    const operatorOptions = NO_ASSIGNMENTS_EXISTS_FIELDS.includes(field) ? 
        (ASSIGNMENTS_OPERATORS_OPTIONS[type].filter((option) => !ASSIGNMENTS_EXISTS_OPTIONS.find((exist_option) => option.key === exist_option.key))) : 
        (ASSIGNMENTS_OPERATORS_OPTIONS[type]);

    return (
        <div className={`${isLast ? "AssignmentCondition__container--last": "AssignmentCondition__container"}`}>
            <div className='AssignmentCondition__numberedContainer'>
                <div className='AssignmentCondition__numberContainer'>
                    <span className='AssignmentCondition__number'>{displayIndex}</span>
                </div>
            </div>
            <div className='AssignmentCondition__conditionContainer'>
                <InnerRuleContainer isLast={isLast}>
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                        <span style={{flex: 1}}>Field: {text}</span>
                        <Controller
                            control={control}
                            name={`${name}[${index}].operator`}
                            render={({ field: { onChange, value, name } }) => (
                                <Form.Select
                                    className='AssignmentRules__operatorField'
                                    label='Operator'
                                    value={value}
                                    name={name}
                                    onChange={async (_, { name, value }) => {
                                        onChange({ target: { name, value } })
                                        if (ASSIGMENT_RULES_MULTIPLE_TYPES.includes(type)) {
                                            if (ASSIGNMENT_RULES_OPERATOR_SINGLE.includes(value)) {
                                                setValue(valueName, "")
                                            } else {
                                                setValue(valueName, [])
                                            }
                                        }
                                        trigger();
                                    }}
                                    options={operatorOptions}
                                />
                            )}
                        />
                        <div style={{flex: 2}}/>
                    </div>
                    {watchedOperator !== "exists" && watchedOperator !== "not_exists" && type !== "checkbox" && (
                        <Controller
                            control={control}
                            name={`${name}[${index}].value`}
                            render={({ field: { onChange, value, name: fieldName }, fieldState: { error }  }) => {
                                switch (type) {
                                    case "text":
                                    case "phone":
                                    case "email":
                                    case "url":
                                        return (
                                            <Form.Input
                                                label='Values'
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    case "date":
                                    case "daterange":
                                        if (ASSIGNMENT_RANGE_OPERATORS.includes(watchedOperator)) {
                                            return null
                                        }
                                        return (
                                            <Form.Field
                                                control={DateInput}
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                label='Values'
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    case "dependency": {
                                        return (
                                            <Form.Field
                                                control={MultipleNestedDropdown}
                                                nested_options={fieldOptions}
                                                onClickParent={() => {}}
                                                onChange={(_, {value}) => {
                                                    onChange({ target: { name: fieldName, value } });
                                                }}
                                                values={value}
                                                error={(error && error?.message && ({
                                                    content: error.message,
                                                }))}
                                            />
                                        )
                                    }
                                    case "dropdown":
                                    case "rating":
                                    case "tagsinput": {
                                        return (
                                            <Form.Select
                                                label='Values'
                                                value={value}
                                                name={fieldName}
                                                onChange={(_, { name, value }) => {
                                                    onChange({ target: { name, value } })
                                                }}
                                                options={fieldOptions}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                                multiple={!ASSIGNMENT_RULES_OPERATOR_SINGLE.includes(watchedOperator)}
                                            />
                                        )
                                    }
                                    case "user":
                                    case "number":
                                    case "percent":
                                    case "currency": {
                                        if (ASSIGNMENT_RANGE_OPERATORS.includes(watchedOperator)) {
                                            return null
                                        } 
                                        return (
                                            <Form.Input
                                                label='Values'
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    }
                                    default:
                                        return (
                                            <Form.Input
                                                label='Values'
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                }
                            }}
                        />
                    )}
                    {ASSIGNMENT_RANGE_FIELD_TYPES.includes(type) && ASSIGNMENT_RANGE_OPERATORS.includes(watchedOperator) && (
                        <div className='AssignmentRules__rangeContainer'>
                            <Controller
                                control={control}
                                name={`${name}[${index}].value`}
                                style={{flex: 1}}
                                render={({ field: { onChange, value, name: fieldName }, fieldState: { error }  }) => {
                                    return ASSIGNMENT_DATE_FIELD_TYPES.includes(type) ? (
                                        <Form.Field
                                            control={DateInput}
                                            name={fieldName}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name: fieldName, value } })
                                                trigger(`${name}[${index}].maxValue`)
                                            }}
                                            label='Minimum value'
                                            error={error && error?.message && ({
                                                content: error.message,
                                                pointing: 'below'
                                            })}
                                            className={"AssignmentRules__maxRange"}
                                        />
                                    ) : (
                                        <Form.Input
                                            label='Minimum value'
                                            name={fieldName}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name: fieldName, value } })
                                                trigger(`${name}[${index}].maxValue`)
                                            }}
                                            style={{flex: 1}}
                                            error={error && error?.message && ({
                                                content: error.message,
                                                pointing: 'below'
                                            })}
                                            className={"AssignmentRules__maxRange"}
                                        />
                                    )
                                }}
                            />
                            <Icon name="minus" />
                            <Controller
                                control={control}
                                name={`${name}[${index}].maxValue`}
                                className={"asdfasdf"}
                                render={({ field: { onChange, value, name: fieldName }, fieldState: { error }  }) => {
                                    return ASSIGNMENT_DATE_FIELD_TYPES.includes(type) ? (
                                        <Form.Field
                                            label='Maximum value'
                                            control={DateInput}
                                            name={fieldName}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name: fieldName, value } })
                                            }}
                                            error={error && error?.message && ({
                                                content: error.message,
                                                pointing: 'below'
                                            })}
                                            className={"AssignmentRules__minRange"}
                                        />
                                    ) : (
                                        <Form.Input
                                            label='Maximum value'
                                            name={fieldName}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name: fieldName, value } })
                                            }}
                                            error={error && error?.message && ({
                                                content: error.message,
                                                pointing: 'below'
                                            })}
                                            className={"AssignmentRules__minRange"}
                                        />
                                    )
                                }}
                            />
                        </div>
                    )}
                </InnerRuleContainer>
                {!isLast && (
                    <Controller
                        control={control}
                        name={`${parentName}.boolean_operator`}
                        render={({ field: { onChange, value, name } }) =>
                            <AndOrToggle
                                name={name}
                                options={[
                                    { key: 'and', text: 'And', value: 'and'},
                                    { key: 'or', text: 'Or', value: 'or' },
                                ]}
                                value={value}
                                onChange={(value) => {
                                    onChange({ target: { name, value } })
                                }}
                            />
                        } 
                    />
                    
                )}
            </div>
        </div>
    )
}
