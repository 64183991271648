import { Controller, useController, useFieldArray, useFormContext } from "react-hook-form";
import {
    Form,
    Grid,
    Header,
    Segment,
} from "semantic-ui-react";
import React from "react";
import QuoteDetails from "./QuoteDetails";

import './index.scss';
import QuoteItemsTable from "./QuoteItemsTable";
import QuoteTotals from "./QuoteTotals";

const Quote = ({ quote }) => {
    const { control } = useFormContext();

    const { field: cartField } = useController({
        name: "cart",
        control
    })

    const { fields: cart } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    return (
        <Segment size="tiny" basic>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <QuoteDetails />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header color="primary">Summary</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <QuoteItemsTable quote={quote} cart={cart} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column />
                    <Grid.Column floated="right">
                        <Segment>
                            <QuoteTotals quote={quote} cart={cartField.value} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column floated="right">
                        <Segment>
                            <Header>Next Estimated Bill</Header>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name="notes"
                            render={({ field: { name, value, onChange } }) => (
                                <Form.TextArea
                                    label="Notes"
                                    placeholder="Type Note"
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Quote;
