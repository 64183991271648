import React from 'react';
import { TableWithHeader } from 'dyl-components';
import VoicemailMailboxesTable from './VoicemailMailboxesTable';
import VoicemailMailboxesHeader from './VoicemailMailboxesHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddVoicemailMailboxSectionContainer from './AddVoicemailMailboxSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const VoicemailMailboxes = ({ 
    onUpdate, 
    onDelete, 
    isAddMailboxSectionOpen,
    onOpenAddMailboxSection, 
    onCloseAddMailboxSection, 
    onAddMailbox 
}) => (
    <TableWithHeader 
        header={
            <VoicemailMailboxesHeader 
                onOpenAddMailboxSection={onOpenAddMailboxSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddMailboxSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddVoicemailMailboxSectionContainer 
                                    onAddMailbox={onAddMailbox} 
                                    onClose={onCloseAddMailboxSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <VoicemailMailboxesTable 
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class VoicemailMailboxesContainer extends React.Component {
    state = {
        isAddMailboxSectionOpen: false
    }

    onOpenAddMailboxSection = () => {
        this.setState({ isAddMailboxSectionOpen: true });
    }

    onCloseAddMailboxSection = () => {
        this.setState({ isAddMailboxSectionOpen: false });
    }

    render() {
        return (
            <VoicemailMailboxes 
                {...this.props}
                {...this.state}
                onOpenAddMailboxSection={this.onOpenAddMailboxSection}
                onCloseAddMailboxSection={this.onCloseAddMailboxSection}
            />
        )
    }
}

export default VoicemailMailboxesContainer;
