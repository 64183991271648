import React, { useState } from "react";
import {
    EditableCategorizedOptions,
    EditableOptions,
    generateResolver,
    Modal,
    Notification,
    STATUS_TYPES,
    VALIDATORS,
    yup,
} from "dyl-components";
import "./EditFieldModal.scss";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Icon, Popup } from "semantic-ui-react";

import FIELD_TYPE_OPTIONS from "shared/constants/FIELD_TYPE_OPTIONS";

const DependencyFieldValues = ({ control, watch, areCategoriesEditable }) => {
    const { remove: removeCategory, append: addCategory } = useFieldArray({
        control,
        name: "categories",
    });

    const watchedCategories = watch("categories");

    return (
        <Controller
            control={control}
            name="categories"
            render={({ fieldState: { error } }) => (
                <EditableCategorizedOptions
                    className="EditFieldModal__EditableCategorizedOptions"
                    categoriesFieldName={"categories"}
                    control={control}
                    categoriesToEdit={watchedCategories}
                    removeCategory={removeCategory}
                    addCategory={addCategory}
                    areCategoriesEditable={areCategoriesEditable}
                    error={error?.message}
                />
            )}
        />
    );
};

const SingleFieldValues = ({ control, watch }) => {
    const {
        remove: removeOption,
        append: addOption,
        update: updateOption,
    } = useFieldArray({
        control,
        name: "options",
    });

    const watchedOptions = watch("options");

    return (
        <EditableOptions
            optionsFieldName={"options"}
            control={control}
            optionsToEdit={watchedOptions}
            removeOption={removeOption}
            addOption={addOption}
            updateOption={updateOption}
        />
    );
};

const FieldValues = ({ field_type, control, watch, areCategoriesEditable }) => {
    switch (field_type) {
        case 'picklist':
        case 'tagsinput':
        case 'dropdown':
            return (
                <SingleFieldValues
                    control={control}
                    watch={watch}
                />
            )
        case 'dependency':
            return (
                <DependencyFieldValues
                    control={control}
                    watch={watch}
                    areCategoriesEditable={areCategoriesEditable}
                />
            );
        default:
            return null;
    }
};

const EditFieldModal = ({
    open,
    onClose,

    field,
    refresh,

    onUpdate,
    willAffectExistingValues
}) => {
    const {
        formState: { isValid, isDirty },
        control,
        handleSubmit,
        watch,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            field_type: field?.field_type || "",
            field_name: field?.field_name || "",
            label: field?.label || "",
            description: field?.description || "",
            ...(field?.field_type === "dependency"
                ? {
                      categories:
                          field?.options?.data
                              ?.filter((option) => !option.deleted)
                              ?.map((option) => ({
                                  id: option.id,
                                  name: option.value,
                                  options:
                                      option.sub_options?.map((option) => ({
                                          id: option.id,
                                          name: option.value,
                                      })) || [],
                              })) || [],
                  }
                : {
                      options:
                          field?.options?.data
                              ?.filter((option) => !option.deleted)
                              ?.map((option) => ({
                                  id: option.id,
                                  name: option.value,
                              })) || [],
                  }),
        },
        resolver: generateResolver({
            field_type: yup
                .string()
                .oneOf(FIELD_TYPE_OPTIONS.map(({ key }) => key)),
            label: yup.string().no_excessive_whitespaces().no_whitespace_only(),
            description: VALIDATORS.DESCRIPTION(),
            categories: yup.array().when("field_type", {
                is: "dependency",
                then: (schema) =>
                    schema.min(1, "This should have at least one option"),
            }),
        }),
    });

    const [isUpdating, setIsUpdating] = useState(false);

    const onConfirmEdit = async (data) => {
        try {
            setIsUpdating(true);
            await onUpdate(data);
            Notification.alert(
                "Successfully updated field!",
                STATUS_TYPES.SUCCESS
            );
            onClose();
            setIsUpdating(false);
            if (refresh) {
                refresh();
            }
        } catch (e) {
            setIsUpdating(false);
            console.log(e);
            Notification.alert("Failed to update field", STATUS_TYPES.ERROR);
        }
    };

    return (
        <Modal open={open} onClose={onClose} className="EditFieldModal">
            <Controller
                name="field_type"
                control={control}
                render={({ field: { value: fieldTypeValue } }) => [
                    <Modal.Header>
                        Edit Field
                        <Popup
                            trigger={
                                <Icon
                                    className="fas fa-circle-info EditFieldModal__infoMessage"
                                    color="primary"
                                />
                            }
                            content={`Please note that saved changes 
                                ${
                                    willAffectExistingValues
                                        ? "will affect all existing values"
                                        : "will only affect new changes to records"
                                }`}
                            inverted
                            position="left center"
                            wide
                        />
                    </Modal.Header>,
                    <Modal.Content>
                        <Form loading={isUpdating} noValidate>
                            <Form.Group widths={"equal"}>
                                <Form.Select
                                    options={FIELD_TYPE_OPTIONS}
                                    value={fieldTypeValue}
                                    label={"Field Type"}
                                    disabled
                                    required
                                />
                                <Controller
                                    name="label"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Form.Input
                                            value={value}
                                            label={"Field Name"}
                                            disabled
                                            required
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Controller
                                name="description"
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <Form.Input
                                        label={"Description"}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        disabled={field.standard}
                                    />
                                )}
                            />
                            <FieldValues
                                control={control}
                                field_type={fieldTypeValue}
                                areCategoriesEditable={
                                    field?.data?.edit_main !== false
                                }
                                watch={watch}
                            />
                        </Form>
                    </Modal.Content>,
                ]}
            />
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(onConfirmEdit)}
                saveDisabled={!isValid || !isDirty || isUpdating}
                saveOptions={{ loading: isUpdating }}
            ></Modal.Actions>
        </Modal>
    );
};

export default EditFieldModal;
