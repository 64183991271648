import React from 'react';
import { STATUS_TYPES, Table, Notification, TableLoader } from 'dyl-components';
import LocationRow from './LocationRow';
import { connect } from 'react-redux';
import provisionActions from 'actions/provision';

import EditLocationModal from './EditLocationModal';

const LocationsTable = ({ locations, isReading, onEdit }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Address1</Table.HeaderCell>
                <Table.HeaderCell>Address2</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Zip</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <TableLoader isLoading={isReading} colspan={8} />
            {locations.map(location => (
                <LocationRow 
                    location={location}
                    onEdit={onEdit}
                />
            ))}
        </Table.Body>
    </Table>
);

class LocationsTableContainer extends React.Component {
    state = {
        locationBeingEdited: null
    }

    onEdit = (location) => {
        this.setState({ locationBeingEdited: { ...location } });
    }

    onCancelEdit = () => {
        this.setState({ locationBeingEdited: null })
    }

    onChange = (_, { name, value }) => {
        const locationBeingEdited = { ...this.state.locationBeingEdited, [name]: value };
        this.setState({ locationBeingEdited });
    }

    onUpdate = () => {
        const { 
            address,
            address2,
            caller_id_number,
            city,
            label,
            state,
            zip,
            id 
        } = this.state.locationBeingEdited;
        const payload = {
            address,
            address2,
            caller_id_number,
            city,
            label,
            state,
            zip
        };
        
        this.props.onUpdate(this.props.domain, payload, id).then(() => {
            this.props.onReadLocations(this.props.domain);
            this.setState({ locationBeingEdited: null });
            Notification.alert('Successfully updated location!', STATUS_TYPES.SUCCESS, true);
        }).catch(() => {
            Notification.alert('Failed to update location', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <React.Fragment>
                <LocationsTable
                    isReading={this.props.isReading}
                    locations={this.props.locations}
                    onEdit={this.onEdit}
                />
                <EditLocationModal
                    location={this.state.locationBeingEdited || {}}
                    open={this.state.locationBeingEdited !== null}
                    onChange={this.onChange}
                    onClose={this.onCancelEdit}
                    onUpdate={this.onUpdate}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    locations: state.provision.locations,
    isReading: state.provision.isReadingLocations || state.pbx_config.isReadingDomains || state.provision.isReadingDomain,
    domain: state.provision.domain
});

const mapDispatchToProps = dispatch => ({
    onReadLocations: (domain) => {
        dispatch(provisionActions.getLocations(domain));
    },
    onUpdate: (domain, payload, location_id) => {
        return dispatch(provisionActions.updateLocation(domain, payload, null, location_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationsTableContainer);
