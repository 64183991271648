import { Grid, Header, Segment } from "semantic-ui-react";
import QuoteItemsTable from "shared/forms/Quote/QuoteItemsTable";
import OrderInformation from "./OrderInformation";
import RecurringProducts from "./RecurringProducts";
import QuoteTotals from "../Quote/QuoteTotals";
import { useController, useFieldArray, useFormContext } from "react-hook-form";

const OrderSummary = ({ quote, account_id, disabled }) => {
    const { control } = useFormContext();

    const { field: cartField } = useController({
        name: "cart",
        control
    })

    const { fields: cart } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Information</Header>
                    <OrderInformation disabled={disabled} account_id={account_id} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Summary</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <QuoteItemsTable disabled={disabled} quote={quote} cart={cart} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Recurring Products</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <RecurringProducts products={[]} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column />
                <Grid.Column floated="right">
                    <Segment>
                        <QuoteTotals isInvoice quote={quote} cart={cartField.value} />
                    </Segment>
                </Grid.Column>
                <Grid.Column floated="right">
                    <Segment>
                        <Header>Next Invoice</Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OrderSummary;
