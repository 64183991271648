import routes from "actions/routes";
import { generateReadOneActionCreator, generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

const readLabelsContainingContact = generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_CONTACT_LABEL, 'label');
const deleteContactFromLabel = generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_CONTACT_LABEL, () => { }, () => { }, 'label');

const readContactHistory = generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_HISTORY, 'history');
const readUpcomingHistory = generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_UPCOMING_HISTORY, 'upcoming');
const readLatestHistory = generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LATEST_HISTORY, 'latest');

export default {
    readContact: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.ACCOUNT_CONTACT),
    updateContact: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.ACCOUNT_CONTACT),
    readLabelsContainingContact,
    deleteContactFromLabel,
    getContactOptions: generateReadActionCreator(routes.API_CONTACT_OPTIONS, ACTION_NAMES.CONTACT_OPTIONS),
    readContactHistory,
    readUpcomingHistory,
    readLatestHistory,
    deleteContact: generateDeleteActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_CONTACT, () => {}, () => {}, 'contact'),
    inlineUpdateCustomData: (groups) => {
        return {
            type: 'INLINE_UPDATE_CONTACT_CUSTOM_DATA',
            groups
        }
    }
}
