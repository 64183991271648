import React from 'react';
import { Modal } from 'dyl-components';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { STATES } from 'shared/constants/STATES';

const EditLocationModal = ({
    location,
    onChange,

    open,
    onClose,
    onUpdate,
    locationBeingUpdated
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Edit Location
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Input 
                    name='label'
                    value={location.label}
                    onChange={onChange}
                    label='Label'
                />
                <Form.Input 
                    name='address'
                    value={location.address}
                    onChange={onChange}
                    label='Address'
                />
                <Form.Input 
                    name='address2'
                    value={location.address2}
                    onChange={onChange}
                    label='Address 2'
                />
                <Form.Input 
                    name='city'
                    value={location.city}
                    onChange={onChange}
                    label='City'
                />
                <Form.Select 
                    name='state'
                    value={location.state}
                    onChange={onChange}
                    options={STATES.map(({ key, text }) => ({
                        key: key, value: key, text
                    }))}
                    label='State'
                />
                <Form.Input 
                    name='zip'
                    value={location.zip}
                    onChange={onChange}
                    label='Zip'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            {!locationBeingUpdated && <Button negative onClick={onClose}>Cancel</Button>}
            <Button primary onClick={onUpdate} loading={locationBeingUpdated}>Update</Button>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = state => ({
    locationBeingUpdated: state.provision.locationBeingUpdated
})

export default connect(mapStateToProps)(EditLocationModal);
