import React, { useRef } from "react";
import { Checkbox, DropdownItem, Ref, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ClippedContent, EllipsisDropdownPortal, Table } from "dyl-components";

import { StringUtils } from "utils";
import HoverableIconWithList from "shared/HoverableIconWithList";

const CategorySubrow = ({ product, hasRemovedAdditions }) => {
    const ref = useRef();
    return (
        <Table.CollapsibleRowContent indented nested>
            <Table.Cell>
                <Checkbox />
            </Table.Cell>
            <Table.Cell>
                <div style={{ width: "10em", display: "inline-flex" }}>
                    <ClippedContent popupContent={product.name}>
                        <span>
                            {hasRemovedAdditions && (
                                <Icon
                                    className="fas fa-info-circle"
                                    color="orange"
                                />
                            )}
                            <Link
                                to={`/settings/product-catalog-quotes-invoices/product/${product.id}`}
                            >
                                {product.name}
                            </Link>
                        </span>
                    </ClippedContent>
                </div>
                <div style={{ display: "inline-flex", paddingLeft: "1em" }}>
                    <HoverableIconWithList
                        items={product?.addons}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-boxes-stacked"
                            />
                        }
                        iconContent={"Add-ons"}
                    />
                    <HoverableIconWithList
                        items={product?.fees}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-circle-dollar-to-slot"
                            />
                        }
                        iconContent={"Fees"}
                    />
                    <HoverableIconWithList
                        items={product?.taxes}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-landmark"
                            />
                        }
                        iconContent={"Taxes"}
                    />
                </div>
            </Table.Cell>
            <Table.Cell>
                {StringUtils.capitalize(product.product_type)}
            </Table.Cell>
            <Table.Cell>{product.description}</Table.Cell>
            <Table.Cell>
                {product.min_price ? `$${product.min_price}` : ""}
            </Table.Cell>
            <Table.Cell>{StringUtils.capitalize(product.status)}</Table.Cell>
            <Table.Cell>
                <Ref innerRef={ref}>
                    <EllipsisDropdownPortal reference={ref}>
                        <DropdownItem
                            text="Edit"
                            icon="fa-solid fa-pencil"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/product/${product.id}`}
                        />
                        <DropdownItem
                            text="Duplicate"
                            icon="fas fa-copy"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/duplicate-product/${product.id}`}
                        />
                    </EllipsisDropdownPortal>
                </Ref>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
};

export default CategorySubrow;
