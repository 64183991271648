import React from 'react';
import { TableWithHeader } from 'dyl-components';
import AdditionalOptionsHeader from './AdditionalOptionsHeader';
import { Grid, Popup, Icon, Checkbox, Input } from 'semantic-ui-react';
import { EditableField } from 'dyl-components';

export default class AdditionalOptions extends React.Component {
    state = {
        isVoicemailExitEnabled: this.props.isVoicemailExitEnabled,
        isDNDOnMute: this.props.isDNDOnMute,
        conferenceRoomDisplays: this.props.conferenceRoomDisplays,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onCloseEditMode = () => {
        this.setState({ 
            isEditMode: false, 
            isVoicemailExitEnabled: this.props.isVoicemailExitEnabled,
            isDNDOnMute: this.props.isDNDOnMute,
            conferenceRoomDisplays: this.props.conferenceRoomDisplays,
        });
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onUpdate = () => {
        this.setState({ isEditMode: false }, () => {
            this.props.onUpdate({...this.state});
        })
    }

    render() {
        return (
            <TableWithHeader 
                header={<AdditionalOptionsHeader 
                    onCloseEditMode={this.onCloseEditMode}
                    onEnableEditMode={this.onEnableEditMode}
                    onUpdate={this.onUpdate}
                    isEditMode={this.state.isEditMode}
                />}
                table={
                    <Grid celled style={{margin: 0}}>
                        <Grid.Row columns='equal'>
                            <EditableField 
                                label={(
                                    <React.Fragment>
                                        Voicemail Exit <Popup 
                                            trigger={<Icon name='question circle outline' />}
                                            content='When a user presses 9 during voicemail instructions it transfers the user to the desired endpoint.'
                                            inverted
                                        />
                                    </React.Fragment>
                                )}
                                isEditMode={this.state.isEditMode}
                                control={Checkbox}
                                checked={this.state.isVoicemailExitEnabled}
                                value={this.state.isVoicemailExitEnabled ? 'Yes' : 'No'}
                                name='isVoicemailExitEnabled'
                                onChange={(_, { name, checked }) => {this.onChange(null, { name, value: checked })}}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <EditableField 
                                label={(
                                    <React.Fragment>
                                        Conference Room Displays <Popup 
                                            trigger={<Icon name='question circle outline' />}
                                            content='The number of conference rooms that will show up on the view.'
                                            inverted
                                        />
                                    </React.Fragment>
                                )}
                                control={Input}
                                checked={this.state.conferenceRoomDisplays}
                                value={this.state.conferenceRoomDisplays}
                                name='conferenceRoomDisplays'
                                onChange={this.onChange}
                                type='number'
                                min='1'
                                max='10'
                                step='1'
                                isEditMode={this.state.isEditMode}
                            />
                        </Grid.Row>
                    </Grid>
                }
            />
        )
    }
}
