import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { SETTINGS } from 'shared/constants/SETTINGS';
import { USER_PROFILE_ROUTES } from 'shared/constants/USER_PROFILE_ROUTES';
import { LIBRARY_ROUTES } from 'shared/constants/LIBRARY_ROUTES';
import { PRODUCT_MANAGEMENT_ROUTES } from 'shared/constants/PRODUCT_MANAGEMENT_ROUTES';
import { PIPELINE_ROUTES } from 'shared/constants/PIPELINE_ROUTES';

import { Login, Leads, Main, LeadConnect, Reports, VoicemailHistory, CallHistory, FaxHistory, TextMessageHistory, Calendar, CRMActivity, Error404, UserProfile, Dashboard, Library, ProductManagement, TextCampaign, DialerCampaign, EmailCampaign, Opportunities, Import, Contacts, ContactProfile, Notifications, PasswordChange, ForgottenPassword, OAuthRedirect, NewSettingsPage, SettingsPage, Accounts, MasterAccount, SubAccounts, Account, LinkedContacts, DataManagement, DataCustomizationParentGroup, GroupContacts, Campaigns, Campaign, CampaignTabs, CampaignUserReports, CampaignContactList, CampaignDashboard, CampaignSettings, CampaignHistory, AssignmentRulesContainer, CustomerPipelines } from 'pages';
// import RestrictedViewComponent from 'shared/RestrictedViewComponent';
import ScrollToTop from 'shared/ScrollToTop';
import { RequireAuth } from 'shared/withAuth';
import { Breadcrumb } from 'semantic-ui-react';

import './App.scss';
import OverviewTab from 'shared/OverviewTab';
import Text from 'shared/TextTab';
import EmailTab from 'shared/EmailTab';
import Timeline from 'shared/TimelineTab';
import EventsTab from 'shared/EventsTab';
import ActivitiesTab from 'shared/ActivitiesTab';
import Tasks from 'shared/NewTasksTab';
import Notes from 'shared/Notes';
import DataTab from 'shared/DataTab';
import LeadTab from 'shared/LeadTab';
import OpportunityTab from 'shared/OpportunityTab';
import Pipelines from './pages/Pipeline'
import Groups from 'pages/Groups';
import CampaignsTab from 'shared/CampaignsTab';
import AccountDataTab from 'shared/AccountDataTab';
import { ASSIGNMENT_RULES_ROUTES } from 'shared/constants/ASSIGNMENT_RULES_ROUTES';
import CustomerTab from 'shared/CustomerTab';
import { PHONE_MANAGEMENT_ROUTES } from 'shared/constants/PHONE_MANAGEMENT_ROUTES';

class Page extends React.Component {
  render() {
    const PageComponent = this.props.component
    document.title = this.props.title;
    const { component, title, ...otherProps } = this.props;
    return (
      <PageComponent {...otherProps} />
    )
  }
}


const PAGES = [
  { path: 'leads', component: Leads, title: 'Leads' },
  { path: 'lead-connect', component: LeadConnect, title: 'Lead Connect' },
  { path: 'groups', component: Groups, title: 'Groups' },

  { path: 'campaigns', component: Campaigns, title: 'Campaigns' },
  { path: 'calendar', component: Calendar, title: 'Calendar' },
  { path: 'reports/pbx-vm', component: VoicemailHistory, title: 'Voicemails' },
  { path: 'reports/pbx-log', component: CallHistory, title: 'Call History' },
  { path: 'reports/fax-log', component: FaxHistory, title: 'Fax Report' },
  { path: 'reports/sms-report', component: TextMessageHistory, title: 'Text History' },
  { path: 'reports/crm_activity', component: CRMActivity, title: 'CRM Activity Report' },
  // TODO: disabled 'til ready
  // { path: '/blast_lists', component: Campaigns, title: 'Campaigns' },
  { path: 'text_campaign', component: TextCampaign, title: 'Text Campaign' },
  { path: 'dialer_campaign', component: DialerCampaign, title: 'Dialer Campaign' },
  { path: 'email_campaign', component: EmailCampaign, title: 'Email Campaign' },
  // TODO: disabled 'til ready
  // { path: '/tools/export', component: Export, title: 'Export' },

  { path: 'opportunities', component: Opportunities, title: 'Opportunities' },
  { path: 'contacts', component: Contacts, title: 'Accounts & Contacts' },
  { path: 'accounts', component: Accounts, title: 'Accounts' },

  { path: 'notifications', component: Notifications, title: 'Notifications' },
  { path: 'read/notifications', component: Notifications, title: 'Read Notifications' },
  { path: 'unread/notifications', component: Notifications, title: 'UnRead Notifications' },
  { path: 'snoozed/notifications', component: Notifications, title: 'Snoozed Notifications' },
  { path: 'archived/notifications', component: Notifications, title: 'Archived Notifications' },

];

const App = () => {
  //PERMISSION TESTING
  // const appPermissions = useSelector(state => state.permissions.appPermissions);
  // console.log("|+|appPerm", appPermissions);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/oauth' element={<OAuthRedirect />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgotten_password' element={<ForgottenPassword />} />
          <Route path='/password_reset' element={<PasswordChange />} />
          <Route path='/healthcheck' element={"ok"} />
          <Route element={<RequireAuth />}>
            <Route path='/' element={<Main />}>
              <Route index element={<Page title={'DYL'} component={Dashboard} />} />
              {PAGES.map(({ path, component, title, pagePermission }) => (
                <Route
                  path={path}
                  element={
                    // <RestrictedViewComponent appPermission={}>
                    <Page title={title} component={component} />
                    // </RestrictedViewComponent>
                  }
                />
              ))}

              <Route path='import-step-2' element={<Page component={() => <Import activeStep={2} />} title={'Import Step 2'} />} />

              <Route path='reports' element={<Page component={Reports} title={'Reports'} />} />
              <Route path='master_account/:master_account_id' element={(
                <Page component={MasterAccount} title='Master Account' />
              )}>
                <Route index path='accounts' element={<SubAccounts accounts={[]} />} />
                <Route index path='notes' element={<Notes />} />
              </Route>
              <Route path='account/:account_id' element={(
                <Page component={Account} title='Account' />
              )}>
                <Route index path='' element={<div></div>} />
                <Route path='data' element={<AccountDataTab />} />
                <Route path='leads' element={<LeadTab />} />
                <Route path='opportunities' element={<OpportunityTab />} />
                <Route path='customer' element={<CustomerTab />}>
                  <Route path='pipelines' element={<CustomerPipelines />} />
                  <Route path='orders_invoices' element={<></>} />
                  <Route path='recurring_products' element={<></>} />
                </Route>
                <Route path='contacts' element={<LinkedContacts />} />
                <Route path='timeline' element={<div></div>} />
                <Route path='texts' element={<div></div>} />
                <Route path='emails' element={<EmailTab />} />
                <Route path='events' element={<EventsTab />} />
                <Route path='tasks' element={<Tasks />} />
                <Route path='notes' element={<Notes />} />
              </Route>
              <Route path='contact/:contact_id' element={(
                <Page title={'Contact Profile'} component={ContactProfile} />
              )}>
                <Route index path='' exact element={<OverviewTab />} />
                <Route path='data' element={<DataTab />} />
                <Route path='lead' element={<LeadTab />} />
                <Route path='opportunity' element={<OpportunityTab />} />
                <Route path='campaign' element={<CampaignsTab />} />
                <Route path='timeline' element={<Timeline />} />
                <Route path='texts' element={<Text />} />
                <Route path='emails' element={<EmailTab />} />
                <Route path='activities' element={<ActivitiesTab />} />
                <Route path='events' element={<EventsTab />} />
                <Route path='tasks' element={<Tasks />} />
                <Route path='notes' element={<Notes />} />
              </Route>
              <Route path='group/:group_id' element={(
                <Page title={'Group Contacts'} component={GroupContacts} />
              )}>
              </Route>
              <Route path='campaigns/:id' element={
                <Page title={'Campaign'} component={CampaignTabs} />
              }>
                <Route path='dashboard' element={<CampaignDashboard />} />
                <Route path='contacts' element={<CampaignContactList />} />
                <Route path='user-reports' element={<CampaignUserReports />} />
                <Route path='settings' element={<CampaignSettings />} />
                <Route path='history' element={<CampaignHistory />} />
              </Route>
              <Route path='/campaigns/create' element={(
                <Page title={'Create Campaign'} component={Campaign} />
              )} />
              <Route path='/campaign/:id' element={(
                <Page title={'Edit Campaign'} component={Campaign} />
              )} />
              <Route path='*' element={<Error404 />} />
            </Route>
            <Route path='/settings' element={<NewSettingsPage />}>
              {SETTINGS.map(({ path: main_path, name, component: Component, pages }, index) => (
                <React.Fragment>
                  <Route
                    key={main_path}
                    index={index === 0}
                    path={main_path}
                    element={
                      // <RestrictedViewComponent appPermission={pagePermission}>
                      <Page component={Component} title={name} />
                      // </RestrictedViewComponent>
                    }
                  >
                    {!pages && PHONE_MANAGEMENT_ROUTES}
                  </Route>
                  {!!pages && pages.map(({ path, page: Component, header, parent, type, favorite_type, subroute }) => Component && (
                    <Route
                      key={path}
                      path={path}
                      element={(
                        // <RestrictedViewComponent appPermission={pagePermission}>
                        <Page
                          title={header}
                          component={() => {
                            const parent_path = parent?.path || main_path;
                            const parent_name = parent?.name || name;
                            const pathId = parent?.pathId || false;
                            return (
                              <SettingsComponent
                                main_path={parent_path}
                                name={parent_name}
                                header={header}
                                path={path}
                                pathId={pathId}
                                Component={Component}
                                type={type}
                                favorite_type={favorite_type}
                                subroute={subroute}
                              />
                            )
                          }}
                        />
                        // </RestrictedViewComponent>
                      )}
                    />
                  ))}
                </React.Fragment>
              ))}
              <Route path='users/:id' element={(
                <SettingsPage
                  title={'User Profile'}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/users`}>Users</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>User</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={UserProfile} title={'User Profile'} />
                </SettingsPage>
              )}>
                {USER_PROFILE_ROUTES}
              </Route>
              <Route path='data-customization' element={(
                <SettingsPage
                  title={'Data Customization'}
                  details={{
                    favorite_type: "settings",
                    setting_report_type: "crm_customization.data_customization"
                  }}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/crm-customizations`}>CRM Customizations</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>Data Customization</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={DataManagement} title={'Data Customization'} />
                </SettingsPage>
              )}>
                <Route index path='/settings/data-customization/*' element={(<DataCustomizationParentGroup />)} />
              </Route>
              <Route path='library' element={(
                <SettingsPage
                  title={'Library'}
                  details={{
                    favorite_type: "settings",
                    setting_report_type: "crm_customization.library"
                  }}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/crm-customizations`}>CRM Customizations</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>Library</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={Library} title={'Library'} />
                </SettingsPage>
              )}>
                {LIBRARY_ROUTES}
              </Route>
              <Route path='product-catalog-quotes-invoices' element={(
                <SettingsPage
                  title={'Product Catalog, Quotes & Invoices'}
                  details={{
                    favorite_type: "settings",
                    setting_report_type: "crm_customization.product_catalog_quotes_invoices"
                  }}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/crm-customizations`}>CRM Customizations</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>Product Catalog, Quotes & Invoices</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={ProductManagement} title={'Product Catalog, Quotes & Invoices'} />
                </SettingsPage>
              )}
              >
                {PRODUCT_MANAGEMENT_ROUTES}
              </Route>
              <Route path='pipelines' element={(
                <SettingsPage
                  title={'Pipeline'}
                  details={{
                    favorite_type: "settings",
                    setting_report_type: "crm_customization.pipeline"
                  }}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/crm-customizations`}>CRM Customizations</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>Pipeline</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={Pipelines} title={'Pipeline'} />
                </SettingsPage>
              )}>
                {PIPELINE_ROUTES}
              </Route>
              <Route path='assignment-rules' element={(
                <SettingsPage
                  title={'Assignment Rules'}
                  breadcrumbs={(
                    <Breadcrumb>
                      <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section as={Link} to={`/settings/automation`}>Automation</Breadcrumb.Section>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section>Assignment Rules</Breadcrumb.Section>
                    </Breadcrumb>
                  )}
                >
                  <Page component={AssignmentRulesContainer} title={'Assignment Rules'} />
                </SettingsPage>
              )}>
                {ASSIGNMENT_RULES_ROUTES}
              </Route>
              <Route path='*' element={<Error404 />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      {/* Removed until we can establish why the client-side de-authenticates a user <IdleCheck /> */}
    </div>
  );
}

const SettingsComponent = ({ main_path, name, header, pathId, Component, favorite_type, type, subroute }) => {
  const location = useLocation();
  const params = useParams();
  const breadcrumbRoute = pathId ? `${main_path}/${params?.id}` : main_path;
  return (
    <SettingsPage
      {...type ? ({
        details: {
          favorite_type: favorite_type || "settings",
          setting_report_type: type
        }
      }) : {}}
      breadcrumbs={(
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section as={Link} to={`/settings/${breadcrumbRoute}`}>{name}</Breadcrumb.Section>
          <Breadcrumb.Divider />
          {subroute && (
            <>
              <Breadcrumb.Section as={Link} to={`/settings/${subroute.path}`}>{subroute.name}</Breadcrumb.Section>
              <Breadcrumb.Divider />
            </>
          )}
          <Breadcrumb.Section>{location.state?.breadcrumb_header || header}</Breadcrumb.Section>
        </Breadcrumb>
      )}
      title={header}
    >
      <Component />
    </SettingsPage>
  );
}

export default App;



