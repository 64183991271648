import React, { useState } from "react";
import {
    Grid,
    Button,
    Segment,
    Header,
    Icon,
    Popup,
    Form,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
    ClippedContent,
    DateTimeUtils,
    generateResolver,
    yup,
    VALIDATORS,
    DateInput,
    Notification,
    STATUS_TYPES,
    DividingHeader,
} from "dyl-components";
import { StringUtils } from "utils";

import CustomData from "../CustomData";
import { Link, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ContactHouseholdData from "shared/ContactHouseholdData";
import ContactBusinessData from "shared/ContactBusinessData";
import RecordEmailsForm from "shared/forms/RecordEmailsForm";
import RecordPhonesForm from "shared/forms/RecordPhonesForm";
import contactPhoneActions from "actions/contact_phone";
import contactEmailActions from "actions/contact_email";
import contactActions from "actions/contact";
import Socials from "./Socials";
import contactSocialsActions from "actions/contact_socials";
import groupsActions from "actions/groups";

import "./index.scss";
import { STATES } from "shared/constants/STATES";
import RecordLocations from "./RecordLocations";
import {
    CustomGroupUtils,
    ObjectUtils,
    PhoneUtil,
    RecordUtils
} from "utils";
import contactLocationsActions from "actions/contact_location";
import contactDuplicatesActions from "actions/contact_duplicates";
import LocationUtils from "utils/LocationUtils";
import EditSocials from "./EditSocials";
import Groups from "./Groups";
import EditGroups from "./EditGroups";
import { commsSchema } from "shared/schemas/contact/commsSchema";
import EmailUtil from "utils/EmailUtil";

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}

const DataTab = () => {
    const { contact_id } = useParams();

    const [saving, setSaving] = useState(false);

    const {
        business_details,
        contactGroups,
        created,
        custom_data,
        date_of_birth,
        emails,
        fields,
        household_details,
        isCheckingDuplicates,
        isReading,
        isUpdating,
        last_modified,
        locations,
        parent_account,
        phones,
        social_media,
        standard_groups,
        website,
    } = useSelector((state) => {
        const { isReadingContact } = state.contact;
        const users = state.users.users;
        const { created_by, received, last_modified_by, last_modified } =
            state.contact.contact;
        const creator = users.find(({ user_id }) => user_id === created_by);
        const modifier = users.find(
            ({ user_id }) => user_id === last_modified_by
        );
        let creatorName = "";
        let modifierName = "";
        if (creator) {
            creatorName = `${creator.first_name} ${creator.last_name}`;
        }
        if (modifier) {
            modifierName = `${modifier.first_name} ${modifier.last_name}`;
        }
        const { phone: phones, social_media } = state.contact;

        const custom_data = CustomGroupUtils.groupAndFlatten(
            state.contact.custom_data
        );
        const household_details = (custom_data.children?.household_details ||
            [])[0]?.fields;
        const business_details = (custom_data.children?.business_details ||
            [])[0]?.fields;

        return {
            created: {
                user: creatorName,
                id: created_by,
                date: DateTimeUtils.changeFormat(
                    DateTimeUtils.convertUnixTimeToDate(received),
                    "",
                    DateTimeUtils.DATE_FORMAT
                ),
            },
            last_modified: {
                user: modifierName,
                id: last_modified_by,
                date: DateTimeUtils.changeFormat(
                    DateTimeUtils.convertUnixTimeToDate(last_modified),
                    "",
                    DateTimeUtils.DATE_FORMAT
                ),
            },

            date_of_birth: state.contact.contact.date_of_birth,
            website: state.contact.contact.website,
            locations: state.contact.contact?.locations,
            phones,
            emails: state.contact_email.email,
            social_media: social_media.sort((a, b) => a.type - b.type),

            fields: custom_data.fields,

            custom_data: state.contact.custom_data?.children?.filter(
                (child) => !child.standard
            ),
            standard_groups: state.contact.custom_data?.children?.filter(
                (child) => child.standard
            ),
            business_details,
            household_details,

            parent_account: state.contact.account,
            isReading: isReadingContact || state.account.isReadingProfile,
            isUpdating:
                state.contact.contactBeingUpdated ||
                state.pipeline.pipelineBeingUpdated ||
                state.pipeline.isTransitioningPipeline ||
                state.pipeline.pipelineAssignmentBeingCreated ||
                state.pipeline.pipelineAssignmentBeingUpdated,
            isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates,
            contactGroups: state.groups.contactGroups,
        };
    });

    const { id: account_id } = parent_account || {};

    const dispatch = useDispatch();

    const onReadContact = (contact_id) => {
        return Promise.all([
            dispatch(contactActions.readContact(contact_id)),
            dispatch(contactPhoneActions.getContactPhones(contact_id)),
            dispatch(contactEmailActions.getContactEmails(contact_id)),
            dispatch(groupsActions.readContactGroups(contact_id)),
        ]);
    };

    const checkDuplicates = (queryParameters) => {
        return dispatch(
            contactDuplicatesActions.checkDuplicates(queryParameters)
        );
    };

    const onUpdateCustomData = (contact_id, custom_data) => {
        return dispatch(
            contactActions.updateContact(contact_id, { custom_data })
        );
    };

    const onInlineUpdateCustomData = (groups) => {
        return dispatch(contactActions.inlineUpdateCustomData(groups));
    };

    const onSave = async ({
        contact_id,
        phones,
        phoneValues,
        emails,
        emailValues,
        socials,
        socialValues,
        locations,
        locationValues,
        date_of_birth,
        website,
        custom_data,
        groups,
    }) => {
        const phonesToDelete = phones.filter(
            (phone) =>
                phoneValues.findIndex(
                    (phoneValue) => phoneValue.id === phone.id
                ) === -1
        );
        const phonesToAdd = phoneValues.filter((phone) => phone.new);
        const phonesToUpdate = phoneValues.filter(
            (phoneValue) =>
                !phoneValue.new &&
                phones.findIndex(
                    (phone) =>
                        phone.id === phoneValue.id &&
                        phone.main === phoneValue.main &&
                        phone.phone === phoneValue.value &&
                        phone.phone_type === phoneValue.type
                ) === -1
        );

        const emailsToDelete = emails.filter(
            (email) =>
                emailValues.findIndex(
                    (emailValue) => emailValue.id === email.id
                ) === -1
        );
        const emailsToAdd = emailValues.filter((email) => email.new);
        const emailsToUpdate = emailValues.filter(
            (emailValue) =>
                !emailValue.new &&
                emails.findIndex(
                    (email) =>
                        email.id === emailValue.id &&
                        email.main === emailValue.main &&
                        email.email === emailValue.value &&
                        email.email_type === emailValue.type
                ) === -1
        );

        const socialsToDelete = socials.filter(
            (social) =>
                socialValues.findIndex(
                    (socialValue) => socialValue.id === social.id
                ) === -1
        );
        const socialsToAdd = socialValues.filter((social) => social.new);
        const socialsToUpdate = socialValues.filter(
            (socialValue) =>
                !socialValue.new &&
                socials.findIndex(
                    (social) =>
                        social.id === socialValue.id &&
                        social.url === socialValue.value &&
                        social.type === socialValue.type
                ) === -1
        );

        const locationsToDelete = locations.filter(
            (location) =>
                locationValues.findIndex(
                    (locationValue) => locationValue.id === location.id
                ) === -1
        );
        const locationsToAdd = locationValues.filter(
            (location) => location.new
        );
        const locationsToUpdate = locationValues.filter(
            ({ new: isNew, main: isLocationValueMain, ...locationValue }) =>
                !isNew &&
                locations.findIndex(({ main: isMainLocation, ...location }) =>
                    ObjectUtils.deepEqual(location, locationValue)
                ) === -1
        );

        const idsOfNewLocations = await processItems(locationsToAdd, () => {
            return dispatch(
                contactLocationsActions.addContactLocation(
                    locationsToAdd.map((location) => ({
                        label: location.label,
                        street: location.street,
                        additional_street: location.apartmentUnitOrFloor,
                        city: location.city,
                        state: location.state,
                        zip: location.zip,
                    })),
                    null,
                    contact_id
                )
            );
        });

        await processItems(locationsToUpdate, () => {
            return Promise.all(
                locationsToUpdate.map((location) =>
                    dispatch(
                        contactLocationsActions.updateContactLocation(
                            contact_id,
                            {
                                label: location.label,
                                street: location.street,
                                additional_street:
                                    location.apartmentUnitOrFloor,
                                city: location.city,
                                state: location.state,
                                zip: location.zip,
                            },
                            null,
                            location.id
                        )
                    )
                )
            );
        });

        const primary_location_id = (() => {
            const primaryLocation = locationValues.find(
                (location) => location.main
            );
            if (primaryLocation === undefined) {
                return null;
            }
            if (primaryLocation.new) {
                const indexOfAddedPrimaryLocation = locationsToAdd.findIndex(
                    (location) => location.main
                );
                return idsOfNewLocations[indexOfAddedPrimaryLocation];
            }
            return primaryLocation.id;
        })();
        if (primary_location_id) {
            const primaryLocation = locationValues.find((location) => {
                return location.main;
            });
            await dispatch(
                contactLocationsActions.updateContactLocation(
                    contact_id,
                    {
                        label: primaryLocation.label,
                        street: primaryLocation.street,
                        additional_street: primaryLocation.apartmentUnitOrFloor,
                        city: primaryLocation.city,
                        state: primaryLocation.state,
                        main: primaryLocation.main,
                        zip: primaryLocation.zip,
                    },
                    null,
                    primaryLocation.id
                )
            );
        }
        //TODO: figure out way to flatten custom data

        await dispatch(
            contactActions.updateContact(contact_id, {
                ...(date_of_birth
                    ? { date_of_birth }
                    : { date_of_birth: null }),
                custom_data,
                website,
            })
        );

        await processItems(locationsToDelete, () => {
            return Promise.all(
                locationsToDelete.map((location) =>
                    dispatch(
                        contactLocationsActions.deleteContactLocation(
                            contact_id,
                            null,
                            location.id
                        )
                    )
                )
            );
        });

        await Promise.all([
            processItems(phonesToUpdate, () => {
                return Promise.all(
                    phonesToUpdate.map((phone) =>
                        dispatch(
                            contactPhoneActions.updateContactPhone(
                                contact_id,
                                {
                                    main: phone.main,
                                    phone: phone.value,
                                    phone_type: phone.type,
                                },
                                null,
                                phone.id
                            )
                        )
                    )
                );
            }),
            processItems(phonesToDelete, () => {
                return Promise.all(
                    phonesToDelete.map((phone) =>
                        dispatch(
                            contactPhoneActions.deleteContactPhone(
                                contact_id,
                                null,
                                phone.id
                            )
                        )
                    )
                );
            }),
            processItems(emailsToUpdate, () => {
                return Promise.all(
                    emailsToUpdate.map((email) =>
                        dispatch(
                            contactEmailActions.updateContactEmail(
                                contact_id,
                                {
                                    main: email.main,
                                    email: email.value,
                                    email_type: email.type,
                                },
                                null,
                                email.id
                            )
                        )
                    )
                );
            }),
            processItems(emailsToDelete, () => {
                return Promise.all(
                    emailsToDelete.map((email) =>
                        dispatch(
                            contactEmailActions.deleteContactEmail(
                                contact_id,
                                null,
                                email.id
                            )
                        )
                    )
                );
            }),
            processItems(socialsToUpdate, () => {
                return Promise.all(
                    socialsToUpdate.map((social) =>
                        dispatch(
                            contactSocialsActions.updateContactSocial(
                                contact_id,
                                {
                                    url: StringUtils.formatLink(social.value),
                                    type: social.type,
                                },
                                null,
                                social.id
                            )
                        )
                    )
                );
            }),
            processItems(socialsToDelete, () => {
                return Promise.all(
                    socialsToDelete.map((social) =>
                        dispatch(
                            contactSocialsActions.deleteContactSocial(
                                contact_id,
                                null,
                                social.id
                            )
                        )
                    )
                );
            }),
        ]);

        return await Promise.all([
            processItems(phonesToAdd, () => {
                return dispatch(
                    contactPhoneActions.addContactPhone(
                        phonesToAdd.map((phone) => ({
                            main: phone.main,
                            phone: phone.value,
                            phone_type: phone.type,
                        })),
                        null,
                        contact_id
                    )
                );
            }),
            processItems(emailsToAdd, () => {
                return dispatch(
                    contactEmailActions.addContactEmail(
                        emailsToAdd.map((email) => ({
                            main: email.main,
                            email: email.value,
                            email_type: email.type,
                        })),
                        null,
                        contact_id
                    )
                );
            }),
            processItems(socialsToAdd, () => {
                return dispatch(
                    contactSocialsActions.addContactSocial(
                        socialsToAdd.map((social) => ({
                            url: StringUtils.formatLink(social.value),
                            type: social.type,
                        })),
                        null,
                        contact_id
                    )
                );
            }),
            processItems(groups.toAdd, () => {
                return Promise.all(
                    groups.toAdd.map((groupToAdd) => {
                        return dispatch(
                            groupsActions.addContactsToGroup({
                                group_id: groupToAdd,
                                contacts: [Number(contact_id)],
                            })
                        );
                    })
                );
            }),
            processItems(groups.toDelete, () => {
                return Promise.all(
                    groups.toDelete.map((groupToDelete) => {
                        return dispatch(
                            groupsActions.deleteContactGroup(
                                [Number(contact_id)],
                                { group_id: groupToDelete }
                            )
                        );
                    })
                );
            }),
        ]);
    };

    function getDefaultValues() {
        const [industryValue, subIndustryValue] = `${
            business_details?.industry_sector || ""
        }`
            ?.split(",")
            .map((value) => Number(value)) || ["", ""];
        return {
            phones: phones.map((phone) => ({
                value: phone.phone,
                main: phone.main,
                id: phone.id,
                type: phone.phone_type,
            })),
            emails: emails.map((email) => ({
                value: email.email,
                main: email.main,
                id: email.id,
                type: email.email_type,
            })),
            socials: social_media.map((social) => ({
                value: social.url,
                type: social.type,
                id: social.id,
            })),
            locations: locations.map((location) => ({
                label: location.label,
                street: location.street,
                apartmentUnitOrFloor: location.additional_street,
                state: location.state,
                city: location.city,
                zip: location.zip,
                main: location.main,
                id: location.id,
            })),
            date_of_birth: date_of_birth,
            website: website || "",

            school: fields?.school || "",
            degree: fields?.degree || "",
            description: fields?.description || "",
            department: business_details?.department || "",

            business_name: business_details?.company_name || "",
            industry_sector: industryValue,
            sub_industry: subIndustryValue,
            employee_size: business_details?.employee_count,
            annual_revenue: business_details?.annual_revenue,
            product_services_sold:
                business_details?.product_services_sold || "",

            household_name: household_details?.household_name || "",
            household_type: household_details?.household_type || "",
            household_members: household_details?.household_members_count || 0,
            number_of_children: household_details?.number_of_children || 0,
            annual_household_income: household_details?.annual_income || "",

            children: custom_data,
            groups: contactGroups || [],
        };
    }

    const {
        formState: { isDirty, isValid, errors },
        control,
        watch,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        setValue,
        getValues,
        trigger
    } = useForm({
        mode: "onChange",
        defaultValues: getDefaultValues(),
        resolver: generateResolver({
            phones: commsSchema.phones,
            emails: commsSchema.emails,
            website: VALIDATORS.WEBSITE().maxlength(256),
            socials: yup.array().of(
                yup.object().shape({
                    type: yup.string().required("Social type is required"),
                    value: VALIDATORS.WEBSITE(),
                })
            ),
            locations: yup.array().of(
                yup.object().shape({
                    STATE: yup.string().oneOf(STATES.map(({ key }) => key)),
                    street: yup.string().maxlength(100),
                    city: yup.string().maxlength(60),
                    apartmentUnitOrFloor: yup.string().maxlength(12),
                    zip: VALIDATORS.US_POSTAL_CODE(),
                })
            ),
            department: yup.string().maxlength(64),
            children: CustomGroupUtils.generateValidationSchema(custom_data),
        }, ['emails', 'phones']),
    });

    const cancelEdit = () => {
        onCancelEdit();
        reset(getDefaultValues());
    };

    const {
        fields: phonesToEdit,
        append: addPhone,
        remove: removePhone,
        update: updatePhone,
    } = useFieldArray({
        control,
        name: "phones",
    });

    const {
        fields: emailsToEdit,
        append: addEmail,
        remove: removeEmail,
        update: updateEmail,
    } = useFieldArray({
        control,
        name: "emails",
    });

    const {
        fields: socialsToEdit,
        append: addSocial,
        remove: removeSocial,
        update: updateSocial,
    } = useFieldArray({
        control,
        name: "socials",
    });

    const {
        fields: locationsToEdit,
        append: addLocation,
        remove: removeLocation,
        update: updateLocation,
    } = useFieldArray({
        control,
        name: "locations",
    });

    const [watchedEmails, watchedPhones, watchedSocials, watchedLocations] =
        watch(["emails", "phones", "socials", "locations"]);

    const save = async (data) => {
        const { groups } = data;

        const contactGroupIds = contactGroups.map((contactGroup) =>
            contactGroup.id ? contactGroup.id : contactGroup.value
        );
        const groupsIds = groups.map((group) =>
            group.id ? group.id : group.value
        );

        const toDelete = contactGroupIds.filter(
            (contactGroupId) => !groupsIds.includes(contactGroupId)
        );
        const toAdd = groupsIds.filter(
            (groupId) => !contactGroupIds.includes(groupId)
        );

        try {
            setSaving(true);
            await onSave({
                contact_id,
                phones,
                phoneValues: data.phones,
                emails,
                emailValues: data.emails,
                socials: social_media,
                socialValues: data.socials,
                locations,
                locationValues: data.locations,
                date_of_birth: data.date_of_birth,
                website: data.website,
                groups: {
                    toDelete,
                    toAdd,
                },

                custom_data: {
                    fields: CustomGroupUtils.extractPersonFields(data),
                    children: {
                        ...CustomGroupUtils.extractBusinessAndHouseholdDetails(
                            data
                        ),
                        ...CustomGroupUtils.groupAndFlatten({
                            children: data.children,
                        }).children,
                    },
                },
            });
            setSaving(false);
            Notification.alert(
                "Successfully updated contact!",
                STATUS_TYPES.SUCCESS
            );
            await onReadContact(contact_id);
            cancelEdit();
        } catch (e) {
            console.log(e);
            setSaving(false);
            Notification.alert("Failed to update contact", STATUS_TYPES.ERROR);
        }
    };

    const hasErrors = Object.keys(errors).length > 0;

    const [isEditing, setIsEditing] = useState(false);

    const onEdit = () => {
        setIsEditing(true);
    };

    const onCancelEdit = () => {
        setIsEditing(false);
    };

    const onConfirmInlineEdit = async () => {
        try {
            setSaving(true);
            const data = getValues();
            await onUpdateCustomData(contact_id, {
                fields: CustomGroupUtils.extractPersonFields(data),
                children: {
                    ...CustomGroupUtils.extractBusinessAndHouseholdDetails(
                        data
                    ),
                    ...CustomGroupUtils.groupAndFlatten({
                        children: data.children,
                    }).children,
                },
            });
            onInlineUpdateCustomData([...standard_groups, ...data.children]);
            setSaving(false);
            Notification.alert(
                "Successfully updated contact!",
                STATUS_TYPES.SUCCESS
            );
            return;
        } catch (e) {
            console.log(e);
            setSaving(false);
            Notification.alert("Failed to update contact", STATUS_TYPES.ERROR);
        }
    };

    return (
        <Form loading={isReading || saving} size="mini">
            <Grid stackable>
                <Grid.Row centered>
                    <Grid.Column textAlign="center">
                        {!isEditing ? (
                            <Button
                                disabled={isReading}
                                basic
                                color="blue"
                                onClick={onEdit}
                            >
                                Edit
                            </Button>
                        ) : (
                            [
                                !saving && (
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                ),
                                <Button
                                    onClick={handleSubmit(save)}
                                    disabled={
                                        !isDirty ||
                                        !isValid ||
                                        hasErrors ||
                                        isCheckingDuplicates
                                    }
                                    loading={saving}
                                    primary
                                >
                                    Save
                                </Button>,
                            ]
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Grid
                            stackable
                            as={Segment}
                            size="tiny"
                            columns="equal"
                        >
                            <Grid.Column>
                                <Header color="primary">Contact Info</Header>
                                <Header size="small" color="primary">
                                    <span>
                                        {isEditing && (
                                            <Icon
                                                link
                                                onClick={() => {
                                                    PhoneUtil.onAddPhone({
                                                        value: "",
                                                        type: "Cell",
                                                        new: true,
                                                    }, phonesToEdit, addPhone)
                                                }}
                                                className="fas fa-plus-circle"
                                                color="primary"
                                            />
                                        )}
                                        Phones
                                    </span>
                                </Header>
                                {!isEditing ? (
                                    <Grid
                                        className="RecordInfo"
                                        columns="equal"
                                    >
                                        {phones.map((phone) => (
                                            <Grid.Row columns="equal">
                                                <Grid.Column>
                                                    <b>{phone.phone_type}</b>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <ClippedContent>
                                                        {PhoneUtil.formatPhoneNumber(
                                                            phone.phone
                                                        )}
                                                    </ClippedContent>
                                                </Grid.Column>
                                                <Grid.Column width={1}>
                                                    {phone.main && (
                                                        <Icon
                                                            className="fas fa-star"
                                                            color="primary"
                                                        />
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}
                                    </Grid>
                                ) : (
                                    <RecordPhonesForm
                                        control={control}
                                        phones={phonesToEdit}
                                        onRemove={(index) => {
                                            RecordUtils.removeItem(
                                                watchedPhones,
                                                index,
                                                updatePhone,
                                                removePhone
                                            );
                                        }}
                                        updateMain={(onChange) => {
                                            RecordUtils.updateMain(
                                                watchedPhones,
                                                updatePhone,
                                                onChange
                                            );
                                        }}
                                        checkDuplicates={checkDuplicates}
                                        clearErrors={clearErrors}
                                        contact_id={contact_id}
                                        setError={setError}
                                        trigger={trigger}
                                        allowNoPhones
                                    />
                                )}
                                <Header size="small" color="primary">
                                    <span>
                                        {isEditing && (
                                            <Icon
                                                link
                                                onClick={() => {
                                                    EmailUtil.onAddEmail({
                                                        value: "",
                                                        new: true,
                                                        type: "Work",
                                                    }, emailsToEdit, addEmail);
                                                }}
                                                className="fas fa-plus-circle"
                                                color="primary"
                                            />
                                        )}
                                        Emails
                                    </span>
                                </Header>
                                {!isEditing ? (
                                    <Grid
                                        className="RecordInfo"
                                        columns="equal"
                                    >
                                        {emails.map((email) => (
                                            <Grid.Row columns="equal">
                                                <Grid.Column>
                                                    <b>
                                                        {email.email_type ||
                                                            "No Type"}
                                                    </b>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <ClippedContent>
                                                        {email.email}
                                                    </ClippedContent>
                                                </Grid.Column>
                                                <Grid.Column width={1}>
                                                    {email.main && (
                                                        <Icon
                                                            className="fas fa-star"
                                                            color="primary"
                                                        />
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}
                                    </Grid>
                                ) : (
                                    <RecordEmailsForm
                                        control={control}
                                        emails={emailsToEdit}
                                        onRemove={(index) => {
                                            RecordUtils.removeItem(
                                                watchedEmails,
                                                index,
                                                updateEmail,
                                                removeEmail
                                            );
                                        }}
                                        updateMain={(onChange) => {
                                            RecordUtils.updateMain(
                                                watchedEmails,
                                                updateEmail,
                                                onChange
                                            );
                                        }}
                                        checkDuplicates={checkDuplicates}
                                        clearErrors={clearErrors}
                                        contact_id={contact_id}
                                        setError={setError}
                                        trigger={trigger}
                                        allowNoEmails
                                    />
                                )}

                                <Header size="small" color="primary">
                                    <span>
                                        {isEditing && (
                                            <Icon
                                                link
                                                onClick={() => {
                                                    addLocation({
                                                        value: "",
                                                        main: false,
                                                        new: true,
                                                        label: "Business",
                                                    });
                                                }}
                                                className="fas fa-plus-circle"
                                                color="primary"
                                            />
                                        )}
                                        Locations
                                    </span>
                                </Header>
                                <RecordLocations
                                    control={control}
                                    locations={
                                        !isEditing ? locations : locationsToEdit
                                    }
                                    isEditing={isEditing}
                                    addressLabelOptions={LocationUtils.getAddressLabelOptions()}
                                    onRemove={(index) => {
                                        RecordUtils.removeItem(
                                            watchedLocations,
                                            index,
                                            updateLocation,
                                            removeLocation
                                        );
                                    }}
                                    updateMain={(onChange) => {
                                        RecordUtils.updateMain(
                                            watchedLocations,
                                            updateLocation,
                                            onChange
                                        );
                                    }}
                                />

                                <Grid className="RecordInfo">
                                    <Grid.Row columns="equal">
                                        <Grid.Column>School</Grid.Column>
                                        <Grid.Column>
                                            {!isEditing ? (
                                                fields?.school || "None"
                                            ) : (
                                                <Controller
                                                    name="school"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            name,
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Input
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            placeholder="Enter School"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Degree</Grid.Column>
                                        <Grid.Column>
                                            {!isEditing ? (
                                                fields?.degree || "None"
                                            ) : (
                                                <Controller
                                                    name="degree"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            name,
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Input
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            placeholder="Enter Degree"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Birthday</Grid.Column>
                                        <Grid.Column>
                                            {!isEditing ? (
                                                date_of_birth
                                            ) : (
                                                <Controller
                                                    name="date_of_birth"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            name,
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Field
                                                            control={DateInput}
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            placeholder="Enter birthday"
                                                            minDate="01-01-1900"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Website</Grid.Column>
                                        <Grid.Column>
                                            {!isEditing ? (
                                                website
                                            ) : (
                                                <Controller
                                                    name="website"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            name,
                                                            value,
                                                            onChange,
                                                        },
                                                        fieldState: { error },
                                                    }) => (
                                                        <Form.Input
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            placeholder="Enter website"
                                                            error={
                                                                error?.message
                                                            }
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Header size="small" color="primary">
                                    <span>
                                        {isEditing && (
                                            <Icon
                                                disabled={
                                                    socialsToEdit.length >= 3
                                                }
                                                link
                                                onClick={() => {
                                                    addSocial({
                                                        value: "",
                                                        new: true,
                                                        type: "",
                                                    });
                                                }}
                                                className="fas fa-plus-circle"
                                                color="primary"
                                            />
                                        )}
                                        Social Media
                                    </span>
                                </Header>
                                <Grid className="RecordInfo" columns="equal">
                                    {!isEditing ? (
                                        <Socials socials={social_media} />
                                    ) : (
                                        <EditSocials
                                            socials={socialsToEdit}
                                            control={control}
                                            onRemove={(index) => {
                                                RecordUtils.removeItem(
                                                    watchedSocials,
                                                    index,
                                                    updateSocial,
                                                    removeSocial
                                                );
                                            }}
                                            updateType={(index, type) => {
                                                updateSocial(index, {
                                                    ...watchedSocials[index],
                                                    type,
                                                });
                                            }}
                                            selectedSocials={watchedSocials
                                                .map((social) => social.type)
                                                .filter((type) => type)}
                                        />
                                    )}
                                </Grid>
                                <Header size="small" color="primary">
                                    Interests
                                </Header>
                                {isEditing ? (
                                    <EditGroups control={control} />
                                ) : (
                                    <Groups />
                                )}
                            </Grid.Column>
                            <Grid.Column
                                style={{
                                    paddingTop: "2em",
                                    paddingBottom: "2em",
                                }}
                            >
                                <Grid className="RecordInfo">
                                    <Grid.Row columns="equal">
                                        <Grid.Column>IP Address</Grid.Column>
                                        <Grid.Column>None</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Description</Grid.Column>
                                        <Grid.Column>
                                            {!isEditing ? (
                                                fields?.description || "None"
                                            ) : (
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            name,
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => (
                                                        <Form.Input
                                                            name={name}
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            placeholder="Enter description"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Account ID</Grid.Column>
                                        <Grid.Column>
                                            {account_id ? (
                                                <>
                                                    <Link to={`/account/${account_id}`}>
                                                        {account_id}
                                                    </Link>
                                                    {" "}
                                                    <Popup
                                                        trigger={
                                                            <Icon link onClick={copy(account_id)} className="fas fa-copy" color="primary" />
                                                        }
                                                        content="Copied!"
                                                        on="click"
                                                        closeOnTriggerMouseLeave
                                                        position="bottom center"
                                                        inverted
                                                    />
                                                </>
                                            ) : (
                                                "None"
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Contact ID</Grid.Column>
                                        <Grid.Column>
                                            {contact_id}
                                            {" "}
                                            <Popup
                                                trigger={
                                                    <Icon link onClick={copy(contact_id)} className="fas fa-copy" color="primary" />
                                                }
                                                content="Copied!"
                                                on="click"
                                                closeOnTriggerMouseLeave
                                                position="bottom center"
                                                inverted
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Record Source</Grid.Column>
                                        <Grid.Column></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Created On</Grid.Column>
                                        <Grid.Column>
                                            {created.date}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>Created By</Grid.Column>
                                        <Grid.Column>
                                            {created.user && (
                                                <ClippedContent>
                                                    <Link
                                                        target={"_blank"}
                                                        to={`/settings/users/${created.id}/general`}
                                                    >
                                                        {created.user}
                                                    </Link>
                                                </ClippedContent>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            Last Modified On
                                        </Grid.Column>
                                        <Grid.Column>
                                            {last_modified.date}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            Last Modified By
                                        </Grid.Column>
                                        <Grid.Column>
                                            {last_modified.user && (
                                                <ClippedContent>
                                                    <Link
                                                        target={"_blank"}
                                                        to={`/settings/users/${last_modified.id}/general`}
                                                    >
                                                        {last_modified.user}
                                                    </Link>
                                                </ClippedContent>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Header color="primary">
                                    Business Details
                                </Header>
                                <ContactBusinessData
                                    control={control}
                                    isEditing={isEditing}
                                    setValue={setValue}
                                />
                                <Header color="primary">
                                    Household Details
                                </Header>
                                <ContactHouseholdData
                                    control={control}
                                    isEditing={isEditing}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid
                            as={Segment}
                            loading={isReading || isUpdating}
                            size="mini"
                            columns="equal"
                            className={`CustomDataBox CustomDataBox--${
                                isEditing ? "isEditing" : ""
                            }`}
                        >
                            <Grid.Row>
                                <Grid.Column>
                                    <DividingHeader
                                        compact
                                        content="Custom Data"
                                        backgroundColor="#f4f6ff"
                                    />
                                    <div className="CustomDataBox__form">
                                        <CustomData
                                            control={control}
                                            isEditing={isEditing}
                                            onConfirmInlineEdit={
                                                onConfirmInlineEdit
                                            }
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const processItems = (items, callback) => {
    if (items.length) {
        return callback();
    }
    return Promise.resolve();
};

export default DataTab;
