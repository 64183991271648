import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const LocationsHeader = ({ onOpenAddLocationModal }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Manage E911 Locations
    </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            <Button onClick={onOpenAddLocationModal} size='tiny' basic inverted color='white'>Add Location</Button>
        </Grid.Column>
    </Grid>

);

export default LocationsHeader;
