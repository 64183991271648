import { generateCreateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator, generateReadActionCreator, generateUpdateActionCreator } from '../crudActionCreators';
import routes from '../routes';
import ACTION_NAMES from '../ACTION_NAMES';

export default {
    readCallerIdSelects: generateReadOneActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CID_SELECTS, 'cid_select'),
    readCallerIdOptions: generateReadActionCreator(routes.API_PBX_CONFIG_CID_OPTIONS, ACTION_NAMES.CID_OPTIONS, 'cid'),
    readCallerIdPrefs: generateReadActionCreator(routes.API_PBX_CONFIG_CID_PREFS, ACTION_NAMES.CID_PREFS, 'cid_prefs'),
    updateCallerIdPrefs: generateUpdateActionCreator(routes.API_PBX_CONFIG_UPDATE_CID_PREFS, ACTION_NAMES.UPDATE_CID_PREFS, () => {}, () => {}, undefined, false),

    createCallerIdSelects: generateCreateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CID_SELECTS, () => {}, () => {}, 'cid_select'),
    updateCallerIdSelect: generateUpdateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CID_SELECTS, () => {}, () => {}, 'cid_select'),
    deleteCallerIdSelect: generateDeleteActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CID_SELECTS, () => {}, () => {}, 'cid_select')
}
