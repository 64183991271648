import React from 'react';
import { ContactsHeader } from '../../Contacts/subcomponents/Header';
import { ContactsRow } from '../../Contacts/subcomponents/Row';
import { useSearchParams, useParams } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';
import { useSelector } from 'react-redux';

const GroupContactsTable = ({ onFilter, onPageChange, parent }) => {
    const { group_id } = useParams();

    const { contacts, isReadingContacts, count } = useSelector((state) => {
        return {
            contacts: group_id === "hotlist" ? state.groups.hotlistContacts : state.groups.contacts,
            isReadingContacts: state.groups.isReadingContacts,
            count: group_id === "hotlist" ? state.groups.hotlistCount || 0 : state.groups.contactCount || 0,
        };
    });

    const [params] = useSearchParams();

    const isColumnHidden = (column) => column === "Last Modified";

    return (
        <RecordsTable
            isTableLoading={isReadingContacts}
            isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            TableHeader={<ContactsHeader isInGroups isColumnHidden={isColumnHidden} onFilter={onFilter} />}
            TableRows={contacts.map(contact => (
                <ContactsRow key={contact.id} contact={contact} isColumnHidden={isColumnHidden} isInGroups={true} group_id={group_id} parent={parent} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Group Contact'
            emptyListTitle="No Contacts Added"
            emptySubtitleHidden
        />
    )
}

export default GroupContactsTable;
