import React, { useContext, useEffect, useState } from 'react';
import { TableWithHeader } from 'dyl-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import QuotesAndOrdersToolbar from './QuotesAndOrdersToolbar';
import QuotesAndOrdersTable from './QuotesAndOrdersTable';
import { useDispatch } from 'react-redux';
import usersActions from 'actions/users';

import './index.scss';
import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { KEY_ACCOUNT_CO_OWNER, KEY_ACCOUNT_NAME, KEY_ACCOUNT_OPPORTUNITY_STATUS, KEY_ACCOUNT_OWNER, KEY_CONTACT_CO_OWNER, KEY_CONTACT_NAME, KEY_CONTACT_OWNER, KEY_CREATED, KEY_CREATED_BY, KEY_EXPIRES_ON, KEY_LAST_MODIFIED, KEY_LAST_MODIFIED_BY, KEY_NUMBER_PRODUCTS_BUNDLES, KEY_OPPORTUNITY_NAME, KEY_PRODUCTS_BUNDLES, KEY_PROPOSAL_INVOICE_CREATED, KEY_QUOTE_ORDER_ID, KEY_STATUS, KEY_SUBTOTAL } from 'shared/constants/QUOTES_ORDERS_COLUMNS';
import quotesAndOrdersActions from 'actions/quotes_and_orders';
import QuoteBuilderProvider from 'shared/context/QuoteBuilderProvider';

const QuotesAndOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const columnDisplayOptions = [KEY_CREATED, KEY_CREATED_BY, KEY_LAST_MODIFIED, KEY_LAST_MODIFIED_BY, KEY_PROPOSAL_INVOICE_CREATED, KEY_EXPIRES_ON, KEY_QUOTE_ORDER_ID, KEY_NUMBER_PRODUCTS_BUNDLES, KEY_PRODUCTS_BUNDLES, KEY_CONTACT_OWNER, KEY_CONTACT_CO_OWNER, KEY_ACCOUNT_OWNER, KEY_ACCOUNT_CO_OWNER, KEY_CONTACT_NAME, KEY_ACCOUNT_NAME, KEY_OPPORTUNITY_NAME, KEY_ACCOUNT_OPPORTUNITY_STATUS, KEY_SUBTOTAL, KEY_STATUS];
    const defaultColumnsDisplayed = [KEY_CREATED, KEY_CREATED_BY, KEY_LAST_MODIFIED_BY, KEY_QUOTE_ORDER_ID, KEY_CONTACT_OWNER, KEY_ACCOUNT_OWNER, KEY_CONTACT_NAME, KEY_ACCOUNT_NAME, KEY_STATUS];
    const allowedColumns = [
        KEY_CREATED,
        KEY_CREATED_BY,
        KEY_LAST_MODIFIED,
        KEY_LAST_MODIFIED_BY,
        KEY_PROPOSAL_INVOICE_CREATED,
        KEY_EXPIRES_ON,
        KEY_CONTACT_NAME,
        KEY_ACCOUNT_NAME,
        KEY_OPPORTUNITY_NAME,
        KEY_SUBTOTAL,
        KEY_STATUS
    ]
    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const columns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const [, setSelectedQuotesAndOrders, , setAreQuotesAndOrdersInAllPagesSelected] = useContext(BulkActionsContext);

    const [filters, setFilters] = useState({})

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ''}`);
    }

    const isColumnHidden = (column) => {
        return !columns.includes(column);
    }

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...columns, column, KEY_CONTACT_NAME] : columns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ''}`);
        }

        if (!isColumnHidden(column)) {
            switch (column) {
                case KEY_CREATED:
                    if (params.get('start') || params.get('end') || params.get('order_by') === 'created') {
                        const query = new URLSearchParams(params);
                        query.delete('start');
                        query.delete('end');
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case KEY_LAST_MODIFIED:
                    if (params.get('order_by') === 'activity') {
                        const query = new URLSearchParams(params);
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                default:
                    updateColumnsParameter();
            }
        } else {
            updateColumnsParameter();
        }
    }

    const location = useLocation();

    useEffect(() => {
        if (!location.state?.isTogglingPanel) {
            const { status, ...queryParameters } = {
                page: 1,
                limit: 25,
                ...Object.fromEntries(params),
                columns: [...new Set([...columns.filter(column => allowedColumns.includes(column)), "opportunity_id", "account_id", "contact_id"])].join(",")
            }
            dispatch(quotesAndOrdersActions.readAll(queryParameters))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    useEffect(() => {
        if (!location.state?.isTogglingPanel) {
            dispatch(usersActions.readUsers());
            setSelectedQuotesAndOrders([]);
            setAreQuotesAndOrdersInAllPagesSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <div className='QuotesAndOrdersPage'>
            <TableWithHeader
                header={(
                    <QuotesAndOrdersToolbar
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                    />
                )}
                table={(
                    <QuotesAndOrdersTable
                        isColumnHidden={isColumnHidden}
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                    />
                )}
            />
        </div>
    );
}

export default function QuotesAndOrdersContainer() {
    return (
        <BulkActionsProvider>
            <QuoteBuilderProvider>
                <QuotesAndOrders />
            </QuoteBuilderProvider>
        </BulkActionsProvider>
    )
};
