import React, { useState, useEffect, useCallback } from "react";
import { SearchBar, Button, RecordsTable } from "dyl-components";
import { Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddRingGroupModal from "shared/modals/AddRingGroupModal";
import ringGroupActions from "actions/ring_groups";
import BulkActionsProvider from "shared/context/BulkActionsProvider";
import Header from "./subcomponents/Header";
import Row from "./subcomponents/Row";
import "./index.scss";

const RingGroupCallQueues = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { groupsData, groupsCount, isReadingGroups } = useSelector(
        (state) => state.ring_group
    );

    const searchQuery = params.get("search") || "";

    const [filters, setFilters] = useState({
        type: params.get("type")?.split(","),
        status: params.get("order")?.split(","),
        shared: params.get("page")?.split(","),
    });
    const [openAddModal, setOpenAddModal] = useState(false);

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const onPageChange = (_, { activePage }) => {
        onFilter(_, { name: "page", value: activePage });
    };

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set("page", 1);

        const { type, order, page, search } = filters;

        if (search) {
            query.set("search", search);
        } else {
            query.delete("search");
        }

        if (type?.length > 0) {
            query.set("type", type.join(","));
        } else {
            query.delete("type");
        }

        if (order) {
            query.set("order", order);
        } else {
            query.delete("order");
        }

        if (page) {
            query.set("page", page);
        } else {
            query.delete("page");
        }

        const query_string = query.toString();
        navigate(
            `/settings/phone-management/routing/ring-group-call-queues${
                query_string ? `?${query_string}` : ""
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const getGroups = useCallback(
        async (filters) => {
            await dispatch(ringGroupActions.getRingGroups(filters));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(ringGroupActions.getRingGroups(filters));
    }, [searchQuery, filters, getGroups, dispatch]);

    return (
        <div className="RingGroupOrCallQueue">
            <div className="RingGroupHeader">
                <SearchBar
                    isSearching={false}
                    search={searchQuery}
                    searchFcn={(value) => {
                        onFilter(null, { name: "search", value: value });
                    }}
                    cancelFcn={() => {
                        onFilter(null, { name: "search", value: null });
                    }}
                    placeholder={
                        "Search by ring group, call queue name, or internal extension"
                    }
                />
                <div className="RingGroupHeader__ButtonGroup">
                    <Button
                        className="RingGroup__NewUserButton"
                        onClick={() => {
                            setOpenAddModal((openAddModal) => !openAddModal);
                        }}
                    >
                        <Icon name="plus" /> New
                    </Button>
                </div>
            </div>

            <RecordsTable
                tableBodyClassName="RingGroupCallQueuesTable"
                isTableLoading={isReadingGroups}
                isSearchResultEmpty={!groupsCount && params.get("search")}
                isTableEmpty={groupsData?.length === 0}
                TableHeader={
                    <Header ringGroups={groupsData} count={groupsCount} onFilter={onFilter} />
                }
                TableRows={groupsData?.map((group) => (
                    <Row group={group} />
                ))}
                recordsCount={groupsCount}
                activePage={params.get("page")}
                onPageChange={onPageChange}
                emptySubtitle=" "
                recordsName="Ring Groups/Call Queues"
                emptyListTitle={"No Ring Groups/Call Queues Created"}
            />

            <AddRingGroupModal
                open={openAddModal}
                setOpen={(state) => {
                    setOpenAddModal(state);
                }}
                close={setOpenAddModal}
            />
        </div>
    );
};

export default () => (
    <BulkActionsProvider>
        <RingGroupCallQueues />
    </BulkActionsProvider>
);
