import { Controller, useFormContext } from "react-hook-form";
import { Form, Radio, Segment } from "semantic-ui-react";

const AuthorizeNetPaymentOption = ({
    isActive,
    name,
    label,
    onChangeSelectedPaymentMethod,
}) => {
    return (
        <Segment
            className={`PaymentMethod${
                isActive(name) ? ` PaymentMethod--active` : ""
            }`}
            compact
        >
            <Radio
                label={label}
                checked={isActive(name)}
                onChange={() => {
                    onChangeSelectedPaymentMethod(name);
                }}
            />
        </Segment>
    );
};

const PaymentMethod = ({
    selectedPaymentMethod,
    onChangeSelectedPaymentMethod,
    account_id,
}) => {
    const isActive = (method) => {
        return selectedPaymentMethod === method;
    };

    const getClassName = (method) => {
        return `PaymentMethod${
            isActive(method) ? ` PaymentMethod--active` : ""
        }`;
    };

    const {
        formState: { isValid },
        control,
    } = useFormContext();

    return (
        <Segment.Group>
            <AuthorizeNetPaymentOption
                account_id={account_id}
                isActive={isActive}
                label={"Credit Card"}
                name={"cc"}
                onChangeSelectedPaymentMethod={onChangeSelectedPaymentMethod}
            />
            <AuthorizeNetPaymentOption
                account_id={account_id}
                isActive={isActive}
                label={"E-check"}
                name={"e-check"}
                onChangeSelectedPaymentMethod={onChangeSelectedPaymentMethod}
            />
            <Segment className={getClassName("custom-ach")} compact>
                <Radio
                    label="Custom (ACH)"
                    checked={isActive("custom-ach")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("custom-ach");
                    }}
                />
            </Segment>
            {selectedPaymentMethod === "custom-ach" && (
                <Segment style={{ minHeight: "11em" }}>
                    <Form noValidate>
                        <Controller
                            name="custom_ach"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.TextArea
                                    placeholder="Insert account payable information (bank, account #)"
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                        <Form.Button
                            type="button"
                            floated="right"
                            color="primary"
                            disabled={!isValid}
                        >
                            Process
                        </Form.Button>
                    </Form>
                </Segment>
            )}
        </Segment.Group>
    );
};

export default PaymentMethod;
