import React from 'react';
import InstructionsPanel from 'shared/InstructionsPanel';

const VoicemailInstructions = () => (
    <InstructionsPanel 
        mainTitle='Voicemail Instructions'
        additionalInstructions={(
            <i>
                <strong>Note</strong> This is for the main company Mailbox <strong>300</strong>. If you create a new company mailbox, 
                please follow instruction with the new mailbox extension/pin.
            </i>
        )}
        leftPaneTitle='Company Voicemail Setup'
        leftPaneInstructions={[
            'Dial extension 501.',
            'Enter Mailbox Number: 300.',
            'Enter Pin/Passcode: 4717',
            'Press 5 for Advanced Options',
            'Follow prompts to record'
        ]}
        rightPaneTitle='Direct Extension VM Setup'
        rightPaneInstructions={[
            'Each user will dial 500 on assigned extension',
            'Press 5 for Advanced Options',
            'Follow prompts to record.'
        ]}
    />
);

export default VoicemailInstructions;
