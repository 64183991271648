import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import imageActions from 'actions/image';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import axiosInstance from 'actions/axiosInstance';

const getTemplateAttachmentSizes = async (attachments) => {
    try {
        const file_ids = attachments.map(attachment => attachment.file_id);
        const metaData = await imageActions.getMetaData("mms", file_ids);
        const existingAttachmentsMetaData = metaData.filter(file_meta => file_meta.size !== -1);
        const getFileMeta = file_id => existingAttachmentsMetaData.find(fileMeta => fileMeta.name === file_id);
        const existingAttachments = attachments.filter(attachment => getFileMeta(attachment.file_id) !== undefined).map(attachment => ({
            ...attachment,
            size: getFileMeta(attachment.file_id).size
        }));
        return existingAttachments;
    } catch (e) {
        console.log(e);
        return attachments;
    }
}

export default {
    getTemplates: generateReadActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATES),
    createTemplates: generateCreateActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATES),
    getTemplate: (id) => async (dispatch) => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_REQUEST)
        });

        try {
            const { data: textTemplate } = await axiosInstance.instance1.get(`${routes.API_TEXT_TEMPLATES}/${id}`);
            const attachments = textTemplate.attachments?.data ? await getTemplateAttachmentSizes(textTemplate.attachments.data) : [];
            const data = {
                ...textTemplate,
                attachments: {
                    ...(textTemplate.attachments || {}),
                    data: attachments
                }
            }
            console.log(data);
            dispatch({
                type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS),
                data,
                id
            });
            return data;
        } catch (error) {
            console.log(error);
            dispatch({
                type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_FAILURE)
            });
            return Promise.reject(error.response?.data);
        }
    },
    updateTemplate: generateUpdateActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATE),
    deleteTemplate: generateDeleteActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATE)
}
