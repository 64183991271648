const initialState = {
    recordings: null,
    isReadingRecordings: false,
    isUpdatingRecordings: false,

    preferences: null,
    isReadingPreferences: false,
    isUpdatingPreferences: false,

    dylNumbers: [],
    dylNumbersCount: 0,
    isReadingDylNumbers: false,

    dylNumber: null,
    isReadingDylNumber: false,

    destinations: null,
    isReadingDestinations: false,

    destinationGroups: [],
    destinationGroup: null,
    destinationGroupsCount: 0,
    isReadingDestinationGroups: false,
    isSavingDestinationGroups: false,
    isDeletingDestinationGroup: false,
    isReadingDestinationGroup: false,
    isEditingDestinationGroup: false,
    
    isUpdatingDylNumber: false,
    
    companyExtensions: [],
    companyVoicemail: null,
    conferenceRoom: null,
    parkingLot: null,
    isReadingCompanyExtensions: false,
    isReadingCompanyVoicemail: false,
    isReadingConferenceRoom: false,
    isReadingParkingLot: false,
    isReadingListPhones: false,
    pbxListPhones: [],

    callRoutingRules: [],
    callRoutingRulesCount: 0,
    isReadingCallRoutingRules: false,

    isCreatingCallRoutingRules: false,

    callRoutingRule: null,
    isReadingCallRoutingRule: false,

    isUpdatingCallRoutingRule: false,
    isReadingExtensionsList:false,
    extensionsList:[],
    isReadingUnusedExtensions:false,
    unusedExtensions:[]

}

export default initialState;
