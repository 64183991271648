import React from 'react';
import { Modal } from 'dyl-components';
import { Select, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

const EditVoicemailBoxModal = ({
    mailbox,
    onChange,

    open,
    onClose,
    onUpdate,
    voicemailBoxBeingUpdated
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Edit Voicemail Box
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Input 
                    name='label'
                    value={mailbox.label}
                    onChange={onChange}
                    label='Label'
                />
                <Form.Input
                    name='extension_number'
                    value={mailbox.extension_number}
                    onChange={onChange}
                    type='number'
                    step='1'
                    min={0}
                    label='Voicemail'
                    disabled
                />
                <Form.Input 
                    name='pin'
                    value={mailbox.pin}
                    onChange={onChange}
                    type='number'
                    step='1'
                    min={1000}
                    max={9999}
                    disabled
                    label='Pin'
                />
                <Form.Select 
                    control={Select}
                    name='email_delivery'
                    value={mailbox.email_delivery}
                    onChange={onChange}
                    label='Email Delivery'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpdate}
            saveOptions={{loading: voicemailBoxBeingUpdated}}
        />
    </Modal>
)

const mapStateToProps = state => {
    return ({
        voicemailBoxBeingUpdated: state.pbx_config.voicemailBoxBeingUpdated
    });
}

export default connect(mapStateToProps)(EditVoicemailBoxModal);
