import React from 'react';
import { STATUS_TYPES, Table, Notification, TableLoader } from 'dyl-components';
import { connect } from 'react-redux';
import SoftphoneRow from './SoftphoneRow';

import provisionActions from 'actions/provision';

import EditSoftphoneModal from './EditSoftphoneModal';

const SoftphonesTable = ({ softphones, isReading, onEdit }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Credentials</Table.HeaderCell>
                <Table.HeaderCell>E911 Location</Table.HeaderCell>
                <Table.HeaderCell>IP Restrictions</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <TableLoader isLoading={isReading} colspan={8} />
            {!isReading && softphones.map(softphone => (
                <SoftphoneRow
                    key={softphone.id}
                    softphone={softphone}
                    onEdit={onEdit}
                />
            ))}
        </Table.Body>
    </Table>
);

class SoftphonesTableContainer extends React.Component {
    state = {
        softphoneBeingEdited: null
    }

    onEdit = (softphone) => {
        this.setState({ softphoneBeingEdited: { ...softphone } });
    }

    onCancelEdit = () => {
        this.setState({ softphoneBeingEdited: null })
    }

    onChange = (_, { name, value }) => {
        const softphoneBeingEdited = { ...this.state.softphoneBeingEdited, [name]: value };
        this.setState({ softphoneBeingEdited });
    }

    onUpdate = () => {
        const { 
            extension_id, 
            name,
            location_id,
            reset_password,
            id 
        } = this.state.softphoneBeingEdited;
        const payload = {
            extension: {
                id: extension_id,
                number: (this.props.extensions.find(extension => extension.value === extension_id) || { text: '' }).text 
            },
            name,
            location_id,
            reset_password
        };
        
        this.props.onUpdate(this.props.domain, payload, id).then(() => {
            this.props.onReadSoftphones(this.props.domain);
            this.setState({ softphoneBeingEdited: null });
            Notification.alert('Successfully updated softphone!', STATUS_TYPES.SUCCESS, true);
        }).catch(() => {
            Notification.alert('Failed to update softphone', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <React.Fragment>
                <SoftphonesTable
                    isReading={this.props.isReading}
                    softphones={this.props.softphones}
                    onEdit={this.onEdit}
                />
                <EditSoftphoneModal
                    softphone={this.state.softphoneBeingEdited || {}}
                    open={this.state.softphoneBeingEdited !== null}
                    onChange={this.onChange}
                    onClose={this.onCancelEdit}
                    onUpdate={this.onUpdate}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    softphones: state.provision.softphones.map(({ location, extension, ...softphone }) => ({
        ...softphone,
        location_id: location.id,
        extension_id: extension.id,
        location_name: location.name,
        extension_number: extension.number
    })),
    isReading: state.provision.isReadingSoftphones || state.pbx_config.isReadingDomains || state.provision.isReadingDomain,
    extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    })),
    domain: state.provision.domain
});

const mapDispatchToProps = dispatch => ({
    onReadSoftphones: (domain) => {
        dispatch(provisionActions.getSoftphones(domain));
    },
    onUpdate: (domain, payload, softphone_id) => {
        return dispatch(provisionActions.updateSoftphone(domain, payload, null, softphone_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SoftphonesTableContainer);
