import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const PhoneExtensionsHeader = ({ onOpenAddExtensionModal }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Phone Extensions
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            <Button onClick={onOpenAddExtensionModal} size='tiny' basic inverted color='white'>Add Extension</Button>
        </Grid.Column>
    </Grid>
)

export default PhoneExtensionsHeader;
