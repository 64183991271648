import React from 'react';
import { TableWithHeader } from 'dyl-components';
import PhoneExtensionsTable from './PhoneExtensionsTable';
import PhoneExtensionsHeader from './PhoneExtensionsHeader';
import { Grid } from 'semantic-ui-react';
import AddExtensionModal from './AddExtensionModal';

const PhoneExtensions = ({
    departmentOptions,
    isVoicemailExitEnabled,
    voicemailOptions,
    isAddExtensionModalOpen,
    onOpenAddExtensionModal,
    onCloseAddExtensionModal,
    devices
}) => (
        <TableWithHeader
            header={
                <PhoneExtensionsHeader onOpenAddExtensionModal={onOpenAddExtensionModal} />
            }
            table={
                <Grid style={{ margin: 0 }}>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0 }}>
                            <PhoneExtensionsTable
                                departmentOptions={departmentOptions}
                                voicemailOptions={voicemailOptions}
                                isVoicemailExitEnabled={isVoicemailExitEnabled}
                                devices={devices}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <AddExtensionModal
                        open={isAddExtensionModalOpen}
                        onClose={onCloseAddExtensionModal}
                    />
                </Grid>
            }
        />
    );


class PhoneExtensionsContainer extends React.Component {
    state = {
        isAddExtensionModalOpen: false
    }

    onOpenAddExtensionModal = () => {
        this.setState({ isAddExtensionModalOpen: true });
    }

    onCloseAddExtensionModal = () => {
        this.setState({ isAddExtensionModalOpen: false });
    }

    render() {
        return (
            <PhoneExtensions
                {...this.props}
                {...this.state}
                onOpenAddExtensionModal={this.onOpenAddExtensionModal}
                onCloseAddExtensionModal={this.onCloseAddExtensionModal}
            />
        )
    }
}

export default PhoneExtensionsContainer;
