import React from 'react';
import { STATUS_TYPES, Table, Notification, TableLoader } from 'dyl-components';
import VoicemailMailboxRow from './VoicemailMailboxRow';
import { connect } from 'react-redux';
import pbx_configActions from 'actions/pbx_config';

import EditVoicemailBoxModal from './EditVoicemailBoxModal';

const MailboxesTable = ({ mailboxes, isReading, onEdit, isDeleteable }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Voicemail</Table.HeaderCell>
                <Table.HeaderCell>PIN</Table.HeaderCell>
                <Table.HeaderCell>Email Delivery</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <TableLoader isLoading={isReading} colspan={5} />
            {!isReading && mailboxes.map((mailbox, index) => (
                <VoicemailMailboxRow 
                    key={mailbox.id}
                    mailbox={mailbox}
                    onEdit={onEdit}
                    deleteable={isDeleteable(mailbox.id, index)}
                />
            ))}
        </Table.Body>
    </Table>
);

class MailBoxesTableContainer extends React.Component {
    state = {
        voicemailboxBeingEdited: null
    }

    onEdit = (voicemailbox) => {
        this.setState({ voicemailboxBeingEdited: { ...voicemailbox } });
    }

    onCancelEdit = () => {
        this.setState({ voicemailboxBeingEdited: null })
    }

    onChange = (_, { name, value }) => {
        const voicemailboxBeingEdited = { ...this.state.voicemailboxBeingEdited, [name]: value };
        this.setState({ voicemailboxBeingEdited });
    }

    onUpdate = () => {
        const {
            label,
            extension_number,
            pin,
            id
        } = this.state.voicemailboxBeingEdited;
        const payload = {
            label,
            extension_number,
            pin
        }
        this.props.onUpdate(this.props.domain, payload, id).then(() => {
            this.setState({
                voicemailboxBeingEdited: null,
            });
            this.props.onRead(this.props.domain);
            Notification.alert('Successfully updated voicemail box', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to update voicemail box', STATUS_TYPES.ERROR, true);
        })
    }

    isDeleteable = (id, index) => {
        return index !== 0 && !this.props.extension_mailboxes.includes(id);
    }

    render() {

        return (
            <React.Fragment>
                <MailboxesTable
                    isReading={this.props.isReading}
                    mailboxes={this.props.mailboxes}
                    onEdit={this.onEdit}
                    isDeleteable={this.isDeleteable}
                />
                <EditVoicemailBoxModal
                    mailbox={this.state.voicemailboxBeingEdited || {}}
                    open={this.state.voicemailboxBeingEdited !== null}
                    onChange={this.onChange}
                    onClose={this.onCancelEdit}
                    onUpdate={this.onUpdate}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    mailboxes: state.pbx_config.voicemail_boxes.filter(({extension_number})  => extension_number.startsWith("3")),
    isReading: state.pbx_config.isReadingDomains || state.pbx_config.isReadingVoicemailBoxes,
    extension_mailboxes: state.pbx_config.extensions.map(({ voicemail }) => voicemail.id)
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (domain, updatedVoicemailBox, voicemail_box_id) => {
        return dispatch(pbx_configActions.updateVoicemailBox(domain, updatedVoicemailBox, null, voicemail_box_id));
    },
    onRead: (domain) => {
        dispatch(pbx_configActions.getVoicemailBoxes(domain));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MailBoxesTableContainer);
