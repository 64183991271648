import React from 'react';
import { Header } from 'semantic-ui-react';
import { Modal } from 'dyl-components';
import AddLocationForm from 'shared/forms/AddLocationForm';
import { connect } from 'react-redux';
import provisionActions from 'actions/provision';


class AddLocationModal extends React.Component {
    state = {
        label: '',
        valid: false,
        location: null,
        isConfirmationModalOpen: false
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onValidateLocation = () => {

        const { label, address, address2, city, state, zip, caller_id_number } = this.state;

        const location = {
            label, address, address2, city, state, zip, caller_id_number
        }

        this.props.onValidateLocation(location).then(({ location, valid }) => {
            this.setState({
                isConfirmationModalOpen: true,
                valid,
                location
            })
        }).catch(() => {
            this.setState({
                isConfirmationModalOpen: true,
                valid: false
            })
        })
    }

    getValidatedLocation = () => {
        const { location, valid } = this.state;
        const { address, address2, city, label, state, zip } = this.state;

        return (
            <Modal.Content>
                {
                    <Header as={'h3'}>
                        {location ? `The location ${location.address}, ${location.address2 ? `${location.address2},` : ''} ${location.city}, ${location.state}, ${location.zip} with a label of ${label} is ${valid ? 'valid' : 'invalid'}.`
                            : `The location ${address}, ${address2 ? `${address2},` : ''} ${city}, ${state}, ${zip} with a label of ${label} is ${valid ? 'valid' : 'invalid'}.`}
                    </Header>
                }
                {
                    !valid && location &&
                    <React.Fragment>
                        <Header as={'h3'}>
                            {`The suggested valid location is: `}
                        </Header>
                        <Header as={'h3'}>
                            {`${location.address}, ${location.address2 ? `${location.address2},` : ''} ${location.city}, ${location.state}, ${location.zip}`}
                        </Header>
                    </React.Fragment>

                }
            </Modal.Content>
        )
    }

    onCloseConfirmationModal = () => {
        this.setState({
            isConfirmationModalOpen: false,
            valid: false,
            location: null
        })
    }

    onAddLocation = () => {

        const { label, caller_id_number, location } = this.state;

        this.props.onAddLocation(this.props.domain, { ...location, label, caller_id_number });
        this.setState({
            label: '',
            location: null,
            valid: false
        }, () => {
            this.onCloseConfirmationModal();
            this.props.onClose();
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal open={this.props.open} onClose={this.props.onClose}>
                    <Modal.Header>
                        Add Location
                </Modal.Header>
                    <Modal.Content>
                        <AddLocationForm onChange={this.onChange} />
                    </Modal.Content>
                    <Modal.Actions
                        hasSaveButton
                        onSave={this.onValidateLocation}
                        saveOptions={{loading: this.props.isValidatingLocation}}
                    />
                </Modal>
                {this.state.isConfirmationModalOpen &&
                    <Modal size='tiny' open={this.state.isConfirmationModalOpen} onClose={this.onCloseConfirmationModal}>
                        <Modal.Header>
                            Add Location
                        </Modal.Header>
                        {this.getValidatedLocation()}
                        <Modal.Actions
                            hasSaveButton={this.state.location}
                            onSave={this.onAddLocation}
                        />
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    domain: state.provision.domain,
    isValidatingLocation: state.provision.isValidatingLocation
});

const mapDispatchToProps = dispatch => ({
    onValidateLocation: (location) => {
        return dispatch(provisionActions.validateLocation(location, {}));
    },
    onAddLocation: (domain, location) => {
        return dispatch(provisionActions.createLocations([location], {}, domain)).then(() => {
            dispatch(provisionActions.getLocations(domain))
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationModal);
