import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function cidSelectsReducer(state = initialState, action) {

    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCidSelects: true }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCidSelects: false, cid_selects: action.data.data || [], count: action.data.count }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCidSelects: false, cid_selects: [], count: 0 }

            case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
                return { ...state, isReadingCidOptions: true };
            case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
                return { ...state, isReadingCidOptions: false, cidOptions: action.data };
            case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
                return { ...state, isReadingCidOptions: false };

            case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_REQUEST):
                return { ...state, isReadingCidPrefs: true };
            case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_SUCCESS):
                return { ...state, isReadingCidPrefs: false, cidPrefs: action.data };
            case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_FAILURE):
                return { ...state, isReadingCidPrefs: false };

                case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
                    return { ...state, isUpdatingCidPrefs: true };
                case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
                    return { ...state, isUpdatingCidPrefs: false, cidPrefs: action.data };
                case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
                    return { ...state, isUpdatingCidPrefs: false };
                
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, cidSelectBeingDeleted: action.subId }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, cidSelectBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, cidSelectBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, cidSelectBeingUpdated: action.subId }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, cidSelectBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, cidSelectBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCidSelects: true }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCidSelects: false }
        case getNamedAction(ACTION_NAMES.CID_SELECTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCidSelects: false }

        default:
            return state;
    }
}

export default cidSelectsReducer;
