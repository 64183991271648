import React from "react";
import { Table } from 'dyl-components';
import { Transition } from 'semantic-ui-react';
import AddGreetingFormContainer from './AddGreetingForm';

const AddGreetingSection = (props) => (
    <Table.Row style={props.isAddGreetingSectionOpen ? {} : {
        display: 'none'
    }}>
        <Table.Cell style={props.isAddGreetingSectionOpen ? {} : {
            margin: 0,
            padding: 0
        }} colSpan={props.isVoicemailExitEnabled ? 8 : 7}>
            <Transition unmountOnHide visible={props.isAddGreetingSectionOpen} animation='slide down' duration='150'>
                <AddGreetingFormContainer onCancel={props.onCloseAddGreetingSection} onUpload={props.onAddGreeting} />
            </Transition>
        </Table.Cell>
    </Table.Row>
);

export default AddGreetingSection;
