import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { RecordUtils } from 'utils';

const AddPhoneExtensionForm = ({
    callerid,
    onChange,
    extension_number,
    user_id,
    users,
    callerids,
    devices,
    assigned_devices,
    hasDevices,
    onChangeAssignedDevices
}) => (
        <Form>
            <Form.Select
                label='Caller ID'
                required
                name='callerid'
                value={callerid}
                onChange={onChange}
                options={callerids}
                selectOnBlur={false}
                clearable
            />
            <Form.Input
                label='Extension Number'
                required
                name='extension_number'
                value={extension_number}
                onChange={onChange}
            />
            <Form.Select
                label='Assign User'
                name='user_id'
                value={user_id}
                onChange={onChange}
                options={users}
            />
            <Form.Select
                multiple={hasDevices}
                label='Assign Existing Device'
                options={[
                    { key: -1, value: -1, text: 'Assign Later' },
                    ...devices
                ]}
                value={
                    hasDevices ? assigned_devices : -1
                }
                onChange={onChangeAssignedDevices}
                name='assigned_devices'
            />
        </Form>
    );

const mapStateToProps = state => ({
    callerids: state.phone_number.phone_numbers.map(({ phone }) => ({
        key: phone,
        value: phone,
        text: phone
    })),
    users: RecordUtils.generateUserOptions(state.users.users),
    
    devices: state.provision.devices.map(({id, phone_name}) => ({
        key: id,
        value: id,
        text: phone_name
    }))
})

export default connect(mapStateToProps)(AddPhoneExtensionForm);
