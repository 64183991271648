import axiosInstance from 'actions/axiosInstance';
import routes from 'actions/routes';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { saveAs } from 'file-saver';

import { Notification, STATUS_TYPES } from 'dyl-components';

export default {
    getMetaData: async (type, file_ids) => {
        const jwtResponse = await axiosInstance.instance1.get(routes.API_JWT);
        const { data: metaData } = await axiosInstance.upload_instance.post(`/v1/file/metadata/${type}`, file_ids, {
            headers: {
                "X-Token": jwtResponse.data.token
            }
        });
        return metaData;
    },
    upload: (file, type, queryParameters) => async dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_REQUEST)
        });

        try {
            const jwtResponse = await axiosInstance.instance1.get(routes.API_JWT);
            const data = new FormData();
            data.append('file', file);
            const uploadResponse = await axiosInstance.upload_instance.post(`/v1/upload/${type}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Token": jwtResponse.data.token
                },
                params: queryParameters
            });

            dispatch({
                type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_SUCCESS)
            });

            return Promise.resolve(uploadResponse.data);
        } catch (error) {
            console.log(error);
            dispatch({
                type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
            });
            return Promise.reject(error);
        }
    },

    download: (type, queryParameters, save=true) => async dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_REQUEST)
        });

        try {
            const jwtResponse = await axiosInstance.instance1.get(routes.API_JWT);
            const downloadResponse = await axiosInstance.upload_instance.get(`/v1/download/${type}`, {
                headers: {
                    "Content-Type": `application/${type}`,
                    "X-Token": jwtResponse.data.token,
                    "Content-Disposition": `attachment; filename="${queryParameters.custom_id}.pdf"`,
                    "Accept": `application/${type}`
                },
                params: queryParameters,
                responseType: 'blob'
            });

            if (save) {
                saveAs(downloadResponse.data, `${queryParameters.custom_id}.pdf`);
            }

            dispatch({
                type: getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_SUCCESS),
            });

            return Promise.resolve(downloadResponse.data);
        } catch (error) {
            console.log(error);

            dispatch({
                type: getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_FAILURE),
            });
            return Promise.reject();
        }

    },

    uploadBlastList: (list) => dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_REQUEST)
        });

        return axiosInstance.instance1.get(routes.API_JWT)
            .then((response) => {
                return Promise.resolve({ token: response.data.token });
            })
            .then(({ token }) => {
                const data = new FormData();
                data.append('list', list);
                return axiosInstance.upload_instance.post(routes.API_UPLOAD_BLAST_LIST, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-Token": token
                    }
                })
            })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_SUCCESS),
                });

                Notification.alert('Successfully uploaded blast list!', STATUS_TYPES.SUCCESS, true);

                return response.data;
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_FAILURE),
                });

                Notification.alert('Failed to upload blast list', STATUS_TYPES.ERROR, true);
            })
    }
}