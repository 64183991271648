import {
    DateTimeUtils,
    Modal,
    VALIDATORS,
    generateResolver,
    yup,
} from "dyl-components";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Icon, Popup, Segment } from "semantic-ui-react";
import { cartItemSchema, getDefaultCartItems } from "shared/schemas/cart/cartSchema";
import EditQuote from "./EditQuote";
import AddProducts from "./AddProducts";
import ProposalPreview from "./ProposalPreview";
import { useContext, useEffect } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { Error500 } from "pages";
import Order from "shared/forms/Order";
import Payment from "shared/Payment";

const ModalContent = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id
}) => {
    const quote = useSelector((state) => state.quote.quote);
    const order = useSelector((state) => state.order.order);
    const currentDate = DateTimeUtils.getCurrentDate(
        DateTimeUtils.WORD_DATE_FORMAT
    );

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const { currentStep, id } = quoteBuilderConfig;

    const defaultValues = (() => {
        if (
            currentStep === "build-quote" ||
            (currentStep === "add-products" && id)
        ) {
            const { street, city, state, zip } = quote.contact_info?.address || {};

            return {
                quote_status: quote.quote_status,
                cart: getDefaultCartItems(quote.quote_summary),
                name: quote.name,
                from_email: quote.from_information?.email,
                from_phone: quote.from_information?.phone,
                from_user_id: quote.from_information?.from_user_id,
                profile_info: quote.from_information?.profile_info,
                contact_email: quote.contact_info?.email,
                contact_phone: quote.contact_info?.phone,
                contact_id: quote.contact_info?.contact_id,
                contact_address: (street && city && state && zip) ? quote.contact_info?.address : {
                    address: {
                        additional_street: "",
                        city: "",
                        state: "",
                        street: "",
                        zip: "",
                    },
                    contact_id: account_id,
                    email: "",
                    phone: "",
                },
                expires:
                    quote.expires > 0
                        ? DateTimeUtils.changeFormat(
                              DateTimeUtils.convertUnixTimeToDate(
                                  quote.expires,
                                  "UTC"
                              ),
                              "",
                              DateTimeUtils.WORD_DATE_FORMAT,
                              "UTC"
                          )
                        : null,
                notes: quote.note,
            };
        }
        if ((currentStep === "order" || currentStep === "edit-order") && id) {
            return {
                order_status: order?.status || "",
                name: order?.name,
                billing_address: Object.keys(order?.billing_address || {})
                    .length
                    ? order?.billing_address
                    : {
                          address: {
                              additional_street: "",
                              city: "",
                              state: "",
                              street: "",
                              zip: "",
                          },
                          contact_id: account_id,
                          email: "",
                          phone: "",
                      },
                shipping_address: Boolean(
                    order?.shipping_address?.location?.address
                )
                    ? {
                          address: order?.shipping_address?.location?.address,
                          ...(!Boolean(
                              order?.shipping_address?.location?.contact_id
                          )
                              ? () => {
                                    const [first_name, last_name, suffix] = (
                                        order?.shipping_address?.ship_to ||
                                        " _ _"
                                    )?.split("_");
                                    return {
                                        contact_id: `shipping-contact`,
                                        first_name,
                                        last_name,
                                        suffix,
                                        phone: order?.shipping_address?.location
                                            ?.phone,
                                        email: order?.shipping_address?.location
                                            ?.email,
                                    };
                                }
                              : {
                                    ship_to: order?.shipping_address?.ship_to,
                                    contact_id:
                                        order?.shipping_address?.location
                                            ?.contact_id,
                                    phone: order?.shipping_address?.location
                                        ?.phone,
                                    email: order?.shipping_address?.location
                                        ?.email,
                                }),
                      }
                    : {
                          address: {
                              additional_street: "",
                              city: "",
                              state: "",
                              street: "",
                              zip: "",
                          },
                          contact_id: account_id,
                          email: "",
                          phone: "",
                      },
                cart: getDefaultCartItems(order.order_items),
            };
        }
        if (currentStep === "checkout") {
            return {
                invoice_name: "",
            };
        }
        return {
            cart: [],
        };
    })();

    const currentStepSchema = (() => {
        if (currentStep === "build-quote") {
            return {
                contact_address: yup.object({
                    street: yup
                        .string()
                        .maxlength(100)
                        .required("This field is required"),
                    additional_street: yup.string().maxlength(12),
                    city: yup
                        .string()
                        .maxlength(60)
                        .required("This field is required"),
                    state: yup.string().required("This field is required"),
                    zip: VALIDATORS.US_POSTAL_CODE().required(
                        "This field is required"
                    )
                }),
                contact_id: yup.number().required("This field is required"),
                from_user_id: yup.number().required("This field is required"),
                name: VALIDATORS.BUSINESS_NAME()
                    .no_whitespace_only()
                    .required("This field is required"),
                quote_status: yup.string().required("This field is required"),
                expires: yup
                    .string()
                    .required("This field is required")
                    .test("valid_date", "Invalid date", (value) => {
                        return value
                            ? DateTimeUtils.isValid(
                                  value,
                                  DateTimeUtils.WORD_DATE_FORMAT
                              )
                            : true;
                    })
                    .test(
                        "cannot_select_past_dates",
                        "Expiration date should not be a past date",
                        function (value) {
                            if (!!!value) {
                                return true;
                            }
                            return !DateTimeUtils.dateIsBeforeAnotherDate(
                                value,
                                currentDate,
                                "days",
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                        }
                    )
                    .test(
                        "cannot_go_beyond_a_date",
                        "Expiration date should be at most 6 months from now",
                        function (value) {
                            if (!!!value) {
                                return true;
                            }
                            const maxDate = DateTimeUtils.changeFormat(
                                DateTimeUtils.getNext(6, "month", false, true),
                                DateTimeUtils.DATE_FORMAT,
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                            return DateTimeUtils.dateIsBeforeAnotherDate(
                                value,
                                maxDate,
                                "days",
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                        }
                    ),
                cart: yup
                    .array()
                    .min(1, "There should be at least one item selected")
                    .of(
                        yup.object(cartItemSchema)
                    )
                    .test('no_items_in_cart', "There should be at least one item in the cart", (items) => {
                        return !items.every(item => item.removed);
                    }),
            };
        }
        if (currentStep === "order" || currentStep === "edit-order") {
            return {
                order_status: yup.string().required("This field is required"),
                billing_address: yup.object({
                    address: yup.object({
                        street: yup
                            .string()
                            .maxlength(100)
                            .required("This field is required"),
                        additional_street: yup.string().maxlength(12),
                        city: yup
                            .string()
                            .maxlength(60)
                            .required("This field is required"),
                        state: yup.string().required("This field is required"),
                        zip: VALIDATORS.US_POSTAL_CODE().required(
                            "This field is required"
                        ),
                    }),
                    phone: VALIDATORS.PHONE_NUMBER().required(
                        "This field is required"
                    ),
                    email: VALIDATORS.EMAIL_ADDRESS().required(
                        "This field is required"
                    ),
                }),
                shipping_address: yup.object({
                    address: yup.object({
                        street: yup
                            .string()
                            .maxlength(100)
                            .required("This field is required"),
                        additional_street: yup.string().maxlength(12),
                        city: yup
                            .string()
                            .maxlength(60)
                            .required("This field is required"),
                        state: yup.string().required("This field is required"),
                        zip: VALIDATORS.US_POSTAL_CODE().required(
                            "This field is required"
                        ),
                    }),
                    phone: VALIDATORS.PHONE_NUMBER().required(
                        "This field is required"
                    ),
                    email: VALIDATORS.EMAIL_ADDRESS().required(
                        "This field is required"
                    ),
                }),
                cart: yup
                    .array()
                    .min(1, "There should be at least one item selected")
                    .of(
                        yup.object(cartItemSchema)
                    ),
            };
        }
        if (currentStep === "checkout") {
            return {
                invoice_name: yup.string().required("This field is required"),
                payment_method: yup.string().required("This field is required"),
                custom_ach: yup.string().when("payment_method", {
                    is: "custom-ach",
                    then: (schema) => schema.required("This field is required"),
                }),
                term: yup.string().required("This field is required"),
                due_on: yup
                    .string()
                    .required("This field is required")
                    .test("valid_date", "Invalid date", (value) => {
                        return value
                            ? DateTimeUtils.isValid(
                                  value,
                                  DateTimeUtils.WORD_DATE_FORMAT
                              )
                            : true;
                    })
                    .test(
                        "cannot_select_past_dates",
                        "Due date should not be a past date",
                        function (value) {
                            if (!!!value) {
                                return true;
                            }
                            return !DateTimeUtils.dateIsBeforeAnotherDate(
                                value,
                                currentDate,
                                "days",
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                        }
                    )
                    .test(
                        "cannot_go_beyond_a_date",
                        "Due date should be at most 6 months from now",
                        function (value) {
                            if (!!!value) {
                                return true;
                            }
                            const maxDate = DateTimeUtils.changeFormat(
                                DateTimeUtils.getNext(6, "month", false, true),
                                DateTimeUtils.DATE_FORMAT,
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                            return DateTimeUtils.dateIsBeforeAnotherDate(
                                value,
                                maxDate,
                                "days",
                                DateTimeUtils.WORD_DATE_FORMAT
                            );
                        }
                    ),
            };
        }
        return {
            cart: yup
                .array()
                .min(1, "There should be at least one item selected"),
        };
    })();

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: generateResolver(currentStepSchema),
    });

    const { trigger, setValue } = methods;

    useEffect(() => {
        if (currentStep === "build-quote" || currentStep.includes("order")) {
            trigger(`cart`);
        }
    }, [currentStep, trigger]);

    useEffect(() => {
        if (currentStep === "build-quote" || currentStep.includes("order")) {
            const items = currentStep === "build-quote" ? quote.quote_summary : order.order_items;
            items.forEach((item, index) => {
                const isRecurring = item.price_data.model?.includes("recurring");
                if (isRecurring) {
                    const pricingSchedules = item.price_data?.price;
                    if (!Boolean(pricingSchedules[item.pricing_schedule]?.active)) {
                        setValue(`cart[${index}].pricing_schedule`, Object.keys(pricingSchedules).filter(frequency => pricingSchedules[frequency].active)[0], { shouldDirty: true });
                    }
                }                
            })
        }
    }, [currentStep, setValue, quote, order])

    return (
        <FormProvider {...methods}>
            {currentStep === "build-quote" && (
                <EditQuote
                    account_id={account_id}
                    opportunity_id={opportunity_id}
                    isLinkedToCustomer={isLinkedToCustomer}
                    contact_id={contact_id}
                    account_primary_contact_id={account_primary_contact_id}
                />
            )}
            {currentStep === "add-products" && (
                <AddProducts
                    opportunity_id={opportunity_id}
                    account_id={account_id}
                    contact_id={contact_id}
                />
            )}
            {(currentStep === "order" || currentStep === "edit-order") && (
                <Order account_id={account_id} contact_id={contact_id} />
            )}
            {currentStep === "checkout" && <Payment account_id={account_id} />}
        </FormProvider>
    );
};

const QuoteBuilderModal = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id
}) => {
    const {
        isReading,
        quote,
        quoteVersion,
        quoteVersions,
        isReadingQuoteVersions,
        quoteVersionError,
        orderError,
    } = useSelector((state) => ({
        isReading:
            state.quote.quoteBeingRead ||
            state.quote.quoteVersionBeingRead ||
            state.order.orderBeingRead,
        isReadingQuoteVersions: state.quote.quoteVersionsBeingRead,
        quote: state.quote.quote,
        quoteVersion: state.quote.quoteVersion,
        quoteVersions: state.quote.quoteVersions,
        quoteVersionError: state.quote.quoteVersionError,
        orderError: state.order.orderError,
    }));

    const { quoteBuilderConfig, onCloseModal, onViewProposal } =
        useContext(QuoteBuilderContext);

    const { currentStep, id, isUpsell } = quoteBuilderConfig || {
        currentStep: null,
        id: null,
        isUpsell: false,
    };

    const getModalHeader = () => {
        if (currentStep === "add-products") {
            return opportunity_id ? (
                "Add Products"
            ) : (
                <>
                    Product Catalog
                    {!isUpsell && (
                        <Popup
                            trigger={
                                <Icon
                                    size="small"
                                    style={{
                                        float: "right",
                                        marginRight: "1em",
                                        marginTop: "0.2em",
                                    }}
                                    className="fas fa-circle-info"
                                    color="primary"
                                />
                            }
                            content={
                                "Quotes can only be added to an opportunity"
                            }
                            inverted
                            position="bottom right"
                            size="mini"
                        />
                    )}
                </>
            );
        }
        if (currentStep === "build-quote") {
            return "Quote Builder";
        }
        if (currentStep === "order" || currentStep === "checkout") {
            return "Create Order";
        }
        if (currentStep === "edit-order") {
            return "Edit Order";
        }
        if (currentStep === "proposal-preview") {
            if (quoteVersionError) {
                return "Proposal";
            }
            return (
                <>
                    {quoteVersion?.quote_information?.name}
                    {!isReadingQuoteVersions && quoteVersions.length > 1 && (
                        <Dropdown
                            selection
                            options={quoteVersions.map((version) => ({
                                key: version.id,
                                value: version.id,
                                text: version.name,
                            }))}
                            value={id}
                            onChange={(_, { value }) => {
                                onViewProposal(value);
                            }}
                            selectOnBlur={false}
                            style={{ float: "right", marginRight: "1em" }}
                        />
                    )}
                </>
            );
        }
        return null;
    };

    const content = (() => {
        if (isReading || (currentStep === "build-quote" && !Boolean(quote))) {
            return (
                <Modal.Content>
                    <Segment basic loading />
                </Modal.Content>
            );
        }
        if (
            (currentStep === "proposal-preview" && quoteVersionError) ||
            (currentStep === "order" && orderError)
        ) {
            return (
                <Modal.Content>
                    <Error500 height="50vh" message={"Something went wrong"} />
                </Modal.Content>
            );
        }
        if (currentStep === "proposal-preview") {
            return <ProposalPreview opportunity_id={opportunity_id} />;
        }
        return (
            <ModalContent
                account_id={account_id}
                opportunity_id={opportunity_id}
                contact_id={contact_id}
                isLinkedToCustomer={isLinkedToCustomer}
                account_primary_contact_id={account_primary_contact_id}
            />
        );
    })();

    return (
        <Modal size="big" open={Boolean(currentStep)} onClose={onCloseModal}>
            <Modal.Header>{getModalHeader()}</Modal.Header>
            {content}
        </Modal>
    );
};

export default QuoteBuilderModal;
