import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid } from 'semantic-ui-react';
import LocationsHeader from './LocationsHeader';
import LocationsTable from './LocationsTable';
import AddLocationModal from './AddLocationModal';


const Locations = ({
    locations,
    isAddLocationModalOpen,
    onOpenAddLocationModal,
    onCloseAddLocationModal,
    onUpdate,
    onDelete,
}) => (
        <TableWithHeader
            header={
                <LocationsHeader onOpenAddLocationModal={onOpenAddLocationModal} />
            }
            table={
                <Grid style={{ margin: 0 }}>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0,  overflowX: 'scroll'  }}>
                            <LocationsTable
                                locations={locations}
                                onUpdate={onUpdate}
                                onDelete={onDelete} />
                        </Grid.Column>
                    </Grid.Row>
                    <AddLocationModal
                        open={isAddLocationModalOpen}
                        onClose={onCloseAddLocationModal} />
                </Grid>
            }
        />
    );


class LocationsContainer extends React.Component {
    state = {
        isAddLocationModalOpen: false
    }

    onOpenAddLocationModal = () => {
        this.setState({
            isAddLocationModalOpen: true
        })
    }

    onCloseAddLocationModal = () => {
        this.setState({
            isAddLocationModalOpen: false
        })
    }

    render() {
        return (
            <Locations
                {...this.state}
                {...this.props}
                onOpenAddLocationModal={this.onOpenAddLocationModal}
                onCloseAddLocationModal={this.onCloseAddLocationModal}
            />
        )
    }
}

export default LocationsContainer;
