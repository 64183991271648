import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import quoteActions from "actions/quote";
import orderActions from "actions/order";
import invoiceActions from "actions/invoice";

export const QuoteBuilderContext = createContext();

const QuoteBuilderProvider = ({ children, refreshAccountInformation }) => {
    const dispatch = useDispatch();

    const [quoteBuilderConfig, setQuoteBuilderConfig] = useState({
        currentStep: null,
        id: null,
    });

    const onOpenAddProducts = (config) => {
        const { quote_id = null, isUpsell = false, order_id = null } = config || {};
        setQuoteBuilderConfig({
            currentStep: "add-products",
            id: quote_id || order_id || null,
            isUpsell,
        });
    };

    const onRefreshAccountInformation = () => {
        if (refreshAccountInformation) {
            return refreshAccountInformation();
        }
        return null;
    };

    const onEditQuote = (quote_id) => {
        if (quote_id) {
            dispatch(quoteActions.readQuote(quote_id));
        }
        setQuoteBuilderConfig({
            currentStep: quote_id ? "build-quote" : "add-products",
            id: quote_id || null,
        });
    };

    const onViewProposal = (version_id) => {
        dispatch(quoteActions.getVersion(version_id));
        setQuoteBuilderConfig({
            currentStep: "proposal-preview",
            id: version_id,
        });
    };

    const onViewOrder = (order_id, account_id) => {
        if (order_id) {
            dispatch(orderActions.readOne(account_id, null, order_id));
        }
        setQuoteBuilderConfig({
            currentStep: "order",
            id: order_id,
        });
    };

    const onEditOrder = (order_id, account_id) => {
        dispatch(orderActions.readOne(account_id, null, order_id));
        setQuoteBuilderConfig({
            currentStep: "edit-order",
            id: order_id,
        });
    };

    const onCheckout = ({ checkout }) => {
        setQuoteBuilderConfig({
            ...quoteBuilderConfig,
            checkout,
            currentStep: "checkout",
        });
    };

    const onViewInvoice = (invoice_id, account_id) => {
        dispatch(invoiceActions.readOne(account_id, null, invoice_id));
        setQuoteBuilderConfig({
            currentStep: "invoice",
            id: invoice_id
        })
    }

    const onCloseModal = () => {
        setQuoteBuilderConfig({ currentStep: null, id: null });
    };

    return (
        <QuoteBuilderContext.Provider
            value={{
                quoteBuilderConfig,
                onOpenAddProducts,
                onEditQuote,
                onViewProposal,
                onCloseModal,
                onViewOrder,
                onCheckout,
                onRefreshAccountInformation,
                onEditOrder,
                onViewInvoice
            }}
        >
            {children}
        </QuoteBuilderContext.Provider>
    );
};

export default QuoteBuilderProvider;
