import routes from '../routes';
import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateUpdateActionCreator } from "../crudActionCreators";
import ACTION_NAMES from "../ACTION_NAMES";

export default {
    read: generateReadActionCreator(routes.API_PHONE_NUMBER, ACTION_NAMES.PHONE_NUMBER),
    search: generateReadActionCreator(routes.API_PHONE_NUMBER_SEARCH, ACTION_NAMES.PHONE_NUMBER_SEARCH),
    add: generateCreateActionCreator(routes.API_PHONE_NUMBER, ACTION_NAMES.PHONE_NUMBER),
    order: generateCreateActionCreator(routes.API_PHONE_NUMBER, ACTION_NAMES.PHONE_NUMBER_ORDER, () => {}, () => {}, 'order'),
    delete: generateDeleteActionCreator(routes.API_PHONE_NUMBER, ACTION_NAMES.PHONE_NUMBER),
    update: generateUpdateActionCreator(routes.API_PHONE_NUMBER, ACTION_NAMES.PHONE_NUMBER),
    readDomains: generateReadActionCreator(routes.API_PHONE_NUMBER_DOMAIN, ACTION_NAMES.PHONE_NUMBER_DOMAIN),
    clearSearchReuslts: () => {
        return {
            type: 'PHONE_NUMBER_CLEAR_SEARCH'
        }
    },
    mark: (phone) => {
        return {
            type: 'PHONE_NUMBER_MARK',
            phone
        }
    },
    markAll: () => {
        return {
            type: 'PHONE_NUMBER_MARK_ALL'
        }
    }
}
