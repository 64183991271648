export default [
    {
        settingName: "hostedPaymentButtonOptions",
        settingValue: '{"text": "Process"}',
    },
    {
        settingName: "hostedPaymentStyleOptions",
        settingValue: '{"bgColor": "blue"}',
    },
    {
        settingName: "hostedPaymentSecurityOptions",
        settingValue: '{"captcha": false}',
    },
    {
        settingName: "hostedPaymentShippingAddressOptions",
        settingValue: '{"show": false, "required": false}',
    },
    {
        settingName: "hostedPaymentBillingAddressOptions",
        settingValue: '{"show": true, "required": false}',
    },
    {
        settingName: "hostedPaymentCustomerOptions",
        settingValue:
            '{"showEmail": false, "requiredEmail": false, "addPaymentProfile": true}',
    },
    {
        settingName: "hostedPaymentOrderOptions",
        settingValue: '{"show": true, "merchantName": "DYL"}',
    },
];
