import React from 'react';
import { TableWithHeader } from 'dyl-components';
import DevicesTable from './DevicesTable';
import DevicesHeader from './DevicesHeader';
import { Grid } from 'semantic-ui-react';
import AddDeviceModal from './AddDeviceModal';

const Devices = ({
    locations,
    devices,
    onUpdate,
    onDelete,
    isAddDeviceSectionOpen,
    onOpenAddDeviceSection,
    onCloseAddDeviceSection,
    extensions
}) => (
        <TableWithHeader
            header={
                <DevicesHeader
                    onOpenAddDevicesSection={onOpenAddDeviceSection}
                />
            }
            table={
                <Grid style={{ margin: 0 }}>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0, overflowX: 'scroll' }}>
                            <DevicesTable
                                locations={locations}
                                devices={devices}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                extensions={extensions}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <AddDeviceModal
                        open={isAddDeviceSectionOpen}
                        onClose={onCloseAddDeviceSection}
                    />
                </Grid>
            }

        />
    );

class DevicesContainer extends React.Component {
    state = {
        isAddDeviceSectionOpen: false
    }

    onOpenAddDeviceSection = () => {
        this.setState({ isAddDeviceSectionOpen: true });
    }

    onCloseAddDeviceSection = () => {
        this.setState({ isAddDeviceSectionOpen: false });
    }

    render() {
        return (
            <Devices
                {...this.props}
                {...this.state}
                onOpenAddDeviceSection={this.onOpenAddDeviceSection}
                onCloseAddDeviceSection={this.onCloseAddDeviceSection}
            />
        )
    }
}

export default DevicesContainer;
