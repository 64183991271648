import React, { useEffect, useState } from 'react';
import { TimelineTabForm, TimelineTabHeader, Notification, STATUS_TYPES } from 'dyl-components';
import { Header, Icon, Segment } from 'semantic-ui-react';

import EventForm from 'shared/EventForm';
import Timeline from './subcomponents/Timeline';

import './index.scss';
import { connect } from 'react-redux';
import { DateTimeUtils } from 'dyl-components';
import eventsActions from 'actions/events';
import eventAttendeeActions from 'actions/event_attendee';
import eventActions from 'actions/event';
import uploadActions from 'actions/upload';
import eventAttachmentActions from 'actions/event_attachment';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import accountActions from 'actions/account';
import { useSelector } from 'react-redux';
import useEventForm from 'utils/useEventForm';

import Utils from 'shared/EventForm/Utils'

const EventsTab = ({
    user_id,

    onReadEvents,
    onReadEventLabels,

    onAddAttachments,
    onUpload,
    onAddEvent,
    onRemoveAttachment,
    onUpdateEvent,
    onDeleteEvent,
    onReadEvent,

    events,
    isReadingEvents,

    isReadingEvent,
    isReadingEventLabels,
    event_labels,

    isDeletingEvent,
    isSaving,

    refreshEventsSidePanel,

    accountContactIds,
    accountContacts,

    onRemoveAttendee,
    onAddAttendees,

    contact_account_id,
    onReadContacts,
}) => {
    const [params] = useSearchParams();
    const status = params.get('status') || 'upcoming';

    const { pathname } = useLocation();
    const record_type = pathname.split('/')[1];
    let { account_id, contact_id } = useParams();
    //TODO: Add for CER-3340
    // const associated_account = useSelector(state => state.contact.account_id)
    const record_id = Number(contact_id || account_id); //only for routing
    const isContact = record_type === 'contact';
    //TODO: Add for CER-3340
    // account_id = !isContact ? Number(account_id) : associated_account !== 0 ? associated_account : 0;
    // contact_id = Number(contact_id);
    const contact_ids = isContact ? contact_id : accountContactIds;
    const currentContact = useSelector(state=> state.contact);
    const tz = useSelector(state => state.auth.timezone);
    
    const [state, setState] = useState({
        isFormTabOpen: true,
        formKey: isContact ? 'CREATE_FORM' : '',
        saving: false
    });

    const { control, watch, isValid, isDirty, trigger, setValue, getValues, reset, eventBeingEdited, loadEvent, isAllowedToModify } = useEventForm({contact_id});
    
    useEffect(() => {
        onReadEvents(contact_ids, status);
        //TODO: Add for CER-3340
        // onReadEvents(contact_id, account_id, status, isContact); 
        if (isContact) {
            if (contact_account_id) {
                onReadContacts(contact_account_id);
            }
            refreshEventsSidePanel(contact_ids);
            //TODO: Add for CER-3340
            // refreshEventsSidePanel(contact_id, account_id, isContact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onReadEvents, params, status]);

    useEffect(() => {
        if (isContact) {
            onReadEventLabels();
        }
    }, [onReadEventLabels, isContact]);

    const navigate = useNavigate();

    const routeRoot = `/${record_type}/${record_id}/events`;

    const handleItemClick = (_, { name }) => {
        const query = new URLSearchParams();
        query.set('status', name);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onToggleFormTab = () => {
        setState({
            isFormTabOpen: !state.isFormTabOpen,
            formKey: 'CREATE_FORM',
            saving: false
        });
        loadEvent(null)
    }

    const addAttachments = async (files, event_id, file_type) => {
        if (files && files.length > 0) {
            const toUpload = files.filter(file => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name
            }));
            const uploadedFiles = await Promise.all(toUpload.map(file => onUpload(file, file_type)));
            return onAddAttachments([
                ...uploadedFiles.map((file, index) => ({
                    file_id: file.id,
                    name: files[index].name
                })),
                ...toCopy
            ], user_id, event_id);
        }
        return Promise.resolve();
    }

    const addEvent = async (values) => {
        const { users, attachments, contacts } = values;
        const attendees = [
            ...contacts.map(contact_id => ({
                accepted: "no",
                contact_id,
                emailed: false
            })),
            ...users.map(user_id => ({
                accepted: "no",
                user_id,
                emailed: false
            }))
        ]

        try {
            setState({ ...state, saving: true });
            const { id } = await onAddEvent(values);
            await addAttendees(id, attendees);
            await addAttachments(attachments, id, 'pdf');
            Notification.alert('Successfully created event!', STATUS_TYPES.SUCCESS);
            navigate(`${routeRoot}`);
            navigate(`${routeRoot}?status=upcoming`);
            DateTimeUtils.setTimezone(tz);
            setState({ ...state, saving: false });
            return true;
        } catch (e) {
            console.log(e);
            setState({ ...state, saving: false });
            Notification.alert('Failed to create event', STATUS_TYPES.ERROR);
            return false;
        }
    }

    const removeAttachments = (files, event_id) => {
        if (!files) {
            files = [];
        }
        const toRemove = eventBeingEdited.attachments.filter(attachment => files.findIndex(file => file.id === attachment.id) === -1);
        if (toRemove.length <= 0) {
            return Promise.resolve();
        }
        return Promise.all(toRemove.map(file => onRemoveAttachment(file.id, event_id, user_id)));
    }

    const addAttendees = (event_id, usersToAdd) => {
        let params = { payload: usersToAdd, integration_id: null, access_token: null }
        if (usersToAdd.length > 0) {
            return onAddAttendees(event_id, params);
        }
        return Promise.resolve()
    }

    const removeAttendees = (event_id, usersToRemove) => {
        let params = { event_id, integration_id: null, access_token: null };
        if (usersToRemove.length > 0) {
            return Promise.all(usersToRemove.map(user => onRemoveAttendee(user, params)));
        }
        return Promise.resolve();
    }

    const updateAttendees = async (updatedAttendees, event_id) => {
        const attending_users = eventBeingEdited.attendees.users;

        const updatedUsers = updatedAttendees.filter((attendee) => attendee.user_id);

        const attending_contacts = eventBeingEdited.attendees.contacts;
        const updatedContacts = updatedAttendees.filter(attendee => attendee.contact_id);

        let usersToAdd = updatedUsers.filter(({user_id}) => {
            return !attending_users.includes(user_id)
        })

        const usersToRemove = attending_users.filter((user) => (
            usersToAdd.findIndex(({ user_id }) => user_id === user) === -1 &&
            updatedUsers.findIndex(({ user_id }) => user_id === user) === -1
        ));

        let contactsToAdd = updatedContacts.filter(({contact_id}) => {
            return !attending_contacts.includes(contact_id)
        })

        const contactsToRemove = attending_contacts.filter((contact) => (
            contactsToAdd.findIndex(({ contact_id }) => contact_id === contact) === -1 &&
            updatedContacts.findIndex(({ contact_id }) => contact_id === contact) === -1
        ));

        await Promise.all([
            addAttendees(event_id, [...usersToAdd, ...contactsToAdd]),
            removeAttendees(event_id, [...usersToRemove, ...contactsToRemove])
        ]);
    }

    const onUpdate = async (event) => {
        const { attachments, users, contacts } = event;
        
        const { id } = eventBeingEdited;
        const attendees = [
            ...contacts.map(contact_id => ({
                accepted: "no",
                contact_id,
                emailed: false
            })),
            ...users.map(user_id => ({
                accepted: "no",
                user_id,
                emailed: false
            }))
        ];
        const updateEvent = { ...event, event_id: id};
        try {
            setState({ ...state, saving: true });
            await onUpdateEvent(id, updateEvent);
            const files = attachments;
            const filesToAdd = files.filter(file => file.id === undefined);
            await Promise.all([
                addAttachments(filesToAdd, id, 'pdf'),
                removeAttachments(files.filter(file => file.id), id),
                updateAttendees(attendees, id)
            ])
            Notification.alert('Successfully updated the event!', STATUS_TYPES.SUCCESS);
            onReadEvents(contact_ids, status);
            //TODO: Add for CER-3340
            // onReadEvents(contact_id, account_id, status, isContact);
            DateTimeUtils.setTimezone(tz);
            setState({ ...state, saving: false, event: null });
        } catch (e) {
            console.log(e);
            setState({ ...state, saving: false });
            Notification.alert('Failed to update the event', STATUS_TYPES.ERROR);
        }
    }

    const onEditEvent = (event_id) => {
        onReadEvent(event_id)
            .then((event) =>{
                event.id = event_id // TODO: make calendar-api return this value
                loadEvent(event);
                setState({
                    ...state,
                    formKey: 'EDIT_FORM',
                    created_user_id: event.created_user_id,
                });
                
            });
    }

    const onDelete = async (id) => {
        try {
            await onDeleteEvent(id);
            Notification.alert('Successfully deleted event!', STATUS_TYPES.SUCCESS);
            onReadEvents(contact_ids, status);
            //TODO: Add for CER-3340
            // onReadEvents(contact_id, account_id, status, isContact);
            onToggleFormTab();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete event', STATUS_TYPES.ERROR)
        }
    }

    return (
        <TimelineTabForm
            timelineHeader={
                <Header className='EventsTab__Header' as='h2'>
                    {isContact && <Icon className='TimelineTab__add' name='plus' color='blue' link circular inverted size='tiny' onClick={onToggleFormTab} />}
                    Events
                </Header>
            }
            timelineSubHeader={
                <TimelineTabHeader
                    activeItem={status}
                    onClick={handleItemClick}
                    titleCounts={[{ title: 'upcoming' }, { title: 'previous' }]}
                />
            }
            timelineBody={
                isReadingEvents ? <Segment basic loading padded='very' /> :
                    <Timeline
                        expanded={state.isFormTabOpen}
                        items={events}
                        onEditEvent={onEditEvent}
                        isReadingEvents={isReadingEvents}
                    />
            }
            formHeader={
                isContact || eventBeingEdited.id ? (
                    <Header className='EventForm__Header'>
                        {eventBeingEdited.id ? `${isAllowedToModify ? 'Edit' : 'View'} '${eventBeingEdited.name}'` : 'New Event'}
                    </Header>
                ) : null
            }
            formBody={
                isContact || eventBeingEdited.id ? (
                    <EventForm
                        key={state.formKey}
                        isAllowedToModify={isAllowedToModify}
                        onClose={onToggleFormTab}
                        eventBeingEdited={eventBeingEdited}
                        onSave={addEvent}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                        isSaving={state.saving}
                        isDeleting={isDeletingEvent}
                        isReading={isReadingEvent || isReadingEventLabels}
                        contacts={(()=>{
                            if (accountContacts.length === 0 && isContact) {
                                const { first_name, last_name, id } = currentContact.contact
                                const { email } = currentContact;
                                const mainEmail = email.find(({main}) => main);
                                const displayEmail = mainEmail ? mainEmail.email : currentContact.email[0]?.email
                                return [{text: `${first_name} ${last_name}`, key: id, email: displayEmail}]
                            }
                            return accountContacts
                        })()}
                        event_labels={event_labels || []}
                        contact_id={isContact && Number(contact_id)}
                        control={control}
                        watch={watch}
                        isValid={isValid}
                        isDirty={isDirty}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                        reset={reset}
                    />
                ) : null
            }
            //primary-table-6
            color='#214BD6'
            expanded={state.isFormTabOpen}
            loading={isReadingEvents || isSaving}
        />
    )
}

const mapStateToProps = state => {
    const { first_name = '', last_name = '', email: organizer_email } = state.users.userProfile;
    const isProcessingAttachments = state.upload.isUploadingFile || state.event_attachment.isAdding;
    const contact_id = Number(state.contact.contact_id);
    return ({
        organizer_id: state.auth.user_id,
        organizer: `${first_name} ${last_name}`,
        organizer_email,
        event_labels: state.events.event_labels.map(({ id, name }) => ({ key: id, value: id, text: name })),
        events: state.events.events_tab_events.map(event => ({
            ...event,
            start_date: event.start_time,
            end_date: event.end_time
        })),
        contact_id,
        isReadingEvents: state.events.isReadingEventsTab,
        isReadingEvent: state.event.isReadingEvent,
        isReadingEventLabels: state.events.isReadingEventLabels,
        isDeletingEvent: state.event.isDeletingEvent,
        isSaving: state.events.isAddingEvents || state.event_attendee.isAdding || state.event.isUpdatingEvent || isProcessingAttachments,
        accountContactIds: state.account.contactIds.map(({ id }) => id),
        accountContacts: state.account.contactIds.map(({ first_name, last_name, email, suffix, id }) => ({
            text: `${first_name || ''} ${last_name || ''}${suffix ? `, ${suffix}` : ''}`,
            email,
            key: id,
            value: id
        })),

        contact_account_id: Number(state.contact.account_id)
    });
};

const mapDispatchToProps = dispatch => ({
    //TODO: Add for CER-3340
    // refreshEventsSidePanel: (contact_id, account_id, isContact) => {
        refreshEventsSidePanel: (contact_id) => {
        const start_time = DateTimeUtils.getLast(2, 'week');
        const end_time = DateTimeUtils.getNext(1, 'year');
        dispatch(eventsActions.readEvents({ contact_id, start_time, end_time, limit: 5 }));
        //TODO: Add for CER-3340
        // dispatch(eventsActions.readEvents({ 
        //     ...(isContact ? {contact_id} : {}),
        //     ...(!isContact ? {account_id} : {}),
        //     start_time, 
        //     end_time, 
        //     limit: 5 
        // }));
    },
    onOpenDetailsSection: () => {
        window.scrollTo(0, 0);
    },
    onReadEvents: (contact_id, filter) => {
        //TODO: Add for CER-3340
        // (contact_id, account_id, filter, isContact) => {
        const currentDate = DateTimeUtils.getCurrentDate(null, true, false)
        const end = DateTimeUtils.addOneYear(currentDate);
        const start = DateTimeUtils.subtractOneYear(currentDate);

        const end_time = filter === 'upcoming' ? end : currentDate;
        const start_time = filter === 'upcoming' ? currentDate : start;
        const include_ongoing = filter === 'upcoming' ? true : false;
        const timezone = DateTimeUtils.getTimeZone();

        if ( typeof contact_id === 'object' && contact_id.length === 0) {
            // events are only related to contacts
            // if no contact_id provided we use [0] as empty arrays disable the filter by contact 
            contact_id = [0];
        }
        dispatch(eventsActions.readEvents({ contact_id, start_time, end_time,timezone, include_ongoing }));
        dispatch(eventsActions.loadEventsTab({ contact_id, start_time, end_time, timezone, include_ongoing }));
        //TODO: Add for CER-3340
        // dispatch(eventsActions.readEvents({ 
        //     ...(isContact ? {contact_id} : {}),
        //     ...(!isContact ? {account_id} : {}),
        //     start_time, 
        //     end_time, 
        //     timezone, 
        //     include_ongoing 
        // }));
        // dispatch(eventsActions.loadEventsTab({ 
        //     ...(isContact ? {contact_id} : {}),
        //     ...(!isContact ? {account_id} : {}),
        //     start_time, 
        //     end_time, 
        //     timezone,
        //     include_ongoing 
        // }));
    },
    onReadEvent: (id) => {
        const timezone = DateTimeUtils.getTimeZone();
        return dispatch(eventActions.getEvent(id,{timezone}));
    },
    onAddEvent: async (event) => {
        const payload = Utils.toPayload(event);
        const id = await dispatch(eventActions.addEvent(payload));
        return id;
    },
    onDeleteEvent: (id) => {
        return dispatch(eventActions.deleteEvent(id));
    },
    onUpdateEvent: (id, event) => {
        const payload = Utils.toPayload(event);
        return dispatch(eventActions.updateEvent(id, payload));
    },
    onAddAttendees: (event_id, params) => {
       return dispatch(eventAttendeeActions.addAttendees(params, null, event_id));
    },
    onRemoveAttendee: (attendee_id, params) => {
      return dispatch(eventAttendeeActions.removeAttendee(attendee_id, params))
    },
    onUpload: (file, file_type) => {
        return dispatch(uploadActions.upload(file, file_type))
    },
    onAddAttachments: (attachments, user_id, event_id) => {
        return dispatch(eventAttachmentActions.addAttachments(attachments, { user_id }, event_id))
    },
    onRemoveAttachment: (attachment_id, event_id, user_id) => {
        return dispatch(eventAttachmentActions.removeAttachment(attachment_id, { user_id, event_id }));
    },
    onReadEventLabels: () => {
        dispatch(eventsActions.readEventLabels());
    },
    onReadContacts: (account_id) => {
        dispatch(accountActions.readContactIds(account_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsTab);
