import React, { useContext } from "react";
import {
    Table,
    EllipsisDropdown,
    ControlledPopup,
} from "dyl-components";
import {
    DropdownItem,
    Dropdown,
    Icon,
    Checkbox,
    Header,
} from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { PhoneUtil } from 'utils';


const Row = ({ extension, typeCounts, onReadCompanyExtension, onRemove }) => {
    const [selectedExtensions, setSelectedExtensions] =
        useContext(BulkActionsContext);

    const isSelected = (extension_id) => {
        return selectedExtensions.includes(extension_id);
    };

    const onToggleExtension = (extension_id) => {
        const newSelectedExtensions = isSelected(extension_id)
            ? selectedExtensions.filter((id) => extension_id !== id)
            : [...selectedExtensions, extension_id];
        setSelectedExtensions(newSelectedExtensions);
    };

    function getTextFromKey(key) {
        const extension = PhoneUtil.COMPANY_EXTENSION_TYPES.find(ext => ext.key === key);
        return extension ? extension.text : null;
      }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(extension.id)}
                    onChange={() => {
                        onToggleExtension(extension.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>{extension.extension}</Table.Cell>
            <Table.Cell>{getTextFromKey(extension.type)}</Table.Cell>
            <Table.Cell>{extension.label}</Table.Cell>
            <Table.Cell>{extension.display_view ? "on" : "off"}</Table.Cell>
            <Table.Cell>{extension.pin}</Table.Cell>
            <Table.Cell>
                <Icon
                    name="fa-solid fa-music vpiceboxPin-info-icon"
                    color="primary"
                />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        style={{ cursor: "pointer" }}
                        text="Edit"
                        icon="fas fa-edit"
                        onClick={() => onReadCompanyExtension(extension.id, extension.type)}
                    />
                    <ControlledPopup
                        header={
                            <Header as="h4" textAlign="center">
                                Are you sure?
                            </Header>
                        }
                        trigger={
                            <Dropdown.Item
                                icon="fas fa-trash-alt"
                                text="Delete"
                                disabled={((extension?.type === 'conference_room' || extension?.type === 'parking_lot') && typeCounts[extension?.type] <= 1) || extension.in_use}
                            />
                        }
                        onConfirm={() => onRemove(extension.id, extension.type)}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
};

export default Row;
