import React from 'react';
import {Form, Header, Segment, Transition} from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import ConvertToCustomerFields from "../ConvertToCustomerFields";

const CustomerAccountInfo = ({
    control,
    resetField,
    segmentToggleName,
    pipelineName,
    stageName,
    typeName,
    trigger,
    isCampaignConvertedDisabled = false
}) => {
    return (
        <>
            <Header as='h3'>Customer Account Information</Header>
            <Controller
                name={segmentToggleName}
                control={control}
                render={({ field: { name: addCustomerName, onChange: onAddCustomerChange, value: addCustomerValue } }) => (
                    <>
                        <Form.Radio
                            label='Add as Customer'
                            toggle
                            onChange={(_, { value }) => {
                                if (!value) {
                                    resetField(pipelineName);
                                    resetField(stageName);
                                    resetField(typeName);
                                }
                                trigger('customerHasPrimaryContact');
                                onAddCustomerChange({ target: { name: addCustomerName, value } });
                            }}
                        />
                        <Transition visible={addCustomerValue} animation={'slide down'} duration={'150'} unmountOnHide>
                            <Segment basic style={{ padding: 0 }}>
                                <ConvertToCustomerFields control={control} pipelineName={pipelineName} stageName={stageName} typeName={typeName} isCampaignConvertedDisabled={isCampaignConvertedDisabled} />
                            </Segment>
                        </Transition>
                    </>
                )}
            />
        </>
    );
}

export default CustomerAccountInfo;
