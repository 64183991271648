import { Table } from "dyl-components";
import {
    Table as SemanticTable,
} from "semantic-ui-react";
import React from "react";
import QuoteItemRow from "./QuoteItemRow";

const QuoteItemsTable = ({ quote, cart, disabled }) => {
    return (
        <Table striped={false}>
            <Table.Header>
                <Table.Row>
                    <SemanticTable.HeaderCell>
                        Product
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={3}>
                        Description
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Pricing Schedule
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Price
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={2}>
                        Quantity
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Sum
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Discount
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Prorated
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Subtotal
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={1}>
                        Actions
                    </SemanticTable.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {cart.map((item, index) => (
                    <QuoteItemRow
                        index={index}
                        item={item}
                        key={item.key}
                        quote={quote}
                        disabled={disabled}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};

export default QuoteItemsTable;
