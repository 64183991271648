import React from 'react';
import { Modal } from 'dyl-components';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

const EditSoftphoneModal = ({
    softphone,
    onChange,

    open,
    onClose,
    onUpdate,
    softphoneBeingUpdated,
    extensions,
    locations
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Edit Softphone
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Input 
                    name='name'
                    value={softphone.name}
                    onChange={onChange}
                    label='Name'
                />
                <Form.Select 
                    name='extension_id'
                    value={softphone.extension_id}
                    onChange={onChange}
                    options={extensions}
                    label='Extension'
                />
                <Form.Select 
                    name='location_id'
                    value={softphone.location_id}
                    onChange={onChange}
                    options={locations}
                    label='Location'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            {!softphoneBeingUpdated && <Button negative onClick={onClose}>Cancel</Button>}
            <Button primary onClick={onUpdate} loading={softphoneBeingUpdated}>Update</Button>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = (state, { softphone }) => {
    const assignedExtensions = state.provision.softphones.map(({ extension }) => extension.id);
    return {
        softphoneBeingUpdated: state.provision.softphoneBeingUpdated,
        locations: state.provision.locations.map(({ id, label }) => ({
            key: id,
            value: id,
            text: label
        })),
        extensions: state.pbx_config.extensions.filter(({ id }) => !assignedExtensions.includes(id) || softphone.extension_id === id).map(({ id, extension_number }) => ({
            key: id,
            value: id,
            text: extension_number
        }))
    }
}

export default connect(mapStateToProps)(EditSoftphoneModal);
