import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function ringGroupReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingRingGroup: true };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingRingGroup: false, ring_group: action.data };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingRingGroup: false };

        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, groupBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, groupBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, groupBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, ringGroupBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, ringGroupBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.RING_GROUP, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, ringGroupBeingDeleted: null };

            case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
                return { ...state, isReadingGroups: true };
            case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
                return { ...state, isReadingGroups: false, groupsData: action.data?.data, groupsCount: action.data?.count };
            case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
                return { ...state, isReadingGroups: false };

        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingMembers: true };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingMembers: false, members: action.data.data };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingMembers: false };

        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingMembers: true };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingMembers: false };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingMembers: false };

        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, memberBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, memberBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, memberBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, memberBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, memberBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.RING_GROUP_MEMBERS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, memberBeingDeleted: null };
        default:
            return state;
    }
}

export default ringGroupReducer;
