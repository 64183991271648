const initialState = {
    token: null,
    isReadingFormToken: false,
    customerProfileId: null,

    isReadingProfile: false,
    balance: 0,
    profiles: [],
    isSavingProfile: false,
    isDeactivatingProfile: false
}

export default initialState;
