import React from "react";
import { Form } from "semantic-ui-react";
import { FileInput, Notification, STATUS_TYPES } from "dyl-components";

import "./index.scss";

const AddGreetingForm = ({ onChange, files, onUpload, onCancel }) => (
    <Form>
        <Form.Group widths="equal">
            <Form.Field>
                <FileInput
                    onChange={onChange}
                    name="files"
                    files={files}
                    accept="audio/*"
                    icon="file audio outline"
                    onReject={(rejected) => {
                        if (rejected.length > 0) {
                            Notification.alert(
                                "File must be audio.",
                                STATUS_TYPES.ERROR,
                                true
                            );
                        }
                    }}
                />
            </Form.Field>
        </Form.Group>
        <Form.Group className="AddGreetingForm">
            <Form.Button
                floated="right"
                content={"Cancel"}
                onClick={onCancel}
            />
            <Form.Button
                primary
                floated="right"
                content={"Upload"}
                onClick={onUpload}
            />
        </Form.Group>
    </Form>
);

class AddGreetingFormContainer extends React.Component {
    state = {
        files: null,
    };

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    };

    onUpload = () => {
        this.props.onUpload({ ...this.state });
        this.setState({
            files: null,
        });
    };

    render() {
        return (
            <AddGreetingForm
                {...this.props}
                {...this.state}
                onUpload={this.onUpload}
                onChange={this.onChange}
            />
        );
    }
}

export default AddGreetingFormContainer;
