import React from 'react';
import { createSearchParams, Link, useLocation } from 'react-router-dom';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { ClippedContent } from 'dyl-components';
import { StringUtils } from 'utils';

const ModuleLink = ({ pipeline_id, includeLabel, idWidth = 7, unlinked }) => {
    const location = useLocation();

    return (
        <Popup
            trigger={(
                <Grid columns={'equal'}>
                    <Grid.Column width={idWidth}>
                        <ClippedContent popup={!unlinked} popupContent={!unlinked ? pipeline_id : ""}>
                            {includeLabel && 'ID: '}
                            {unlinked ? "Moved" : (
                                <Link to={{
                                    pathname: location.pathname,
                                    search: createSearchParams({
                                        ...Object.fromEntries(new URLSearchParams(location.search)),
                                        module_id: pipeline_id
                                    }).toString()
    
                                }} state={{ isTogglingPanel: true }}>
                                    {pipeline_id}
                                </Link>
                            )}
                        </ClippedContent>
                    </Grid.Column>
                    {!unlinked && (
                        <Grid.Column width={1}>
                            <Icon style={{ marginLeft: "-2em" }} link className='fas fa-copy' color="blue" onClick={StringUtils.copy(pipeline_id)} />
                        </Grid.Column>
                    )}
                </Grid>
            )}
            content='Copied!'
            on='click'
            closeOnTriggerMouseLeave
            inverted
        />
    );
}

export default ModuleLink;
