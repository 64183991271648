import React from 'react';
import { Modal, STATUS_TYPES, Notification } from 'dyl-components';
import { connect } from 'react-redux';
import AddPhoneExtensionForm from 'shared/forms/AddPhoneExtensionForm';
import pbxConfigActions from 'actions/pbx_config';


class AddExtensionModal extends React.Component {
    state = {
        callerid: "",
        create_voicemail: true,
        extension_number: "",
        user_id: 0,
        assigned_devices: [],
        hasDevices: false
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onChangeAssignedDevices = (_, { name, value }) => {
        if (this.state.hasDevices) {
            if (value.find(value => value === -1)) {
                this.setState({
                    hasDevices: false,
                    [name]: []
                })
            } else {
                this.setState({
                    [name]: value
                })
            }
        } else {
            this.setState({
                hasDevices: true,
                [name]: [value]
            })
        }
    }

    onAddExtension = () => {
        this.props.onAddExtension(this.props.domain, { ...this.state }).then(() => {
            this.setState({
                callerid: "",
                create_voicemail: true,
                extension_number: "",
                user_id: 0,
                assigned_devices: [],
                hasDevices: false
            });
            this.props.onClose();
            this.props.onReadExtensions(this.props.domain);
            Notification.alert('Successfully added extension!', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to add extension', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <Modal open={this.props.open} onClose={this.props.onClose}>
                <Modal.Header>
                    Add Phone Extension
                </Modal.Header>
                <Modal.Content>
                    <AddPhoneExtensionForm
                        {...this.state}
                        onChangeAssignedDevices={this.onChangeAssignedDevices}
                        onChange={this.onChange}
                    />
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={this.onAddExtension}
                    saveOptions={{loading: this.props.isCreatingExtensions}}
                />
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    isCreatingExtensions: state.pbx_config.isCreatingExtensions
})

const mapDispatchToProps = dispatch => ({
    onAddExtension: (domain, extensions) => {
        return dispatch(pbxConfigActions.createExtensions([extensions], null, domain));
    },
    onReadExtensions: (domain) => {
        dispatch(pbxConfigActions.getExtensions(domain));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddExtensionModal);
