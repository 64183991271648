import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';

const AddDeviceForm = ({
    phone_name,
    onChange,
    extension_id,
    mac,
    screen_name,
    location_id,
    timezone,
    model,
    extensions,
    locations
}) => (
        <Form>
            <Form.Input
                label='Name'
                name='phone_name'
                value={phone_name}
                onChange={onChange}
            />
            <Form.Select
                label='Extension'
                name='extension_id'
                value={extension_id}
                onChange={onChange}
                options={extensions}
            />
            <Form.Input
                label='Mac'
                name='mac'
                value={mac}
                onChange={onChange}
            />
            <Form.Input
                label='Screen Name'
                name='screen_name'
                value={screen_name}
                onChange={onChange}
            />
            <Form.Select
                label='E911 Location'
                name='location_id'
                value={location_id}
                onChange={onChange}
                options={locations}
            />
            <Form.Select
                label='Timezone'
                name='timezone'
                value={timezone}
                onChange={onChange}
                options={DateTimeUtils.generateTimezoneOptions()}
            />
            <Form.Input
                label='Model'
                name='model'
                value={model}
                onChange={onChange}
            />
        </Form>
    );

const mapStateToProps = state => ({
    extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    })),
    locations: state.provision.locations.map(({ id, label }) => ({
        key: id,
        value: id,
        text: label
    })),
})

export default connect(mapStateToProps)(AddDeviceForm);
