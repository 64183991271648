import React from 'react';
import { Button, Grid } from 'semantic-ui-react';

const SoftphonesHeader = ({ onOpenAddSoftphoneSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Manage Softphones
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            <Button onClick={onOpenAddSoftphoneSection} size='tiny' basic inverted color='white'>Add Softphone</Button>
        </Grid.Column>
    </Grid>
)

export default SoftphonesHeader;
