import React from 'react';
import { Modal, STATUS_TYPES, Notification } from 'dyl-components';
import AddDeviceForm from 'shared/forms/AddDeviceForm';
import provisionActions from 'actions/provision';
import { connect } from 'react-redux';


class AddDeviceModal extends React.Component {
    state = {
        extension_id: null,
        mac: "",
        phone_name: "",
        screen_name: "",
        timezone: "Dateline Standard Time"
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddDevice = () => {
        const { extension_id, ...otherState } = this.state;
        const { id, extension_number } = this.props.extensions.find(({ id }) => id === extension_id);
        this.props.onAddDevice(this.props.domain, { extension: { id, number: extension_number }, ...otherState }).then(() => {
            this.setState({
                extension: null,
                location_id: null,
                mac: "",
                phone_name: "",
                screen_name: "",
                timezone: "Dateline Standard Time"
            });
            this.props.onClose();
            this.props.onReadDevices(this.props.domain);
            Notification.alert('Successfully added device!', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to add device', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <Modal open={this.props.open} onClose={this.props.onClose}>
                <Modal.Header>
                    Add Device
                </Modal.Header>
                <Modal.Content>
                    <AddDeviceForm
                        {...this.state}
                        onChange={this.onChange}
                    />
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={this.onAddDevice}
                    saveOptions={{loading: this.props.isCreatingDevices}}
                />
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    domain: state.provision.domain,
    extensions: state.pbx_config.extensions,
    isCreatingDevices: state.provision.isCreatingDevices
})

const mapDispatchToProps = dispatch => ({
    onAddDevice: (domain, device) => {
        return dispatch(provisionActions.createDevices(device, null, domain));
    },
    onReadDevices: (domain) => {
        dispatch(provisionActions.getDevices(domain));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);
