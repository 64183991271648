const initialState = {
    cid_selects: [],
    isReadingCidSelects: false,
    cidSelectBeingDeleted: null,
    cidSelectBeingUpdated: null,
    isCreatingCidSelects: false,
    count: 0
}

export default initialState;
