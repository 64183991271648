import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GroupedTimeLine, DateTimeUtils, CollapsibleList } from 'dyl-components';

import moduleInformationActions from 'actions/module_information';

import HistoryTableHeader from './HistoryTableHeader';
import HistoryColumns from './HistoryColumns';

import {
    getDataNonQuery,
    onToggleTimeUnitNonQuery,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import { StringUtils } from 'utils';
import Avatar from 'react-avatar';
import { Icon } from 'semantic-ui-react';

const HistorySection = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const module_id = params.get('module_id');

    const [dateRange, setDateRange] = useState('-')
    const [sortDirection, setSortDirection] = useState(false);

    useEffect(() => {
        const [start, end] = dateRange.split('-');
        dispatch(moduleInformationActions.readHistory(module_id, {
            ...start === '' || { start_time: start },
            ...end === '' || { end_time: end },
            order: sortDirection ? 'asc' : 'desc'
        }));
    }, [dispatch, module_id, dateRange, sortDirection]);

    const onToggleTimeUnit = (timestamp, setTimestamp, setTimeUnitOptionsOpen) => {
        const timestampRange = onToggleTimeUnitNonQuery(timestamp, setTimestamp);

        if (timestamp !== 'Custom Range') {
            setDateRange(`${timestampRange?.start !== undefined ? timestampRange.start : ''}-${timestampRange?.end !== undefined ? timestampRange.end : ''}`);
            setTimeUnitOptionsOpen(false);
        }
    }

    const getData = (setCustomDateRangeOpen, dateRange) => {
        const timestampRange = getDataNonQuery(setCustomDateRangeOpen, dateRange);
        setDateRange(`${timestampRange?.start !== undefined ? timestampRange.start : ''}-${timestampRange?.end !== undefined ? timestampRange.end : ''}`);
    }

    const onToggleSort = () => {
        setSortDirection(!sortDirection);
    }

    const isSequenceEntry = (entry) => {
        const { activity_type, status } = entry;
        return activity_type === "sequence" && (status === 'applied' || status === 'changed' || status === 'removed')
    }

    const getIcon = (entry) => {
        if (isSequenceEntry(entry)) {
            return 'fas fa-sitemap'
        }
        switch (entry.status) {
            case 'created': return 'fas fa-square-user';
            case 'stage changed':
            case 'changed':
                return 'fas fa-funnel-dollar';
            default: return 'fas fa-clipboard';
        }
    }

    const { pipelineHistory, isReadingPipelineHistory } = useSelector(state => ({
        pipelineHistory: DateTimeUtils.groupDates((state.module_information.pipelineHistory?.data || [])
            .map(item => ({
                ts: item.activity,
                icon: <Icon className={getIcon(item)} />,
                columns: <HistoryColumns
                    activityHeader={StringUtils.capitalize(item.activity_type)}
                    activitySubheader={StringUtils.capitalize(item.status)}
                    messageNotes={(
                        <div>
                            {item.message}
                            {((item.status === "changed" && item.activity_type === "pipeline") 
                            || (item.status === "closed" && (item.activity_type === "opportunity" || item.activity_type ==="lead"))
                            ) && (
                                <CollapsibleList
                                    minimumNumberOfItemsToShow={0}
                                    items={[
                                        '',
                                        '',
                                        `${item.close_reason ? `${item.close_reason}${item.close_type ? `/${item.close_type}` : ""}` : ""}`,
                                        '',
                                        item.note || ""
                                    ]}
                                    action="View"
                                />
                            )}
                        </div>
                    )}
                    user={!isSequenceEntry(item) ? (
                        item.user && <Avatar
                            name={item.user}
                            round
                            size='3em'
                            maxInitials={2} />
                    ) : (
                        <Icon
                            className='fas fa-robot AutomationIcon'
                            circular
                            size='large'
                        />
                    )}
                    contactName={`${item.contact.first_name} ${item.contact.last_name}`}
                />
            })),
            sortDirection
        ),
        isReadingPipelineHistory: state.module_information.isReadingPipelineHistory
    }));

    return (
        <div className='HistorySection'>
            <GroupedTimeLine
                headers={<HistoryTableHeader />}
                isLoading={isReadingPipelineHistory}
                additionalColumnNumber={4}
                groups={pipelineHistory}
                useRangeCalendar={useRangeCalendar(null, false)}
                onToggleTimeUnit={onToggleTimeUnit}
                getTimeData={getData}
                sortDirection={sortDirection}
                onToggleSort={onToggleSort}
            />
        </div>
    )
}

export default HistorySection;
