import { combineReducers } from 'redux';
import auth from './auth/auth';
import app from './app/app';
import leads from './leads';
import call from './call';
import teams from './teams';
import officeView from './office_view';
import history from './history';
import notes from './notes';
import note from './note';
import hotlist from './hotlist';
import groups from './groups';
import users from './users';
import smsList from './sms_list';
import customers from './customers';
import leadConnect from './lead_connect';
import user from './user';
import tags from './tags';
import tag from './tag';
import tag_categories from './tag_categories';
import tag_category from './tag_category';
import callRecordings from './call_recordings'
import schedule from './schedule';
import chatRooms from './chat_rooms';
import calendar from './calendar';
import tasks from './tasks';
import task_types from './task_types';
import task from './task';
import team from './team';
import lead_types from './lead_types';
import sms_history from './sms_history';
import lead_sources from './lead_sources';
import favorites from './favorites';
import roles from './roles';
import role from './role';
import campaigns from './campaigns';
import text from './text';
import image from './image';
import contacts from './contacts';
import upload from './upload';
import custom_fields_groups from './custom_fields_groups';
import custom_fields_group from './custom_fields_group';
import custom_fields from './custom_fields';
import custom_field from './custom_field';
import account from './account';
import permissions from './permissions';
import contact from './contact';
import recents from './recents';
import notifications from './notifications';
import events from './events';
import event from './event';
import contact_autocomplete from './contact_autocomplete';
import field_autocomplete from './field_autocomplete';
import account_autocomplete from './account_autocomplete';
import territory from './territory';
import pipeline from './pipeline';
import opportunities from './opportunities';
import text_template from './text_template';
import text_templates from './text_templates';
import email_template from './email_template';
import email_templates from './email_templates';
import relationships from './relationships';
import text_ui from './text_ui';
import email from './email';
import oauth_integrations from './oauth_integrations'
import message_editor from './message_editor';
import pbx_config from './pbx_config';
import provision from './provision';
import email_ui from './email_ui';
import email_form from './email_form';
import lead_ingestion from './lead_ingestion';
import assign from './assign';
import call_history from './call_history';
import phone_number from './phone_number';
import ring_groups from './ring_groups';
import ring_group from './ring_group';
import call_queues from './call_queues';
import call_queue from './call_queue';
import cid_select from './cid_select';
import product_category from './product_category';
import manufacturer from './manufacturer';
import product_quote from './product_quote';
import product_plan from './product_plan';
import payment from './payment';
import user_dashboard from './user_dashboard';
import team_dashboard from './team_dashboard';
import products_ui from './products_ui';
import product_interests from './product_interests';
import self_report from './self_report';
import left_sidebar_ui from './left_sidebar_ui';
import company from './company';
import template_category from './template_category';
import email_attachment from './email_attachment';
import event_attendee from './event_attendee';
import event_attachment from './event_attachment';
import task_attachment from './task_attachment';
import text_attachment from './text_attachment';
import webforms from './webforms';
import lead_provider from './lead_provider';
import data_record from './data_record';
import master_account from './master_account';
import import_data from './import_data';
import contact_phone from './contact_phone';
import contact_email from './contact_email';
import contact_socials from './contact_socials';
import contact_location from './contact_location';
import contact_duplicates from './contact_duplicates';
import send_email from './send_email';
import leads_tab from './leads_tab';
import opportunity_tab from './opportunity_tab';
import module_information from './module_information';
import campaign_category from './campaign_category';
import campaign_history from './campaign_history';
import campaign_contacts from './campaign_contacts';
import assignment from './assignment';
import automation from './automation';
import contact_campaigns from './contact_campaigns';
import customer_pipeline from './customer_pipeline';
import sequences from './sequences';
import sequence_tasks from './sequence_tasks';
import contact_sequence from './contact_sequence';
import campaign_users from './campaign_users';
import products from './products';
import product_addons  from './product_addons';
import product_fees from './product_fees';
import record_assignment from './record_assignment';
import supply_chain_network from './supply_chain_network';
import product_catalog from './product_catalog';
import quote from './quote';
import customer_tab from './customer_tab';
import order from './order';
import invoice from './invoice';
import quotes_and_orders from './quotes_and_orders';

const appReducers = combineReducers({
    auth,
    app,
    leads,
    call,
    teams,
    officeView,
    hotlist,
    history,
    notes,
    note,
    groups,
    users,
    smsList,
    customers,
    leadConnect,
    user,
    tags,
    tag,
    tag_categories,
    tag_category,
    callRecordings,
    schedule,
    chatRooms,
    calendar,
    tasks,
    task,
    team,
    task_types,
    lead_types,
    lead_sources,
    sms_history,
    favorites,
    roles,
    role,
    campaigns,
    text,
    image,
    contacts,
    upload,
    custom_fields_groups,
    custom_fields_group,
    custom_fields,
    custom_field,
    account,
    permissions,
    contact,
    recents,
    notifications,
    events,
    event,
    contact_autocomplete,
    field_autocomplete,
    account_autocomplete,
    territory,
    pipeline,
    opportunities,
    text_template,
    text_templates,
    email_template,
    email_templates,
    relationships,
    text_ui,
    email,
    oauth_integrations,
    message_editor,
    pbx_config,
    provision,
    email_ui,
    email_form,
    lead_ingestion,
    assign,
    call_history,
    phone_number,
    ring_groups,
    ring_group,
    call_queues,
    call_queue,
    cid_select,
    product_category,
    manufacturer,
    product_quote,
    product_plan,
    payment,
    user_dashboard,
    team_dashboard,
    products_ui,
    product_interests,
    self_report,
    left_sidebar_ui,
    company,
    template_category,
    email_attachment,
    event_attendee,
    event_attachment,
    task_attachment,
    text_attachment,
    webforms,
    lead_provider,
    data_record,
    master_account,
    import_data,
    contact_phone,
    contact_email,
    contact_socials,
    contact_location,
    contact_duplicates,
    send_email,
    leads_tab,
    opportunity_tab,
    module_information,
    campaign_category,
    campaign_history,
    campaign_contacts,
    assignment,
    contact_campaigns,
    customer_pipeline,
    sequences,
    sequence_tasks,
    contact_sequence,
    campaign_users,
    automation,
    products,
    product_addons,
    product_fees,
    record_assignment,
    supply_chain_network,
    product_catalog,
    quote,
    customer_tab,
    order,
    invoice,
    quotes_and_orders
});

export default appReducers;
