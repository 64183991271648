import React from 'react';
import { Modal } from 'dyl-components';
import { Select, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import usersActions from 'actions/users';
import UsersForm from 'shared/forms/UsersForm';

const EditExtensionModal = ({
    extension,
    onChange,

    voicemail_boxes,
    users,

    open,
    onClose,
    onUpdate,
    isUpdating,
    isReadingUsers
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Edit Extension
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Input 
                    name='extension_number'
                    value={extension.extension_number}
                    onChange={onChange}
                    label='Extension Number'
                />
                <Form.Field 
                    control={UsersForm}
                    label='User'
                    user={extension.user_id}
                    user_id={extension.user_id}
                    onChange={onChange}
                    name='user_id'
                />
                <Form.Select 
                    control={Select}
                    options={voicemail_boxes}
                    value={extension.voicemail_box_id}
                    onChange={onChange}
                    name='voicemail_box_id'
                    label='Voicemail Box'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpdate}
            saveOptions={{loading: isUpdating}}
        />
    </Modal>
)

class EditExtensionModalContainer extends React.Component {
    render() {
        return (
            <EditExtensionModal 
                extension={this.props.extension}
                isUpdating={this.props.extensionBeingUpdated === this.props.extension.id}
                onChange={this.props.onChange}
                onClose={this.props.onClose}
                onUpdate={this.props.onUpdate}
                open={this.props.open}
                users={this.props.users}
                voicemail_boxes={this.props.voicemail_boxes}
                isReadingUsers={this.props.isReadingUsers}
            />
        )
    }
}

const mapStateToProps = (state, { extension }) => {
    return ({
        users: state.users.userOptions.map(({ user_id, first_name, last_name }) => ({
            key: user_id,
            value: user_id,
            text: `${first_name} ${last_name}`
        })),
        voicemail_boxes: state.pbx_config.voicemail_boxes.filter(({ extension_number }) => 
            extension_number.startsWith("3") || extension.extension_number === extension_number).map(({ id, label }) => ({
            key: id,
            value: id,
            text: label
        })),
        extensionBeingUpdated: state.pbx_config.extensionBeingUpdated,
        isReadingUsers: state.users.isReadingUsers
    });
}

const mapDispatchToProps = dispatch => ({
    onReadUsers: (user_id) => {
        dispatch(usersActions.readUserOptions({ user_id }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditExtensionModalContainer);
