import ACTION_NAMES from '../ACTION_NAMES';
import routes from '../routes';
import { generateCreateActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from '../crudActionCreators';

export default {
    generateFormToken: generateCreateActionCreator(routes.API_AUTHORIZENET_FORM_TOKEN, ACTION_NAMES.PAYMENT_FORM_TOKEN),
    createCustomerProfile: generateCreateActionCreator(routes.API_AUTHORIZENET_CUSTOMER_PROFILE_CREATION, ACTION_NAMES.PAYMENT_CREATE_CUSTOMER_PROFILE),
    getProfiles: generateReadOneActionCreator(routes.API_PAYMENT_PROFILE, ACTION_NAMES.PAYMENT_PROFILE),
    saveProfile: generateCreateActionCreator(routes.API_PAYMENT_PROFILE, ACTION_NAMES.PAYMENT_PROFILE),
    fundProfile: generateCreateActionCreator(routes.API_FUND_PAYMENT_PROFILE, ACTION_NAMES.FUND_PAYMENT_PROFILE),
    deactivateProfile: generateUpdateActionCreator(routes.API_DEACTIVATE_PAYMENT_PROFILE, ACTION_NAMES.DEACTIVATE_PAYMENT_PROFILE)
}
