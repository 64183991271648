import provisionActions from 'actions/provision';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

const AddSoftphoneSection = ({ name, extension_id, location_id, locations, extensions, onChange, onAddSoftphone, onClose, isCreatingSoftphones }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Input
                label='Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <Form.Select
                label='Extension'
                name='extension_id'
                value={extension_id}
                onChange={onChange}
                options={extensions}
            />
            <Form.Select
                label='E911 Location'
                name='location_id'
                value={location_id}
                onChange={onChange}
                options={locations}
            />
        </Form.Group>
        <Form.Group>
            {!isCreatingSoftphones && (
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
            )}
            <Form.Button
                loading={isCreatingSoftphones}
                primary
                floated='right'
                content={'Add'}
                onClick={onAddSoftphone}
            />
        </Form.Group>
    </Form>
)

class AddSoftphoneSectionContainer extends React.Component {
    state = {
        name: '',
        extension_id: 0,
        location_id: 0
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddSoftphone = () => {
        const { extension_id, ...otherState } = this.state;
        const { text } = this.props.extensions.find(({ value }) => value === extension_id);

        this.props.onCreateSoftphone(this.props.domain, { extension: { id: extension_id, number: text }, ...otherState }).then(() => {
            this.props.onReadSoftphones(this.props.domain);
            this.setState({
                name: '',
                extension_id: 0
            });
            this.props.onClose();
        })
    }

    onClose = () => {
        this.setState({
            name: '',
            extension_id: 0
        }, () => {
            this.props.onClose();
        })
    }

    render() {
        return (
            <AddSoftphoneSection
                extension_id={this.state.extension_id}
                location_id={this.state.location_id}
                name={this.state.name}
                onAddSoftphone={this.onAddSoftphone}
                onChange={this.onChange}
                onClose={this.onClose}
                isCreatingSoftphones={this.props.isCreatingSoftphones}
                extensions={this.props.extensions}
                locations={this.props.locations}
            />
        )
    }
}

const mapStateToProps = state => {

    const assignedExtensions = state.provision.softphones.map(({ extension }) => extension.id);
    const extensions = state.pbx_config.extensions.filter(({ id }) => !assignedExtensions.includes(id)).map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    }))

    return ({
        domain: state.provision.domain,
        isCreatingSoftphones: state.provision.isCreatingSoftphones,
        locations: state.provision.locations.map(({ id, label }) => ({
            key: id,
            value: id,
            text: label
        })),
        extensions
    })
}

const mapDispatchToProps = dispatch => ({
    onReadSoftphones: (domain) => {
        dispatch(provisionActions.getSoftphones(domain));
    },
    onCreateSoftphone: (domain, softphone) => {
        return dispatch(provisionActions.createSoftphones(softphone, null, domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSoftphoneSectionContainer);
