import { Modal, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import './DevicesModal.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import provisionActions from "actions/provision";

const DEVICE_MODAL_OPTIONS = PhoneUtil.DEVICE_TYPES_OPTIONS.filter((option) => option.key !== "browser");

const Content = ({ onClose, onReload, isEditing }) => {
    const dispatch = useDispatch();
    const [extensionOptions, setExtensionOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const { extensionsList, isReadingExtensionsList } = useSelector((state) => state.pbx_config);
    const { locations, isReadingLocations, softphone } = useSelector((state) => state.provision);

    const isLoadingData = isEditing && !!softphone 

    const { control, formState: { isValid, isDirty }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: "softphone",
            extension: isLoadingData ? softphone.extension : null,
            name: isLoadingData ? softphone.name : "",
            location_id: isLoadingData ? softphone.location?.id : null
        },
        resolver: generateResolver({
            type: yup.string().required("This field is required"),
            extension: yup.string().required("This field is required"),
            name: VALIDATORS.TEAM_NAME().required("This field is required"),
            location_id: yup.number().required("This field is required")
        })
    });

    const onSave = async (data) => {
        try {
            await dispatch(provisionActions.createSoftphone(data));
            Notification.alert('Device Created Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create device', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            await dispatch(provisionActions.updateSoftphone(softphone.id, data));
            Notification.alert('Device updated Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update device', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (extensionsList) {
            const formattedOptions = extensionsList.map((extension) => ({
                key: extension.id,
                value: extension.extension_number,
                text: `${!!extension.user ? `${extension.user.first_name} ${extension.user.last_name} (Ext. ${extension.extension_number})` : `Ext. ${extension.extension_number}`}`
            }));
            setExtensionOptions(formattedOptions);
        }
    }, [extensionsList])

    useEffect(() => {
        if (locations) {
            const formattedOptions = locations.map((location) => ({
                key: location.id,
                value: location.id,
                text: `${location.label}: ${location.address}`
            }));
            setLocationOptions(formattedOptions);
        }
    }, [locations])

    useEffect(() => {
        dispatch(pbxConfigActions.getListOfExtensions());
        dispatch(provisionActions.getLocations());
    }, [dispatch])

    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit" : "Add"} Device
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={false}>
                    <div className="DevicesModal__form">
                        <div className="DevicesModal__controllerContainer">
                            <Controller
                                name='type'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        placeholder='Select type'
                                        options={DEVICE_MODAL_OPTIONS}
                                        label="Type"
                                        required
                                        error={error?.message}
                                        disabled={isEditing}
                                    />
                                )}
                            />
                            <Controller
                                name='extension'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        placeholder='Select user/extension'
                                        options={extensionOptions}
                                        label="User/Extension"
                                        required
                                        loading={isReadingExtensionsList}
                                        error={error?.message}
                                        disabled={isEditing}
                                    />
                                )}
                            />
                        </div>
                        <div className="DevicesModal__controllerContainer">
                            <Controller
                                name='name'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Label"
                                        placeholder="Type label"
                                        required
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='location_id'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        placeholder='Select E911 location'
                                        options={locationOptions}
                                        label="E911 Location"
                                        required
                                        loading={isReadingLocations}
                                        error={error?.message}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onSave)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: false }}
            />  
        </>
    )
}

const DevicesModal = ({ open, onClose, onReload, isEditing }) => {
    return (
        <Modal open={open} onClose={onClose} size={"small"}>
            <Content onClose={onClose} onReload={onReload} isEditing={isEditing} />
        </Modal>
    )
}

export default DevicesModal;
