import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const AdditionalOptionsHeader = ({ isEditMode, onEnableEditMode, onCloseEditMode, onUpdate }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Additional Options
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            {!isEditMode ? <Button onClick={onEnableEditMode} size='tiny' basic inverted color='white'>Edit</Button> :
            <React.Fragment>
                <Button size='tiny' inverted color='white' basic onClick={onCloseEditMode}>Cancel</Button>
                <Button size='tiny' inverted color='white' basic onClick={onUpdate}>Update</Button>
            </React.Fragment>
        }
        </Grid.Column>
    </Grid>
)

export default AdditionalOptionsHeader;
