import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function pbxConfigReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecordings: true, recordings: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingRecordings: false, recordings: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecordings: false, recordings: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDylNumbers: true, dylNumbers: [], dylNumbersCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDylNumbers: false, dylNumbers: action.data.data, dylNumbersCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDylNumbers: false, dylNumbers: [], dylNumbersCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDestinations: true, destinations: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDestinations: false, destinations: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDestinations: false, destinations: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDestinationGroups: true, destinationGroups: [], destinationGroupsCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDestinationGroups: false, destinationGroups: action.data.data, destinationGroupsCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDestinationGroups: false, destinationGroups: [], destinationGroupsCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCallRoutingRules: true, callRoutingRules: [], callRoutingRulesCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCallRoutingRules: false, callRoutingRules: action.data.data, callRoutingRulesCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCallRoutingRules: false, callRoutingRules: [], callRoutingRulesCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingDestinationGroup: true, destinationGroup: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingDestinationGroup: false, destinationGroup: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingDestinationGroup: false, destinationGroup: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSavingDestinationGroups: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSavingDestinationGroups: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSavingDestinationGroups: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isEditingDestinationGroup: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isEditingDestinationGroup: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isEditingDestinationGroup: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingDestinationGroup: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingDestinationGroup: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingDestinationGroup: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingDylNumber: true, dylNumber: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingDylNumber: false, dylNumber: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingDylNumber: false, dylNumber: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCompanyExtensions: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCompanyExtensions: false, companyExtensions: action.data }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCompanyExtensions: false, companyExtensions: {} }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingExtensionsList: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingExtensionsList: false, extensionsList: action.data?.Data, count: action.data?.count }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingExtensionsList: false, extensionsList: [], count:0 }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUnusedExtensions: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUnusedExtensions: false, unusedExtensions: action.data }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUnusedExtensions: false, unusedExtensions: [] }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingRecordings: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingRecordings: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingRecordings: false  };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPreferences: true, preferences: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPreferences: false, preferences: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPreferences: false, preferences: null };
            
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPreferences: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPreferences: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingPreferences: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingDylNumber: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingDylNumber: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingDylNumber: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCallRoutingRules: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCallRoutingRules: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCallRoutingRules: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCallRoutingRule: true, callRoutingRule: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCallRoutingRule: false, callRoutingRule: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCallRoutingRule: false, callRoutingRule: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanyVoicemail: true, companyVoicemail: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanyVoicemail: false, companyVoicemail: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanyVoicemail: false, companyVoicemail: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingConferenceRoom: true, conferenceRoom: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingConferenceRoom: false, conferenceRoom: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingConferenceRoom: false, conferenceRoom: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingParkingLot: true, parkingLot: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingParkingLot: false, parkingLot: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingParkingLot: false, parkingLot: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCallRoutingRule: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCallRoutingRule: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCallRoutingRule: false };

        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingListPhones: true, pbxListPhones: [] };
        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingListPhones: false, pbxListPhones: action.data };
        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingListPhones: false, pbxListPhones: [] };
        
        default:
            return state;
    }
}


export default pbxConfigReducer;
