import React from 'react';
import { Table, EllipsisDropdown, ControlledPopup } from 'dyl-components';
import { Dropdown, Icon, Header } from 'semantic-ui-react';
import { useState } from 'react';

import PipelineStagesTable from './PipelineStagesTable'
import PipelineModal from '../PipelineModal';

const PipelinesTableRow = ({
    pipeline,
    disabledDeleteBtn,
    disabledDefaultBtn,
    onDelete,
    onMakeDefault,
    onDeleteStage,
    stage,
    refresh,
    isDuplicated,
}) => {
    const [isEditPipelineModalOpen, setEditPipelineModalOpen] = useState(false);

    const onOpenEditPipeline = () => {
        setEditPipelineModalOpen(true);
    }

    const onCloseEditPipeline = () => {
        setEditPipelineModalOpen(false);
    }

    const ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Make default',
            icon: 'fa-solid fa-square-check',
            onClick: () => { onMakeDefault(pipeline) },
            disabled: disabledDefaultBtn
        },
        {
            id: 2,
            text: 'Edit',
            icon: 'fas fa-edit',
            onClick: () => { onOpenEditPipeline() }
        },
        {
            id: 3,
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            onClick: () => { onDelete(pipeline.id) },
            disabled: disabledDeleteBtn
        }
    ];

    const [collapsed, setCollapsed] = useState(false);

    const onToggle = () => {
        setCollapsed(!collapsed);
    }


    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }

    return (
        <Table.CollapsibleRow
            className={'PipelineTable__CollapsibleRow'}
            onToggle={onToggle}
            collapsed={collapsed}
            subrowContent={(
                <Table.CollapsibleRowContent>
                    <Table.Cell colSpan={5} className={'PipelineTable__TableCell'}>
                        <PipelineStagesTable stage={stage} refresh={refresh} pipelineStages={pipeline.stages} pipeline_id={pipeline.id} onDeleteStage={onDeleteStage} />
                    </Table.Cell>
                </Table.CollapsibleRowContent>
            )}>
            <Table.Cell width={4}>
                <div className={'PipelineTable__NameContainer'}>
                    <p className='PipelineTable__Name'>{pipeline.name}</p>
                    {pipeline.default && (
                        <em basic color='blue' className='PipelineTable__DefaultPipeline'>
                            Default
                        </em>
                    )}
                </div>
            </Table.Cell>
            <Table.Cell width={4}>
                {pipeline.description}
            </Table.Cell>
            <Table.Cell width={4}>
                {pipeline.days}
            </Table.Cell>
            <Table.Cell width={3}>
                <EllipsisDropdown>
                    {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, onClick, disabled, ...otherProps }, idx) => {
                        if (idx === 2) {
                            return (
                                <ControlledPopup 
                                    trigger={<Dropdown.Item icon={icon} text={text} disabled={disabled} />}
                                    content={text}
                                    onConfirm={onClick}
                                    inverted
                                />
                            )
                        }
                        return (
                            <DropdownItem
                                key={idx}
                                text={text}
                                icon={icon}
                                displaysPopup={displaysPopup}
                                popupProps={popupProps}
                                onClick={onClick}
                                disabled={disabled}
                                {...otherProps}
                            />
                        );
                    })}
                </EllipsisDropdown>
                <PipelineModal
                    open={isEditPipelineModalOpen}
                    onClose={onCloseEditPipeline}
                    name={pipeline.name}
                    description={pipeline.description}
                    stages={pipeline.stages}
                    pipeline_id={pipeline.id}
                    stage={stage}
                    refresh={refresh}
                    isDuplicated={isDuplicated}
                />
            </Table.Cell>
        </Table.CollapsibleRow>
    )
}

export default PipelinesTableRow;
