import quoteActions from "actions/quote";
import {
    ButtonLink,
    Editable,
    Notification,
    STATUS_TYPES,
    generateResolver,
    yup,
} from "dyl-components";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Popup, Icon, Form } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import QuoteStatusField from "shared/forms/QuoteStatusField";
import { StringUtils } from "utils";

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}

const QuoteStatus = ({ quote_status, quote_id, pipeline_id }) => {
    const [isEditingStatus, setIsEditingStatus] = useState(false);
    const {
        handleSubmit,
        formState: { isValid, isDirty },
        control,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            quote_status,
        },
        resolver: generateResolver({
            quote_status: yup.string().required("This field is required"),
        }),
    });

    const onEdit = () => {
        setIsEditingStatus(true);
    };

    const onCancelEdit = () => {
        reset({
            quote_status,
        });
        setIsEditingStatus(false);
    };

    const dispatch = useDispatch();

    const onConfirmEdit = async (data) => {
        try {
            await dispatch(
                quoteActions.updateQuoteStatus(quote_id, {
                    status: data.quote_status,
                })
            );
            Notification.alert(
                "Successfully updated quote status",
                STATUS_TYPES.SUCCESS
            );
            dispatch(quoteActions.getOpportunityRecentQuote(pipeline_id));
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update quote status",
                STATUS_TYPES.ERROR
            );
        }
    };

    const isUpdating = useSelector((state) =>
        Boolean(state.quote.quoteBeingUpdated)
    );

    const status = (() => {
        const [parent, child] = quote_status.split("/");
        if (!(parent === "rejected" && Boolean(child))) {
            return StringUtils.capitalize(parent);
        }
        return `${StringUtils.capitalize(parent)}/${StringUtils.capitalize(
            child.split("-").join(" ")
        )}`;
    })();

    return !isEditingStatus ? (
        <div onClick={onEdit}>{status}</div>
    ) : (
        <Form loading={isUpdating} size="tiny">
            <Editable
                value={StringUtils.capitalize(quote_status || "None")}
                isEditing={isEditingStatus}
                onCancelEdit={() => {
                    onCancelEdit();
                }}
                onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                    console.log(errors, e);
                })}
                submitDisabled={!isValid || !isDirty}
                onEdit={() => {
                    onEdit();
                }}
                disabled={isUpdating}
            >
                <Controller
                    control={control}
                    name="quote_status"
                    render={({ field: { name, value, onChange } }) => (
                        <QuoteStatusField
                            required
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({
                                    target: { name, value },
                                });
                            }}
                        />
                    )}
                />
            </Editable>
        </Form>
    );
};

const OpportunityRecentQuote = ({ textAlign = "right", pipeline_id }) => {
    const { onEditQuote, onViewProposal } = useContext(QuoteBuilderContext);
    const isReading = useSelector((state) => state.quote.recentQuoteBeingRead);
    const recentQuote = useSelector((state) => {
        const recentQuote = state.quote.recentQuote?.quote_version;
        return recentQuote
            ? {
                  quote_id: recentQuote.id,
                  display_id: (
                      <span>
                          ID:{" "}
                          <ButtonLink
                              noPadding
                              onClick={() => {
                                  onEditQuote(recentQuote.id);
                              }}
                          >
                              {recentQuote.id}
                          </ButtonLink>{" "}
                          <Popup
                              trigger={
                                  <Icon
                                      link
                                      className="fas fa-copy"
                                      onClick={copy(recentQuote.id)}
                                      color="primary"
                                  />
                              }
                              content="Copied!"
                              on="click"
                              closeOnTriggerMouseLeave
                              position="bottom center"
                              inverted
                          />
                          {Boolean(
                              state.quote.recentQuote?.last_pdf_version_id
                          ) && (
                              <Icon
                                  onClick={() => {
                                      onViewProposal(
                                          state.quote.recentQuote
                                              ?.last_pdf_version_id
                                      );
                                  }}
                                  className="fas fa-file-pdf"
                                  link
                                  color="primary"
                              />
                          )}
                      </span>
                  ),
                  one_time_amount: `$${StringUtils.formatDecimal(
                      `${recentQuote.one_time_amount || 0}`,
                      true
                  )}`,
                  recurring_amount: `$${StringUtils.formatDecimal(
                      `${recentQuote.recurring_amount || 0}`,
                      true
                  )}`,
                  subtotal: `$${StringUtils.formatDecimal(
                      `${recentQuote.subtotal || 0}`,
                      true
                  )}`,
                  quote_status: recentQuote.quote_status,
                  created: recentQuote.created.split("T")[0],
                  sent_date: recentQuote.sent_date,
                  expires: recentQuote.expires,
              }
            : {
                  display_id: "None",
                  one_time_amount: "None",
                  recurring_amount: "None",
                  subtotal: "None",
                  quote_status: "None",
                  created: "None",
                  sent_date: "None",
                  expires: "None",
              };
    });

    return (
        <React.Fragment>
            <Grid className="RecordInfo">
                <Grid.Row columns="equal">
                    <Grid.Column>Recent Quote</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.display_id}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>One-Time Amount</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.one_time_amount}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Reccuring Amount</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.recurring_amount}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Subtotal</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.subtotal}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Quote Status</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {!Boolean(isReading) ? (
                            <QuoteStatus
                                quote_status={recentQuote.quote_status}
                                quote_id={recentQuote.quote_id}
                                pipeline_id={pipeline_id}
                            />
                        ) : (
                            <Icon className="fas fa-circle-notch" loading />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Quote Date</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.created === "0001-01-01"
                            ? "None"
                            : recentQuote.created}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Proposal Sent</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.sent_date === "0001-01-01"
                            ? "None"
                            : recentQuote.sent_date}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>Expires On</Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {recentQuote.expires === "0001-01-01"
                            ? "None"
                            : recentQuote.expires}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};

export default OpportunityRecentQuote;
