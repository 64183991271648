import React from 'react';
import { Table, ControlledPopup, MessagingIcon, STATUS_TYPES, Notification, TableLoader } from 'dyl-components';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import provisionActions from 'actions/provision';
import { DateTimeUtils } from 'dyl-components';

const DeviceRow = ({
    device,
    onDelete,
    onEnableEditMode,
    locationExists,
    extensionExists
}) => (
        <Table.Row>
            <Table.Cell>
                {device.phone_name}
            </Table.Cell>
            <Table.Cell>
                {device.extension_number} {!extensionExists && <em>(deleted)</em>}
            </Table.Cell>
            <Table.Cell>
                {device.mac_addr}
            </Table.Cell>
            <Table.Cell>
                {device.screen_name}
            </Table.Cell>
            <Table.Cell>
                {device.location_name} {!locationExists && <em>(deleted)</em>}
            </Table.Cell>
            <Table.Cell>
                {(DateTimeUtils.TIMEZONES.find(({ abbr }) => abbr === device.timezone) || { text: '' }).text}
            </Table.Cell>
            <Table.Cell>
                {device.model}
            </Table.Cell>
            <Table.Cell>
                <Checkbox />
            </Table.Cell>
            <Table.Cell>
                <Checkbox />
            </Table.Cell>
            <Table.Cell>
                <MessagingIcon
                    icon='edit'
                    onClick={() => {onEnableEditMode(device)}}
                />
                <ControlledPopup
                    trigger={<MessagingIcon icon='trash' />}
                    onConfirm={() => { onDelete(device.id) }}
                    content={'Delete'}
                    inverted
                />
            </Table.Cell>
        </Table.Row>
    )

class DeviceRowContainer extends React.Component {
    onDelete = () => {
        this.props.onDelete(this.props.domain, this.props.device.id).then(() => {
            this.props.onReadDevices(this.props.domain);
            Notification.alert('Successfully deleted device!', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to delete device', STATUS_TYPES.ERROR, true);
        });
    }

    locationExistsForDeviceWithLocation = (id) => {
        return this.props.locations.findIndex(location => location.value === id) !== -1;
    }

    extensionExistsForDeviceWithExtension = (id) => {
        return this.props.extensions.findIndex(extension => extension.value === id) !== -1;
    }

    render() {
        if (this.props.deviceBeingDeleted === this.props.device.id) {
            return <TableLoader isLoading colspan={8} />
        }
        return (
            <DeviceRow
                onDelete={this.onDelete}
                device={this.props.device}
                onEnableEditMode={this.props.onEdit}
                locationExists={this.locationExistsForDeviceWithLocation(this.props.device.location_id)}
                extensionExists={this.extensionExistsForDeviceWithExtension(this.props.device.extension_id)}
            />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.provision.domain,
    deviceBeingDeleted: state.provision.deviceBeingDeleted,
    locations: state.provision.locations.map(({ id, label }) => ({
        key: id,
        value: id,
        text: label
    })),
    extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    }))
});

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, device) => {
        return dispatch(provisionActions.deleteDevice(domain, null, device));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRowContainer);
