import React, { useEffect, useState } from 'react';
import { Button, Grid, Form, Segment, Icon, Popup, Header, Loader, ListItem } from 'semantic-ui-react';
import { FileInput, RichTextEditor, TEXT_EDITOR_MODES, Notification, STATUS_TYPES, yup, generateResolver } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import emailActions from 'actions/email';
import userActions from 'actions/user';
import companyActions from 'actions/company';
import { Controller, useForm } from 'react-hook-form';
import templateCategoryActions from 'actions/template_category';
import CategorizedTemplates from '../../NewTasksTab/subcomponents/CategorizedTemplates';
import PreviewEmailTemplateModal from 'shared/modals/PreviewEmailTemplateModal';
import { getRemainingSize, handleChangeTemplate, personalizedVariables, getEmailPreview, determineSignature, disabledSignature, sendEmailAttachment, SIGNATURE_OPTIONS, personalizeButtons } from 'utils/EmailFormUtils';
import './index.scss';

const EmailForm = ({
    editorRef = React.createRef(),
    dropzoneRef = React.createRef(), 
    email,
    readEmails
}) => {
    let selectedThread = email; //convert legacy prop, to be more descriptive
    const dispatch = useDispatch();
    const { account_id } = useParams();

    const queryParameters = useSelector(state => state.email.queryParameters);
    const isSendingEmail = useSelector(state => state.email.isSendingEmail);
    const categorized_templates = useSelector(state => state.template_category.categorized_templates);
    const userProfile = useSelector(state => state.user.userProfile);
    const contact = useSelector(state => state.contact);
    const account = useSelector(state => state.account);
    const contactLocation = useSelector(state => state.contact.contact?.locations);
    const user_id = useSelector(state => state.auth.user_id);
    const userSignaturePreference = useSelector(state => state.user.signature_preferences.reply_forward_email);
    const userSignatures = useSelector(state => state.user.signatures);
    const companySignaturePreference = useSelector(state => state.company.signature_preferences.reply_forward_email);
    const companySignatures = useSelector(state => state.company.signatures);
    const activeIntegration = useSelector(state => state.oauth_integrations.activeIntegration);

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isPersonalizing, setPersonalize] = useState(false);

    const [emailToOptions, setEmailToOptions] = useState([]);
    const [emailCcOptions, setEmailCcOptions] = useState([]);
    const [emailBccOptions, setEmailBccOptions] = useState([]);

    const [userSignature, setUserSignature] = useState(null);
    const [companySignature, setCompanySignature] = useState(null);
    const [isDisabledSignature, setDisabledSignature] = useState([]);

    const { formState: { isValid, errors }, control, setValue, getValues, setError, trigger } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            to: yup.array().min(1, "This field is required"),
        })
    });
    const hasErrors = Object.keys(errors).length > 0;

    const handleAdditionCc = (_, { value }) => {
        setEmailCcOptions ((prevOptions) => [
            {text: value, value: value, key: value},
            ...prevOptions
        ]);
    };

    const handleAdditionBcc = (_, { value }) => {
        setEmailBccOptions ((prevOptions) => [
            {text: value, value: value, key: value},
            ...prevOptions
        ]);
    };

    const personalize = (variable) => {
        setPersonalize(false);
        const { body } = getValues();
        personalizedVariables(variable, editorRef, body, setValue);
    }

    const handleSend = () => {
        let formValues = { ...getValues() };
        formValues.body = getEmailPreview(
            formValues, 
            userSignature, 
            companySignature, 
            contact, 
            userProfile, 
            contactLocation,
            account
        );        
        formValues.thread_id = selectedThread?.thread_id;
        onSend(formValues, emailToOptions);
    }

    const onSend = async (values, emailToOptions) => {
        const { body, attachments, to, cc, bcc } = values;
        const {thread_id, message_id, subject} = selectedThread || null;
        let toName;

        if(account.account?.id){
            toName = account.account?.name || '';
        } else {
            const { first_name, last_name } = contact.contact;
            toName = `${first_name} ${last_name}`;
        }
        //bundle emails
        const toEmails = to.length !== 0 ? to.map(email => {
            return {
                name: toName, //derives from contact first and last or account name
                email: email
            }
        }) : [];
        const ccEmails = cc.length !== 0 ? cc.map(email => {
            let emailExist = emailToOptions.find(e => e.value === email);
                return {
                    name: emailExist ? toName : '', //derives from contact or no name if email added
                    email: email
                }
          
        }) : [];
        const bccEmails = bcc.length !== 0 ? bcc.map(email => {
            return {
                // name: ONLY added emails, no names 
                email: email
            }
        }): [];
        //Replay send, different than first email send, see difference below.
        const emailToSend = {
            body,
            from: {
                name: activeIntegration.name,
                email: activeIntegration.email,
            },
            to: toEmails,
            cc: ccEmails,
            bcc: bccEmails,
            body_type: 'html',
            subject: `Re: ${subject}`, // Add Re: to thead an email
            in_replay_to: message_id || null, //message_id when pulling from email-api.  To thread an email, add message_id to in_replay_to, also must add Re: above
            thread_id: thread_id || null,
        }

        if (attachments) {
            await sendEmailAttachment(attachments, emailToSend);
        }

        try {
            await dispatch(emailActions.send([emailToSend]))
            Notification.alert('Succesfully sent!', STATUS_TYPES.SUCCESS, true);
            setTimeout(()=>{readEmails() }, 2000)
        } catch {
            Notification.alert('Problem sending the email!', STATUS_TYPES.ERROR, true);
        }
    }

    useEffect(()=>{
        let hasUserSignature = false;
        let hasCompanySignature = false;

        if (userSignaturePreference) {
            const userSignature = userSignatures.find(signature => signature.id === userSignaturePreference);
            setUserSignature(userSignature?.content);
            hasUserSignature = true;
        }
        if (companySignaturePreference) {
            const companySignature = companySignatures.find(signature => signature.id === companySignaturePreference);
            setCompanySignature(companySignature?.content);
            hasCompanySignature = true;
        }        
        setValue('signature', determineSignature(hasUserSignature, hasCompanySignature));
        setDisabledSignature(disabledSignature(hasUserSignature, hasCompanySignature));
    }, [userSignaturePreference, userSignatures, companySignaturePreference, companySignatures, setValue, setDisabledSignature]);

    useEffect(() => {
        let allToEmails;
        let allToEmailsMapped;
        let allCCEmailsMapped;
        
        if(account_id){
            let accountEmails = account.account?.emails;
            const contactsAssociated = account.contactsForPinning ? account.contactsForPinning.filter(({email}) => { return email.email !== undefined } ) : [];
            allToEmails = accountEmails ? accountEmails.find(email => email.main)?.email : '';
            allToEmailsMapped = accountEmails ? accountEmails.map(email => {
                return {
                    key: email?.email,
                    value: email?.email,
                    text: email?.email,
                }
            }) : [];
            if(allToEmails?.length === 0){
                setError('to', { type: 'to_required', message: 'Add account email' }) 
            } else {
                allCCEmailsMapped = contactsAssociated.map(email => {
                    return {
                        key: email?.email.id,
                        value: email?.email.email,
                        text: email?.email.email,
                    }
                })
            }
        } else {
            allToEmails = contact.email.find(email => email.main)?.email || contact.email[0]?.email || [];
            allToEmailsMapped = contact.email.map(email => {
                return {
                    key: email.id,
                    value: email.email,
                    text: email.email
                }
            })
            allCCEmailsMapped = allToEmailsMapped;
        }
        setValue('to', [allToEmails]);
        setValue('from', activeIntegration.email || '');
        setValue('cc', []);
        setValue('bcc', []);
        setEmailToOptions(allToEmailsMapped);
        setEmailCcOptions(allCCEmailsMapped);

    }, [contact, setValue, queryParameters, activeIntegration, account, setError, account_id]);

    useEffect(() => {
        dispatch(companyActions.readSignatures());
        dispatch(templateCategoryActions.readCategorizedTemplates({ category: 'email' }));
        dispatch(userActions.readSignaturePreferences({ user_id }));
        dispatch(companyActions.readSignaturePreferences());
        dispatch(userActions.readSignatures({ user_id }));
        dispatch(userActions.viewUserProfile(user_id))
    }, [user_id, dispatch]);

    return (
        <React.Fragment>
            {isSendingEmail &&
                <Loader active>Preparing to send email...</Loader>
            }
            <Form>
                <Segment className='EmailReplyForm'>
                    <Form>
                        <Form.Group>
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Controller
                                        name='to'
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                            <Form.Dropdown
                                                label={<label><Icon name='reply' color='black' /> Reply To</label>}
                                                options={emailToOptions}
                                                onChange={(_, {value}) => { onChange({ target: { name: name, value: value}}) }}
                                                selection
                                                fluid
                                                multiple
                                                search
                                                required
                                                placeholder='Select an email'
                                                value={value}
                                                error={error && error.message && {
                                                    content: error.message,
                                                    pointing: 'below'
                                                }}
                                            />
                                        }
                                    />
                                }
                            />
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Controller
                                        name='from'
                                        defaultValue={''}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                            <Form.Input
                                                readOnly
                                                width={5}
                                                label='From'
                                                // onChange={(_, { value: selected }) => { onChange({ target: { name: 'from', value: selected } }) }}
                                                value={value}
                                            // error={error && error.message && {
                                            //   content: error.message,
                                            //   pointing: 'below'
                                            // }}
                                            // placeholder='Select an email'
                                            // selectOnBlur={false}
                                            // options={emailFromOptions}
                                            />
                                        }
                                    />
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Controller
                                        name='cc'
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                            <Form.Dropdown
                                                label={<label><Icon name='user' color='black' /> Cc</label>}
                                                options={emailCcOptions}
                                                onChange={(_, { value: selected }) => { onChange({ target: { name: name, value: selected } }) }}
                                                selection
                                                fluid
                                                multiple
                                                allowAdditions
                                                search
                                                onAddItem={handleAdditionCc}
                                                placeholder='Select an email'
                                                value={value}
                                                error={error && error.message && {
                                                    content: error.message,
                                                    pointing: 'below'
                                                }}
                                            />
                                        }
                                    />
                                }
                            />
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Controller
                                        name='bcc'
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                            <Form.Dropdown
                                                label={<label><Icon name='user' color='black' /> Bcc</label>}
                                                options={emailBccOptions}
                                                onChange={(_, { value: selected }) => { onChange({ target: { name: name, value: selected } }) }}
                                                selection
                                                fluid
                                                multiple
                                                allowAdditions
                                                search
                                                onAddItem={handleAdditionBcc}
                                                placeholder='Select an email'
                                                value={value}
                                                error={error && error.message && {
                                                    content: error.message,
                                                    pointing: 'below'
                                                }}
                                            />
                                        }
                                    />
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Controller
                                        name='subject'
                                        defaultValue={`Re: ${selectedThread.subject}`}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                            <Form.Input
                                                label={<label><Icon name='envelope' color='black' /> Subject</label>}
                                                readOnly
                                                width={5}
                                                value={value}
                                            />
                                        }
                                    />
                                }
                            />
                            <ListItem
                                className='EmailThread__Row--input'
                                description={
                                    <Form.Field>
                                        <Form.Field label={
                                            <label>
                                                <Icon name='fa-solid fa-pen' color='black' /> Signature {userSignature === null && companySignature === null && <Popup trigger={<Icon name='fa-solid fa-circle-info' color='blue' />} content='Signature needs to be configured' inverted basic position='right center'/>}
                                            </label>
                                        } />
                                        <Controller
                                            name='signature'
                                            defaultValue={''}
                                            control={control}
                                            rules={{
                                                required: {
                                                    message: 'This field is required',
                                                    value: true
                                                }
                                            }}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                <Form.Group style={{ marginTop: "0.75em" }}>
                                                    {SIGNATURE_OPTIONS.map((option, id) => (
                                                        <Form.Radio
                                                            key={option.key}
                                                            label={<label style={{ paddingLeft: '1.4em' }}>{option.text}</label>}
                                                            value={option.value}
                                                            checked={value === option.value}
                                                            disabled={isDisabledSignature[id]}
                                                            onChange={() => { onChange({ target: { name: "signature_preference", value: option.value } }) }}
                                                        />
                                                    ))}
                                                </Form.Group>
                                            }
                                        />
                                    </Form.Field>
                                }
                            />
                        </Form.Group>
                    </Form>
                    <Grid.Row>
                        <Grid.Column>
                            <Controller
                                name="body"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value }, }) => (
                                    <RichTextEditor
                                        basic
                                        style={{ marginBottom: 0 }}
                                        onChange={(_, { value: new_value }) => { onChange({ target: { name: 'body', value: new_value } }) }}
                                        value={value}
                                        toolbarClassName={"body_toolbar"}
                                        allowSwitchToHTML
                                        onSwitchToHTML={() => { }}
                                        onSwitchToRichText={() => { }}
                                        mode={TEXT_EDITOR_MODES.RICH_TEXT}
                                        editorRef={editorRef}
                                        otherControls={[
                                            <CategorizedTemplates
                                            onSelectTemplate={(templateId) => handleChangeTemplate(templateId, setValue, dispatch, trigger, userSignature, companySignature)}
                                                categorized_templates={categorized_templates}
                                                type='Email'
                                            />,
                                            <Popup
                                                trigger={
                                                    <Button
                                                        primary
                                                        onClick={() => { setPersonalize(!isPersonalizing) }}
                                                    >
                                                        Personalize
                                                    </Button>
                                                }
                                                open={isPersonalizing}
                                                onClose={() => { setPersonalize(false) }}
                                                position='bottom right'
                                                on='click'
                                                content={personalizeButtons(account_id, personalize)}
                                            />
                                        ]}
                                    />
                                )}
                            />
                            <Header as='h4'><Icon name='paperclip' /> Attachments</Header>
                            <Controller
                                name="attachments"
                                defaultValue={[]}
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                    <Form.Field
                                        as={Segment}
                                        className='Template__attachments-section'
                                    >
                                        <div>
                                            <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                        </div>
                                        <div className='Template__attachments'>
                                            <FileInput
                                                onChange={(_, { value: new_value }) => {
                                                    onChange({
                                                        target: {
                                                            name, value: [
                                                                ...value,
                                                                ...new_value.filter(new_file => (
                                                                    value.findIndex(file => file.path === new_file.path) === -1
                                                                ))
                                                            ]
                                                        }
                                                    })
                                                }}
                                                onReject={(rejected) => {
                                                    if (rejected.length > 0) {
                                                        Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                                    }
                                                }}
                                                onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                files={value}
                                                name="files"
                                                accept="application/pdf"
                                                icon="file pdf outline"
                                                size="mini"
                                                dropzoneRef={dropzoneRef}
                                                showIcon
                                            />
                                        </div>
                                        <i>Remaining: {getRemainingSize(value)} MB</i>
                                    </Form.Field>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='left' style={{marginTop: 20}}>
                            <Button
                                primary
                                size='tiny'
                                content='Send'
                                disabled={!isValid || hasErrors || isSendingEmail}
                                onClick={handleSend}
                                style={{ marginRight: 10, width: '100px' }}
                            />
                            <Button
                                basic
                                size='tiny'
                                content='Preview'
                                loading={false}
                                onClick={() => setIsPreviewModalOpen(true)}
                                style={{ marginRight: 0, width: '100px', backgroundColor: 'white', fontWeight: '700' }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
            </Form>
            {
                isPreviewModalOpen &&
                <PreviewEmailTemplateModal
                    open={isPreviewModalOpen}
                    onClose={() => setIsPreviewModalOpen(false)}
                    subject={getValues().subject}
                    content={getEmailPreview({ ...getValues() }, userSignature, companySignature, contact, userProfile, contactLocation, account)}
                    attachments={getValues().attachments}
                />
            }
        </React.Fragment>
    );
}

export default EmailForm;
