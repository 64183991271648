export default class MathUtils {
    static roundDecimals(number) {
        return Math.round((number + Number.EPSILON) * 100)/100
    }

    static calculateItemTotals = (item) => {
        const price = item.price;
        const sum =
            MathUtils.roundDecimals((price +
                (item.additional_price || 0)) *
            item.quantity);
        const discount = (item.discount || 0)/100;
        const totalAddonPrice =
            MathUtils.roundDecimals(item.addons.reduce((a, b) => a + b.price || 0, 0) *
            item.quantity);
        const grossTotal = sum + totalAddonPrice;
        const totalTax = MathUtils.roundDecimals(item.taxes.reduce(
            (a, b) =>
                a +
                b.amount *
                    (b.percent
                        ? 0.01 * grossTotal
                        : item.quantity),
            0
        ));
        const totalFee = MathUtils.roundDecimals(item.fees.reduce(
            (a, b) =>
                a +
                b.amount *
                    (b.percent
                        ? 0.01 * grossTotal
                        : item.quantity),
            0
        ));
        const discountAmount = MathUtils.roundDecimals((grossTotal * discount));
        const subtotal =
            grossTotal +
            totalTax +
            totalFee -
            discountAmount;
        return {
            sum, grossTotal, totalTax, totalFee, subtotal, discountAmount
        }
    }

    static getTotals = (items) => {
        const { grossTotal, totalDiscount, totalTax, totalFee } = items.reduce((a, item) => {
            const price = item.price;
            const total = (price + (item.additional_price || 0)) *
                item.quantity +
                item.totalAddonPrice;
            return {
                grossTotal: (
                    a.grossTotal + total
                ),
                totalDiscount: (
                    a.totalDiscount + total * (item.discount || 0)
                ),
                totalTax: a.totalTax + item.totalTax,
                totalFee: a.totalFee + item.totalFee
            };
        }, {
            grossTotal: 0,
            totalDiscount: 0,
            totalTax: 0,
            totalFee: 0
        });

        return {
            grossTotal,
            totalDiscount,
            totalTax,
            totalFee,
            subtotal: grossTotal + totalTax + totalFee - totalDiscount
        }
    }

    static roundUp(value) {
        return Math.round(value);
    }

    static getComputedDiscount(discount_type, price, discount) {
        let computed_discount = 0;
        if (discount_type === 'percent') {
            computed_discount = price * discount / 100;
        } else {
            computed_discount = discount;
        }
        return computed_discount;
    }

    static getActualPrice(price, quantity, discount, discount_type) {
        let computed_discount = MathUtils.getComputedDiscount(discount_type, price, discount);
        return price * quantity - computed_discount;
    }

    static getOverallDiscount(items) {
        return items.reduce((a, b) => a + MathUtils.getComputedDiscount(
            b.discount?.discount_type || 'percent',
            b.price || 0,
            b.discount?.discount || 0
        ), 0).toFixed(2);
    }

    static getSubtotal(items) {
        return items.reduce((a, b) => a + (b.price || 0) * (b.quantity || 0), 0);
    }

    static getTax(items) {
        return MathUtils.getSubtotal(items) * 0.10;
    }

    static getTotal(items) {
        return (MathUtils.getSubtotal(items) + MathUtils.getTax(items) - MathUtils.getOverallDiscount(items)).toFixed(2);
    }

    static formatValues(items, selected_items) {
        return items.map(({ price, plan, quantity, ...product }) => {
            const selectedPriceProp = MathUtils.getSelectedPropertyValue(product.id, price, 'price', product.package_id, selected_items);
            return {
                ...product,
                price: selectedPriceProp,
                plan: MathUtils.getSelectedPropertyValue(product.id, plan, 'plan', product.package_id, selected_items),
                quantity: MathUtils.getSelectedPropertyValue(product.id, quantity, 'quantity', product.package_id, selected_items),
                discount_price: MathUtils.getComputedDiscount(
                    product.discount.discount_type,
                    selectedPriceProp,
                    product.discount.discount
                )
            }
        });
    }

    static getSelectedPropertyValue = (id, actual_value, property, package_id, selected_items) => {
        const selectedItem = selected_items.find(product => (
            product.id === id ||
            (package_id && package_id === product.package_id)
        ));
        if (selectedItem) {
            return selectedItem[property];
        }
        return actual_value;
    }

    static calculatePercentage = (total, number) => `${(number * 100) / total}%`;
}
