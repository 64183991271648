const initialSate = {
    ring_group: {},
    isReadingRingGroup: false,
    groupBeingUpdated: false,
    ringGroupBeingDeleted: false,

    isReadingMembers: false,
    isAddingMembers: false,
    memberBeingUpdated: null,
    memberBeingDeleted: null,
    members: []
}

export default initialSate;
