const initialState = {
    isCreating: false,
    orderBeingRead: null,
    order: null,
    orderError: false,
    recentOrder: null,
    isReadingRecentOrder: false
}

export default initialState;
