import pbx_configActions from 'actions/pbx_config';
import { STATUS_TYPES, Notification } from 'dyl-components';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

const AddVoicemailMailboxSection = ({ label, onChange, onAddMailbox, onClose, isCreatingMailboxes }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Input
                label='Name'
                name='label'
                value={label}
                onChange={onChange}
            />
        </Form.Group>
        <Form.Group>
            {!isCreatingMailboxes && (
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
            )}
            <Form.Button
                loading={isCreatingMailboxes}
                primary
                floated='right'
                content={'Add'}
                onClick={onAddMailbox}
            />
        </Form.Group>
    </Form>
)

class AddVoicemailMailboxSectionContainer extends React.Component {
    state = {
        label: '',
        extension_number: 0
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddMailbox = () => {
        const { mailboxes } = this.props;
        const extension_number = String(Number(mailboxes.sort((a, b) => a - b)[mailboxes.length - 1]) + 1);

        this.props.onCreateMailbox(this.props.domain, { ...this.state, extension_number }).then(() => {
            this.props.onReadMailboxes(this.props.domain);
            this.setState({
                label: ''
            });
            Notification.alert('Successfully created voicemail box', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to create voicemail box', STATUS_TYPES.ERROR, true);
        })
    }

    onClose = () => {
        this.setState({
            label: '',
            extension_number: 0
        }, () => {
            this.props.onClose();
        })
    }

    render() {
        return (
            <AddVoicemailMailboxSection
                extension_number={this.state.extension_number}
                label={this.state.label}
                onAddMailbox={this.onAddMailbox}
                onChange={this.onChange}
                onClose={this.onClose}
                isCreatingMailboxes={this.props.isCreatingMailboxes}
            />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    isCreatingMailboxes: state.pbx_config.isCreatingVoicemailBoxes,
    mailboxes: state.pbx_config.voicemail_boxes.filter(({ extension_number }) => extension_number.startsWith("3")).map(({ extension_number }) => extension_number),
})

const mapDispatchToProps = dispatch => ({
    onReadMailboxes: (domain) => {
        dispatch(pbx_configActions.getVoicemailBoxes(domain));
    },
    onCreateMailbox: (domain, mailbox) => {
        return dispatch(pbx_configActions.createVoicemailBox([mailbox], null, domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVoicemailMailboxSectionContainer);
