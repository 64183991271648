import { VALIDATORS, yup } from "dyl-components";
import { applyEmailsSchema, applyPhonesSchema } from "shared/schemas/contact/commsSchema";

export default class ValidationUtils {
    static notEmpty(value) {
        return value !== null && value !== undefined && value !== '' && (value && `${value}`.trim() !== '');
    }

    static isEmail(value) {
        return value && value.includes('@');
    }

    static isValidOption(option, options) {
        return ValidationUtils.notEmpty(option) && options.findIndex(({ value }) => value === option) !== -1;
    }

    static isPhone(value) {
        return value && `${value}`.replace(/\D/g, '').length === 10;
    }

    static isPhoneExt(value) {
        return value && /^\d+$/.test(value);
    }

    static async checkForDuplicateItemsInTheList(array, type = 'Item', property = 'value', validator = VALIDATORS.SIMPLE_INPUT()) {
        const uniqueData = Array.from(
            new Set(array.map((row) => row[property])),
        );
        const isUnique = array.length === uniqueData.length;
        if (isUnique) {
            return true;
        }
        const index = array.findIndex(
            (row, i) => row[property]?.toLowerCase() !== uniqueData[i]?.toLowerCase(),
        );
        if (array[index][property] === '' || !(await validator.isValid(array[index][property]))) {
            return true;
        }
        return this.createError({
            path: `${this.path}.${index}.${property}`,
            message: `${type} already in the list`,
        });
    }

    static async checkForDuplicatePhonesInTheList(array) {
        const uniqueData = Array.from(
            new Set(array.map((row) => row.value)),
        );
        const isUnique = array.length === uniqueData.length;
        if (isUnique) {
            return true;
        }
        const index = array.findIndex(
            (row, i) => row.value !== uniqueData[i],
        );
        if (array[index].value === '' || !(await VALIDATORS.PHONE_NUMBER().isValid(array[index].value))) {
            return true;
        }
        return this.createError({
            path: `${this.path}.${index}.value`,
            message: "Phone already in the list",
        });
    }

    static async checkForDuplicatePipelineName() {
        
        return true;
    }

    static async checkForDuplicateEmailsInTheList(array) {
        const uniqueData = Array.from(
            new Set(array.map((row) => row.value)),
        );
        const isUnique = array.length === uniqueData.length;
        if (isUnique) {
            return true;
        }
        const index = array.findIndex(
            (row, i) => row.value?.toLowerCase() !== uniqueData[i]?.toLowerCase(),
        );
        if (array[index].value === '' || !(await (VALIDATORS.EMAIL_ADDRESS().isValid(array[index].value)))) {
            return true;
        }
        return this.createError({
            path: `${this.path}.${index}.value`,
            message: "Email already in the list",
        });
    }

    static NEW_CONTACT_VALIDATIONS = {
        new: yup.boolean().nullable(true),
        first_name: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: () => VALIDATORS.FIRST_NAME().no_whitespace_only().required('This field is required')
        }),
        last_name: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: () => VALIDATORS.LAST_NAME().no_whitespace_only().required('This field is required')
        }),
        suffix: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: () => VALIDATORS.SUFFIX()
        }), 
        department: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.maxlength(64),
        }), 
        job_title: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.maxlength(60),
        }),
        street: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.maxlength(100),
        }),
        apartmentUnitOrFloor: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.maxlength(12),
        }),
        city: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.maxlength(60),
        }),
        zip: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: () => VALIDATORS.US_POSTAL_CODE(),
        }),
        phones: yup.array().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => applyPhonesSchema(schema)
        }),
        emails: yup.array().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => applyEmailsSchema(schema)
        }),
        website: yup.string().nullable(true).when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: () => VALIDATORS.WEBSITE()
        }),
        state: yup.string().when("new", {
            is: isNew => isNew === undefined || isNew === true,
            then: schema => schema.required('This field is required')
        })
    }

    static isMinimumFields = (dataMappingWatch) => {
        let MINIMUM_REQUIRED_RECORD_FIELDS = [
            {
                text: "First Name",
                isValid: false,
                values: "person.first_name"
            },
            {
                text: "Last Name",
                isValid: false,
                values: "person.last_name",
        
            },
            {
                text: "Email",
                isValid: false,
                values: "person.email.X.email"
            }, 
            {
                text: "Phone",
                isValid: false,
                values: "person.phone.X.phone"
            }, 
            {
                text: "State",
                isValid: false,
                values: "person.location.X.state"
            } 
        ];
        let isMinimumFirstName = false
        let isMinimumLastName = false
        let isMinimumPhone = false
        let isMinimumEmail = false
        let isMinimumState = false

        for(let i=0; i < MINIMUM_REQUIRED_RECORD_FIELDS.length; i++){
            dataMappingWatch?.fields.forEach(({dyl_field, skipped})=>{
				if (!skipped && dyl_field) {
					const flds = dyl_field.split(':').map(f => f.replace(/\d/, "X"))
					if (flds.includes(MINIMUM_REQUIRED_RECORD_FIELDS[i].values)) {
						MINIMUM_REQUIRED_RECORD_FIELDS[i].isValid = true;
					}
				}
            })
        }
    
        const isMinimum = () => {
            MINIMUM_REQUIRED_RECORD_FIELDS.forEach(({ text, isValid })=>{
                if(text === "First Name" && isValid){
                    isMinimumFirstName = true;
                }
                if(text === "Last Name" && isValid){
                    isMinimumLastName = true;
                }
                if(text === "Email" && isValid){
                    isMinimumEmail = true;
                }
                if(text === "Phone" && isValid){
                    isMinimumPhone = true;
                }
                if(text === "State" && isValid){
                    isMinimumState = true;
                }
            })
            return isMinimumFirstName && isMinimumLastName && (isMinimumPhone || isMinimumEmail) && isMinimumState;
        }
        return { validFields: MINIMUM_REQUIRED_RECORD_FIELDS, isValid: isMinimum()}
    }
}
