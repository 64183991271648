import React, { useContext, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { ConditionsContext } from './ConditionsProvider';
import { Condition } from './Condition';
import { CompositeCondition } from './CompositeCondition';

export const Conditions = ({
    control,
    parentName,
    segmentIndex,
    segmentId,
    trigger,
    watchedSegments,
    compositeFields,
    onChangeCompositeField,
    onAddAdditionalField,
    onRemoveAdditionalField,
    setValue,
    watch
}) => {
    const {
        addCallbacks,
        deleteCallbacks,
    } = useContext(ConditionsContext);

    const name = `${parentName}.conditions`

    const { fields: conditions, append, update, remove } = useFieldArray({
        control,
        name,
    });

    const onUpdateField = (value, name, index) => {
        update(index, {...conditions[index], [name]: value})
    }

    useEffect(() => {
        addCallbacks({index: segmentIndex, segmentId, append, remove})
        return () => deleteCallbacks(segmentId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompositeFieldOptions = (condition) => {
        let fields = [];
        switch (condition.field) {
            case "composite_phone":
                fields = compositeFields?.phoneFields || []
                break;
            case "composite_email":
                fields = compositeFields?.emailFields || []
                break;
            case "composite_location":
                fields = compositeFields?.locationFields || []
                break;
            default:
                break;
        }
        return fields.map((field) => {
            delete field.className;
            return field
        }) || [];
    }

    const getCompositeConditions = (conditions, parent) => {
        return conditions.map((condition, index) => condition.parent ? ({...condition, outerIndex: index}) : condition).filter((condition) => condition.parent === parent)
    }

    const segmentConditions = watchedSegments?.[segmentIndex]?.conditions || [];
    const segmentConditionsNoCriteria = segmentConditions.filter((condition) => condition.field !== "criteria" && !condition.parent);
    return (
        segmentConditions.map((condition, index) => {
            if (condition.field === "criteria" || !!condition.parent) {
                return null;
            }
            const indexNoCriteria = segmentConditionsNoCriteria.findIndex((conditionNoCriteria) => conditionNoCriteria.field === condition.field)
            const isLast = indexNoCriteria === segmentConditionsNoCriteria.length - 1;
            const { id, type, field } = condition;
            if (type === "composite") {
                return (
                    <CompositeCondition
                        compositeOptions={getCompositeFieldOptions(condition)}
                        condition={condition}
                        conditions={getCompositeConditions(segmentConditions, field)}
                        control={control}
                        displayIndex={indexNoCriteria + 1}
                        isLast={isLast}
                        key={id}
                        name={name}
                        onAddAdditionalField={onAddAdditionalField}
                        onChangeCompositeField={onChangeCompositeField}
                        onRemoveAdditionalField={onRemoveAdditionalField}
                        onUpdateField={onUpdateField}
                        parentName={parentName}
                        segmentIndex={segmentIndex}
                        trigger={trigger}
                        watch={watch}
                    />
                )
            }
            return (
                <Condition
                    condition={condition}
                    control={control}
                    displayIndex={indexNoCriteria + 1}
                    index={index}
                    isLast={isLast}
                    key={id}
                    name={name}
                    parentName={parentName}
                    trigger={trigger}
                    setValue={setValue}
                    watch={watch}
                />
            )
        })
    )
}
