import React from 'react';
import { Table, MessagingIcon, STATUS_TYPES, Notification } from 'dyl-components';
import { Icon, TableCell } from 'semantic-ui-react';
import pbx_configActions from 'actions/pbx_config';
import { connect } from 'react-redux';

const MailboxRow = ({
    domain,
    mailbox,
    onEdit,
    onDelete,
    onRead,
    voicemailBoxBeingDeleted,
    deleteable
}) => (
    <Table.Row>
        <Table.Cell>
            {mailbox.label}
        </Table.Cell>
        <TableCell>
            {mailbox.extension_number}
        </TableCell>
        <TableCell>
            {mailbox.pin}
        </TableCell>
        <Table.Cell>
            {mailbox.email_delivery}
        </Table.Cell>
        <Table.Cell>
            <MessagingIcon
                icon='edit'
                onClick={() => { onEdit(mailbox) }}
            />
            {deleteable && (
                <MessagingIcon
                    icon='trash'
                    onClick={() => {
                        onDelete(domain, mailbox.id).then(() => {
                            Notification.alert('Successfully deleted voicemail box!', STATUS_TYPES.SUCCESS, true);
                            onRead(domain);
                        }).catch(() => {
                            Notification.alert('Failed to delete voicemail box', STATUS_TYPES.ERROR, true);
                        })
                    }}
                />
            )}
            {voicemailBoxBeingDeleted === mailbox.id && <Icon name='circle notch' loading />}
        </Table.Cell>
    </Table.Row>
)

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    voicemailBoxBeingDeleted: state.pbx_config.voicemailBoxBeingDeleted
});

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, voicemail_box_id) => {
        return dispatch(pbx_configActions.deleteVoicemailBox(domain, null, voicemail_box_id))
    },
    onRead: (domain) => {
        dispatch(pbx_configActions.getVoicemailBoxes(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(MailboxRow);
