import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';

const Routing = () => {
    return <Grid>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column style={{ paddingTop: '1em', paddingRight: 0, paddingLeft: '0.5em' }}>
                <Menu secondary className='PhoneManagement_generalTabs'>
                    <Menu.Item name='Ring Group/Call Queues' as={NavLink} to='ring-group-call-queues' />
                    <Menu.Item name='Destination Groups' as={NavLink} to='destination-groups' />
                    <Menu.Item name='IVR Prompts' as={NavLink} to='ivr-prompts' />
                    <Menu.Item name='Call Routing Rules' as={NavLink} to='call-routing-rules' />
                </Menu>
                <Segment basic>
                    <Outlet />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>;
}

export default Routing;