import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function groupsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingGroups: true };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingGroups: false,
                groups: action.data.data || [],
                hotlist: action.data.hotlist || null,
                count: action.data.count || 0
            };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingLabels: false };

        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingGroups: true };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingGroups: false };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingGroups: false };

        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingGroup: true };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingGroup: false };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingGroup: false };

        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingGroup: true };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingGroup: false };
        case getNamedAction(ACTION_NAMES.GROUPS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingGroup: false };

        case getNamedAction(ACTION_NAMES.SUBGROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSubgroups: true };
        case getNamedAction(ACTION_NAMES.SUBGROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            const { parent_label_id } = action.data;
            return {
                ...state,
                subgroups: {...state.subgroups, [parent_label_id]: action.data.data},
                isReadingSubgroups: false,
            };
        case getNamedAction(ACTION_NAMES.SUBGROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSubgroups: false };

        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContacts: true };
        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state,
                contacts: action.data.data || [],
                contactCount: action.data.count,
                isReadingContacts: false,
                groupName: action.data.name || "Unknown Group",
                parent: action.data.parent
            };
        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContacts: false };

        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContactGroups: true };
        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state,
                contactGroups: action.data.data || [],
                contactGroupsCount: action.data.count,
                isReadingContactGroups: false,
            };
        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContactGroups: false };
            
        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, contactBeingRemovedFromGroup: action.id };
        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, contactBeingRemovedFromGroup: null };
        case getNamedAction(ACTION_NAMES.CONTACT_GROUPS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, contactBeingRemovedFromGroup: null };
            
        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingContact: true };
        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingContact: false };
        case getNamedAction(ACTION_NAMES.GROUP_CONTACTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingContact: false };
            
        case getNamedAction(ACTION_NAMES.MOVE_GROUPS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isMovingSubgroup: true };
        case getNamedAction(ACTION_NAMES.MOVE_GROUPS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isMovingSubgroup: false };
        case getNamedAction(ACTION_NAMES.MOVE_GROUPS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isMovingSubgroup: false };
            
        case getNamedAction(ACTION_NAMES.GROUP_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingGroupOptions: true };
        case getNamedAction(ACTION_NAMES.GROUP_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingGroupOptions: false };
        case getNamedAction(ACTION_NAMES.GROUP_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingGroupOptions: false };
            
        case getNamedAction(ACTION_NAMES.GROUPS_BULK, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isDoingBulkAction: true };
        case getNamedAction(ACTION_NAMES.GROUPS_BULK, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isDoingBulkAction: false };
        case getNamedAction(ACTION_NAMES.GROUPS_BULK, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isDoingBulkAction: false };

        case getNamedAction(ACTION_NAMES.BULK_GROUPS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingGroup: true };
        case getNamedAction(ACTION_NAMES.BULK_GROUPS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingGroup: false };
        case getNamedAction(ACTION_NAMES.BULK_GROUPS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingGroup: false };

        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContacts: true };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingContacts: false,
                hotlistContacts: action.data.data || null,
                hotlistCount: action.data.count || 0
            };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContacts: false };

        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingHotlist: true };
        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isCheckingHotlist: false,
                contactsInHotlist: action.data || [],
            };
        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingHotlist: false };

        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isRemovingHotlist: true };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return {...state, isRemovingHotlist: false };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isRemovingHotlist: false };

        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingContactHotlist: true };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return {...state, isAddingContactHotlist: false };
        case getNamedAction(ACTION_NAMES.GROUPS_HOTLIST, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingContactHotlist: false };

        default:
            return state;
    }
}


export default groupsReducer;

