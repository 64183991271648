import React from 'react';
import { Form } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';

const AddLocationForm = ({
    label,
    address,
    address2,
    city,
    state,
    zip,
    caller_id_number,
    onChange
}) => (
        <Form>
            <Form.Input
                label='Name'
                name='label'
                value={label}
                onChange={onChange}
            />

            <Form.Input
                label='Address 1'
                name='address'
                value={address}
                onChange={onChange}
            />
            <Form.Input
                label='Address 2'
                name='address2'
                value={address2}
                onChange={onChange}
            />
            <Form.Input
                label='Phone'
                name='caller_id_number'
                value={caller_id_number}
                onChange={onChange}
            />
            <Form.Input
                label='City'
                name='city'
                value={city}
                onChange={onChange}
            />

            <Form.Select
                label='State'
                name='state'
                value={state}
                onChange={onChange}
                options={STATES.map(({ text, key }) => ({
                    key: key, value: key, text
                }))}
            />
            <Form.Input
                label='Zip Code'
                name='zip'
                value={zip}
                onChange={onChange}
            />
        </Form>
    );

export default AddLocationForm;
