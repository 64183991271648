import React from 'react';
import { Table, ControlledPopup, MessagingIcon } from 'dyl-components';
import { connect } from 'react-redux';
import provisionActions from 'actions/provision';

const LocationRow = ({ location, onDelete, onEnableEditMode }) => (
    <Table.Row>
        <Table.Cell>
            {location.label}
        </Table.Cell>
        <Table.Cell>
            {location.address}
        </Table.Cell>
        <Table.Cell>
            {location.address2}
        </Table.Cell>
        <Table.Cell>
            {location.city}
        </Table.Cell>
        <Table.Cell>
            {location.state}
        </Table.Cell>
        <Table.Cell>
            {location.zip}
        </Table.Cell>
        <Table.Cell>
            <MessagingIcon
                icon='edit'
                onClick={() => {onEnableEditMode(location)}}
            />
            <ControlledPopup
                trigger={<MessagingIcon icon='trash' />}
                onConfirm={() => { onDelete(location.id) }}
                content={'Delete'}
                inverted
            />
        </Table.Cell>
    </Table.Row>
)

class LocationRowContainer extends React.Component {
    onDelete = (id) => {
        this.props.onDelete(this.props.domain, id).then(() => {
            this.props.onReadLocations(this.props.domain)
        });
    }

    render() {
        return (
            <LocationRow
                location={this.props.location}
                onEnableEditMode={this.props.onEdit}
                onDelete={this.onDelete}
            />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.provision.domain
});

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, id) => {
        return dispatch(provisionActions.deleteLocation(domain, {}, id)).then(() => {
            dispatch(provisionActions.getLocations(domain))
        });
    },
    onReadLocations: (domain) => {
        dispatch(provisionActions.getLocations(domain))
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(LocationRowContainer);
