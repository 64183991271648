import React from 'react';
import VoicemailInstructions from './subcomponents/VoicemailInstructions';
import PhoneExtensionsContainer from './subcomponents/PhoneExtensions';
import { STATUS_TYPES, Notification } from 'dyl-components';
import AdditionalOptionsContainer from './subcomponents/AdditionalOptions';
import VoicemailMailboxesContainer from './subcomponents/VoicemailMailboxes';
import ManageDevices from './subcomponents/ManageDevices';
import ManageSoftphones from './subcomponents/ManageSoftphones';
import ManageLocations from './subcomponents/ManageE911Locations';
import pbxConfigActions from 'actions/pbx_config';
import provisionActions from 'actions/provision';
import { connect } from 'react-redux';
import cidSelectActions from 'actions/cid_select';
import phoneNumberActions from 'actions/phone_number';

const UserExtensions = ({
    onUpdateAdditionalOptions,
    isVoicemailExitEnabled,
    isDNDOnMute,
    conferenceRoomDisplays,
    voicemailOptions,
    locations
}) => (
        <React.Fragment>
            <VoicemailInstructions />
            <PhoneExtensionsContainer
                isVoicemailExitEnabled={isVoicemailExitEnabled}
                voicemailOptions={voicemailOptions}
            />
            <VoicemailMailboxesContainer />
            <ManageDevices />
            <ManageSoftphones />
            <ManageLocations
                locations={locations}
            />
            <AdditionalOptionsContainer
                onUpdate={onUpdateAdditionalOptions}
                isVoicemailExitEnabled={isVoicemailExitEnabled}
                isDNDOnMute={isDNDOnMute}
                conferenceRoomDisplays={conferenceRoomDisplays}
            />
        </React.Fragment>
    );

class UserExtensionsContainer extends React.Component {
    state = {
        devices: [
            {
                id: 'dev1', name: 'Device 1', extension: '101',
                mac_id: '00:04:F2:24:A0:42 00:0B:82:83:75:78 00:15:65:31:CF:4A CC:EF:48:5D:A8:31',
                display_name: 'Dev 1', e911_location: 'loc1', timezone: 'DST', model: 'Pixel 3'
            },
            {
                id: 'dev2', name: 'Device 2', extension: '102',
                mac_id: '00:04:F2:C0:98:C4 00:0B:82:63:CB:A9',
                display_name: 'Dev 2', e911_location: 'loc1', timezone: 'DST', model: 'iPhone 11'
            }
        ],
        locations: [
            {
                id: 'loc1', name: 'Location 1', address1: '', address2: '', city: '', state: 'OH', zip: ''
            },
            {
                id: 'loc2', name: 'Location 2', address1: '', address2: '', city: '', state: 'AL', zip: ''
            }
        ],
        isVoicemailExitEnabled: true,
        isDNDOnMute: false,
        conferenceRoomDisplays: 3,
        voicemailOptions: [
            { id: 'bx100', name: 'Company Mailbox', voicemail: 'Box 100', pin: '8486', emailDelivery: [] },
            { id: 'bx101', name: 'Bob Dunn Insurance', voicemail: 'Box 101', pin: '4538', emailDelivery: [] },
            { id: 'bx102', name: 'Office B', voicemail: 'Box 102', pin: '2384', emailDelivery: [] }
        ],
        extensions: [
            {
                number: '101', user: 'Tom Luck', department: 'sls', voicemail: 'bx100', voicemailExit: 'Hangup',
                macAddress: '00:04:F2:24:A0:42 00:0B:82:83:75:78 00:15:65:31:CF:4A CC:EF:48:5D:A8:31',
                loginInfo: {
                    sipServer: 'sip.dylphone.com',
                    port: 5060,
                    username: '6854380881',
                    password: '5UhnZp5UyEn8'
                }
            },
            {
                number: '102', user: 'James Sales', department: 'sls', voicemail: 'bx101', voicemailExit: 'Hangup',
                macAddress: '00:04:F2:C0:98:C4 00:0B:82:63:CB:A9',
                loginInfo: {
                    sipServer: 'sip.dylphone.com',
                    port: 5060,
                    username: '2378237430',
                    password: 'Tf8se60Goe5U'
                }
            }
        ]
    }

    componentDidMount() {
        this.props.onReadDomains().then(() => {
            const { domain, domain_name } = this.props;
            this.props.onReadExtensions(domain);
            this.props.onReadVoicemailBoxes(domain);
            this.props.onReadCIDS(domain);

            this.props.onReadPhoneNumberDomains().then(() => {
                this.props.onReadPhoneNumbers(this.props.phone_number_domain_name, domain);
            });

            this.props.onReadProvisionDomain({ domain: domain_name }).then(() => {
                const { provision_domain } = this.props;
                this.props.onReadDevices(provision_domain);
                this.props.onReadSoftphones(provision_domain);
                this.props.onReadLocations(provision_domain);
            })
        });
    }

    onUpdateAdditionalOptions = (additionalOptions) => {
        this.setState({ ...additionalOptions }, () => {
            Notification.alert('Successfully updated additional options!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <UserExtensions
                {...this.state}
                onUpdateAdditionalOptions={this.onUpdateAdditionalOptions}
            />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    domain_name: state.pbx_config.domain_name,
    provision_domain: state.provision.domain,
    phone_number_domain_name: state.phone_number.domain
});

const mapDispatchToProps = dispatch => ({
    onReadDomains: () => {
        return dispatch(pbxConfigActions.getDomains());
    },
    onReadSoftphones: (domain) => {
        dispatch(provisionActions.getSoftphones(domain));
    },
    onReadLocations: (domain) => {
        dispatch(provisionActions.getLocations(domain))
    },
    onReadExtensions: (domain) => {
        dispatch(pbxConfigActions.getExtensions(domain));
    },
    onReadVoicemailBoxes: (domain) => {
        dispatch(pbxConfigActions.getVoicemailBoxes(domain));
    },
    onReadDevices: (domain) => {
        dispatch(provisionActions.getDevices(domain));
    },
    onReadCIDS: (domain) => {
        dispatch(cidSelectActions.readCallerIdSelects(domain));
    },
    onReadProvisionDomain: (queryParameters) => {
        return dispatch(provisionActions.getDomain(queryParameters));
    },
    onReadPhoneNumberDomains: () => {
        return dispatch(phoneNumberActions.readDomains());
    },
    onReadPhoneNumbers: (domain, domain_id) => {
        dispatch(phoneNumberActions.read({ domain, domain_id }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserExtensionsContainer);
