import axiosInstance from 'actions/axiosInstance';
import CRUD_ACTION_TYPES, { getNamedAction } from './CRUD_ACTION_TYPES';
// import { LogRocketIdentifyUser } from '../logRocketSetup'; //DISABLING LOG ROCKET UNTIL USED

let client = axiosInstance.instance1;

const checkIsLoggedIn = (error = null, status = '401') => {
    /** This is a temp fix until we can create a routing 
     *  strategy/schema for the client-side app      **/

    const redirectUrl = '/login';

    if (window.location.pathname !== redirectUrl && error?.message.includes(status)){
        window.location.href = redirectUrl;
    }
}

window.addEventListener('unhandledrejection', function(event) {
    event.preventDefault();
    console.log(event?.reason?.message); 
});

export const generateCreateActionCreator = (endpoint, name, callbackOnSuccess = () => {}, callbackOnFailure = () => {}, subendpoint = '') => (body, queryParameters, id='', headers) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(name, CRUD_ACTION_TYPES.CREATE_REQUEST),
            id,
            body,
            queryParameters
        });
        return client.post(`${endpoint}${id ? `/${id}` : ''}${subendpoint && id ? `/${subendpoint}` : ''}`, body, {params: queryParameters, headers})
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.CREATE_SUCCESS),
                    data: response.data
                });

                callbackOnSuccess();

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.CREATE_FAILURE)
                });

                callbackOnFailure();

                return Promise.reject(error.response?.data);
            })
    }
}

export const generateReadActionCreator = (endpoint, name) => (queryParameters = {}) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(name, CRUD_ACTION_TYPES.READ_REQUEST),
            queryParameters
        });
        return client.get(endpoint, { params: queryParameters })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.READ_SUCCESS),
                    data: response.data,
                    queryParameters
                });

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.READ_FAILURE)
                });

                checkIsLoggedIn(error);

                return Promise.reject(error.response?.data);
            })
    }
}

export const generateUpdateActionCreator = (endpoint, name, callbackOnSuccess = () => {}, callbackOnFailure = () => {}, subendpoint='', isPatch = false) => (id, body, queryParameters = {}, subId = '') => {
    return dispatch => {
        dispatch({
            type: getNamedAction(name, CRUD_ACTION_TYPES.UPDATE_REQUEST),
            id,
            subId,
            queryParameters
        });
        return client[isPatch ? 'patch' : 'put'](`${endpoint}/${id ? id : ''}${subendpoint ? `/${subendpoint}` : ''}${subendpoint && subId ? `/${subId}` : ''}`, body, { params: queryParameters })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.UPDATE_SUCCESS),
                    id,
                    body
                });

                callbackOnSuccess();

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.UPDATE_FAILURE)
                });

                callbackOnFailure();

                return Promise.reject(error.response?.data);
            })
    }
}

export const generateReadOneActionCreator = (endpoint, name, subendpoint = '') => (id, queryParameters, subId) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(name, CRUD_ACTION_TYPES.READ_ONE_REQUEST),
            id: id,
            subId
        });

        return client.get(`${endpoint}${id ? `/${id}` : ''}${subendpoint ? `/${subendpoint}` : ''}${subendpoint && subId ? `/${subId}` : ''}`, { params: queryParameters })
            .then((response) => {
                console.log(response);

                // DISABLING LOG ROCKET UNTIL USED 
                // if(subendpoint === 'profile'){ 
                //     LogRocketIdentifyUser(response.data)
                // }
                
                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.READ_ONE_SUCCESS),
                    data: response.data,
                    queryParameters,
                    id,
                    subId
                });

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.READ_ONE_FAILURE),
                    id
                });
                
                checkIsLoggedIn(error);

                return Promise.reject(error.response?.data);
            })
    }
}

export const generateDeleteActionCreator = (endpoint, name, onSuccess = () => {}, onFailure = () => {}, subendpoint = '') => (id = '', queryParameters = {}, subId = '', otherProps = {}, body) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(name, CRUD_ACTION_TYPES.DELETE_REQUEST),
            id,
            subId,
            queryParameters,
            otherProps,
            body
        });
        return client.delete(`${endpoint}${id ? `/${id}` : ''}${subendpoint ? `/${subendpoint}` : ''}${subendpoint && subId ? `/${subId}` : ''}`, { data: body, params: queryParameters })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.DELETE_SUCCESS),
                    id
                });

                onSuccess();
            })
            .catch((error) => {
                dispatch({
                    type: getNamedAction(name, CRUD_ACTION_TYPES.DELETE_FAILURE)
                });

                onFailure();

                return Promise.reject(error.response?.data);
            })
    }
}
