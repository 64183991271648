import { SearchBar } from "dyl-components";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Ref, Tab } from "semantic-ui-react";
import CategoryProducts from "./CategoryProducts";
import productCatalogActions from "actions/product_catalog";

const Products = ({ opportunity_id }) => {
    const { categories } = useSelector((state) => ({
        categories: state.product_category.categories,
    }));

    const tabContainerRef = useRef();

    const panes = useMemo(
        () => [
            {
                key: "all",
                menuItem: "All",
            },
            {
                key: "uncategorized",
                menuItem: "Uncategorized",
            },
            ...categories
                ?.filter((category) => category.has_products)
                .map((category) => ({
                    key: category.id,
                    menuItem: category.name,
                })),
        ],
        [categories]
    );

    const [activeTab, setActiveTab] = useState("all");

    const getActiveTab = () => {
        const index = panes.findIndex(
            (pane) => pane.menuItem?.toLowerCase() === activeTab?.toLowerCase()
        );
        return index === -1 ? 0 : index;
    };

    const onChangeTab = (index) => {
        const { menuItem } = panes[index];
        setActiveTab(menuItem);
    };

    const dispatch = useDispatch();

    const [queryParameters, setQueryParameters] = useState({
        page: 1,
        product_type: [],
        product_category_id: null,
        search: "",
    });

    useEffect(() => {
        const selectedCategoryIndex = panes.findIndex(
            (pane) => pane.menuItem?.toLowerCase() === activeTab?.toLowerCase()
        );
        const product_category_id = (() => {
            if (selectedCategoryIndex === 0) {
                return null;
            }
            if (selectedCategoryIndex === 1) {
                return 0;
            }
            return panes[selectedCategoryIndex].key;
        })();
        setQueryParameters((queryParameters) => ({
            ...queryParameters,
            product_category_id,
            page: 1,
            product_type: [],
        }));
    }, [activeTab, panes]);

    useEffect(() => {
        dispatch(productCatalogActions.readCatalog(queryParameters));
    }, [queryParameters, dispatch]);

    const searchFunction = (searchValue) => {
        setQueryParameters({
            ...queryParameters,
            search: searchValue,
            page: 1
        });
    };

    const searchCancelFunction = () => {
        setQueryParameters({
            ...queryParameters,
            search: "",
            page: 1
        });
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <SearchBar
                        search={queryParameters.search}
                        searchFcn={searchFunction}
                        cancelFcn={searchCancelFunction}
                        placeholder="Search Product"
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Ref innerRef={tabContainerRef}>
                    <Grid.Column>
                        <Tab
                            className="CatalogWizard__categories"
                            menu={{
                                secondary: true,
                                style: {
                                    maxWidth:
                                        tabContainerRef.current?.getBoundingClientRect()
                                            .width,
                                },
                            }}
                            panes={panes}
                            activeIndex={getActiveTab()}
                            onTabChange={(_, { activeIndex }) => {
                                onChangeTab(activeIndex);
                            }}
                        />
                    </Grid.Column>
                </Ref>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <CategoryProducts
                        onChangePage={(page) => {
                            setQueryParameters((queryParameters) => ({
                                ...queryParameters,
                                page,
                            }));
                        }}
                        hasFilters={
                            queryParameters.search ||
                            queryParameters.product_type
                        }
                        opportunity_id={opportunity_id}
                        queryParameters={queryParameters}
                        onFilter={(_, { name, value }) => {
                            setQueryParameters((queryParameters) => ({
                                ...queryParameters,
                                [name]: value,
                                page: 1
                            }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Products;
