import { DateInput, DateTimeUtils, LinkedAccount } from "dyl-components";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Form, Grid, Icon, Input, Popup } from "semantic-ui-react";
import Location from "shared/Location";
import QuoteStatusField from "../QuoteStatusField";
import QuoteContactAddress from "./QuoteContactAddress";
import { useState } from "react";

const LocationDisplay = ({ location }) => {
    if (location?.state) {
        return <Location location={location} />;
    }
    return (
        <div className="Location">
            <div className="Location__line">No Default Location</div>
        </div>
    );
};

const QuoteDetails = () => {
    const { control } = useFormContext();
    const quote = useSelector((state) => state.quote.quote);
    const { company_email, company_phone, company_location } = useSelector(
        (state) => {
            return {
                company_email: state.company.company?.email || "",
                company_phone: state.company.company?.phones?.find(
                    (phone) => phone.main
                )?.phone,
                company_location: state.company.company?.locations?.find(
                    (location) => location.main
                ),
            };
        }
    );
    const { field: fromPhoneField } = useController({
        name: "from_phone",
        control,
    });
    const { field: fromEmailField } = useController({
        name: "from_email",
        control,
    });

    const { field: contactAddressField } = useController({
        name: "contact_address",
        control,
    });

    const { field: contactIdField } = useController({
        name: "contact_id",
        control,
    });

    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);

    const { field: contactEmailField } = useController({
        name: "contact_email",
        control,
    });

    const { field: contactPhoneField } = useController({
        name: "contact_phone",
        control,
    });

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            error={error?.message}
                                            label="Quote Name"
                                            required
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Controller
                                    name="from_user_id"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Form.Dropdown
                                            label="From"
                                            required
                                            placeholder="Select User"
                                            selectOnBlur={false}
                                            selection
                                            options={[]}
                                            value={value}
                                            fluid
                                            {...(value
                                                ? {
                                                      text: (
                                                          <LinkedAccount
                                                              account={
                                                                  quote
                                                                      .from_information
                                                                      .user_name
                                                              }
                                                              maxWidth={
                                                                  "8.25em"
                                                              }
                                                              subtitle={
                                                                  quote
                                                                      .from_information
                                                                      .user_email
                                                              }
                                                              popup
                                                          />
                                                      ),
                                                  }
                                                : {})}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="profile_info"
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <>
                                            <Form.Radio
                                                label="Profile Info"
                                                checked={value}
                                                onChange={() => {
                                                    onChange({
                                                        target: {
                                                            name,
                                                            value: !value,
                                                        },
                                                    });
                                                    fromPhoneField.onChange({
                                                        target: {
                                                            name: fromPhoneField.name,
                                                            value:
                                                                quote
                                                                    .from_information
                                                                    .user_phone ||
                                                                "",
                                                        },
                                                    });
                                                    fromEmailField.onChange({
                                                        target: {
                                                            name: fromEmailField.name,
                                                            value: quote
                                                                .from_information
                                                                .user_email,
                                                        },
                                                    });
                                                }}
                                            />
                                            <Form.Radio
                                                label="Company Info"
                                                checked={!value}
                                                onChange={() => {
                                                    onChange({
                                                        target: {
                                                            name,
                                                            value: !value,
                                                        },
                                                    });
                                                    fromPhoneField.onChange({
                                                        target: {
                                                            name: fromPhoneField.name,
                                                            value:
                                                                company_phone ||
                                                                "",
                                                        },
                                                    });
                                                    fromEmailField.onChange({
                                                        target: {
                                                            name: fromEmailField.name,
                                                            value:
                                                                company_email ||
                                                                "",
                                                        },
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field label="To" required control="div">
                                    <Dropdown
                                        placeholder="Select Contact"
                                        selectOnBlur={false}
                                        selection
                                        options={[]}
                                        value={null}
                                        fluid
                                        required
                                        disabled
                                        {...(quote.contact_info?.contact_id
                                            ? {
                                                  text: (
                                                      <LinkedAccount
                                                          account={
                                                              quote.contact_name
                                                          }
                                                          maxWidth={"8.25em"}
                                                          subtitle={
                                                              quote.contact_info
                                                                  ?.email
                                                          }
                                                          popup
                                                          linkToAccount={
                                                              quote.contact_name
                                                          }
                                                      />
                                                  ),
                                              }
                                            : {})}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Controller
                                    name="profile_info"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Location
                                            location={
                                                value
                                                    ? quote?.from_information
                                                          ?.location
                                                    : company_location
                                            }
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    control="div"
                                    required
                                    label={
                                        <>
                                            <label
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                Billing/Shipping
                                            </label>
                                            <Popup
                                                content={
                                                    "Quote creation requires an address"
                                                }
                                                trigger={
                                                    <Icon
                                                        className="fas fa-circle-info"
                                                        color="blue"
                                                        style={{
                                                            float: "right",
                                                        }}
                                                    />
                                                }
                                                position="top center"
                                                inverted
                                                size="tiny"
                                            />
                                        </>
                                    }
                                >
                                    <Popup
                                        on="click"
                                        trigger={
                                            <div>
                                                <LocationDisplay
                                                    location={
                                                        contactAddressField.value
                                                    }
                                                />
                                            </div>
                                        }
                                        content={
                                            <QuoteContactAddress
                                                defaultValues={{
                                                    address:
                                                        contactAddressField.value,
                                                    contact_id:
                                                        contactIdField.value,
                                                    contact_name:
                                                        quote.contact_name,
                                                    contact_email:
                                                        quote.contact_info
                                                            ?.email,
                                                }}
                                                onSave={(value) => {
                                                    contactAddressField.onChange(
                                                        {
                                                            target: {
                                                                name: contactAddressField.name,
                                                                value: value?.address,
                                                            },
                                                        }
                                                    );
                                                    contactEmailField.onChange({
                                                        target: {
                                                            name: contactEmailField.name,
                                                            value: value.email,
                                                        },
                                                    });
                                                    contactPhoneField.onChange({
                                                        target: {
                                                            name: contactPhoneField.name,
                                                            value: value.phone,
                                                        },
                                                    });
                                                    setIsUpdatingAddress(false);
                                                }}
                                            />
                                        }
                                        position="bottom center"
                                        open={isUpdatingAddress}
                                        onOpen={() => {
                                            setIsUpdatingAddress(true);
                                        }}
                                        onClose={() => {
                                            setIsUpdatingAddress(false);
                                        }}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Form.Field label="Phone" control="div">
                                    <Controller
                                        control={control}
                                        name="from_phone"
                                        render={({ field: { value } }) => (
                                            <Input disabled value={value} />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field label="Phone" control="div">
                                    <Controller
                                        control={control}
                                        name="contact_phone"
                                        render={({ field: { value } }) => (
                                            <Input disabled value={value} />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Form.Field label="Email" control="div">
                                    <Controller
                                        control={control}
                                        name="from_email"
                                        render={({ field: { value } }) => (
                                            <Input disabled value={value} />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field label="Email" control="div">
                                    <Controller
                                        control={control}
                                        name="contact_email"
                                        render={({ field: { value } }) => (
                                            <Input disabled value={value} />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={4} floated="right">
                    <DateInput
                        disabled
                        dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                        value={DateTimeUtils.changeFormat(
                            DateTimeUtils.convertUnixTimeToDate(
                                quote.quote_created
                            ),
                            "",
                            DateTimeUtils.WORD_DATE_FORMAT
                        )}
                        label="Date"
                    />
                    <Controller
                        control={control}
                        name="expires"
                        render={({
                            field: { name, value, onChange },
                            fieldState: { error },
                        }) => (
                            <Form.Field
                                control={DateInput}
                                dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                value={value}
                                label="Expires On"
                                required
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="quote_status"
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Field
                                control={QuoteStatusField}
                                label="Status"
                                required
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default QuoteDetails;
