export default class FileUtils {
    static convertBase64 = (data=[], toString=false) => {
        return !toString ? Buffer.from(data, 'base64') : data.toString('base64');
    }
    
    static readFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onabort = () => console.log('|+|file reading was aborted')
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        })
    }
}
