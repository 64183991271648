import React from 'react';
import { Modal } from 'dyl-components';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { DateTimeUtils } from 'dyl-components';

const EditDeviceModal = ({
    device,
    onChange,

    open,
    onClose,
    onUpdate,
    deviceBeingUpdated,
    extensions,
    locations
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Edit Device
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Input 
                    name='phone_name'
                    value={device.phone_name}
                    onChange={onChange}
                    label='Name'
                />
                <Form.Select 
                    name='extension_id'
                    value={device.extension_id}
                    onChange={onChange}
                    options={extensions}
                    label='Extension'
                />
                <Form.Input 
                    name='mac_addr'
                    value={device.mac_addr}
                    onChange={onChange}
                    label='Mac ID'
                />
                <Form.Input 
                    name='screen_name'
                    value={device.screen_name}
                    onChange={onChange}
                    label='Display Name'
                />
                <Form.Select 
                    name='location_id'
                    value={device.location_id}
                    onChange={onChange}
                    options={locations}
                    label='Location'
                />
                <Form.Select 
                    name='timezone'
                    value={device.timezone}
                    onChange={onChange}
                    options={DateTimeUtils.generateTimezoneOptions()}
                    label='Timezone'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            {!deviceBeingUpdated && <Button negative onClick={onClose}>Cancel</Button>}
            <Button primary onClick={onUpdate} loading={deviceBeingUpdated}>Update</Button>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = state => ({
    deviceBeingUpdated: state.provision.deviceBeingUpdated,
    locations: state.provision.locations.map(({ id, label }) => ({
        key: id,
        value: id,
        text: label
    })),
    extensions: state.pbx_config.extensions.map(({ id, extension_number }) => ({
        key: id,
        value: id,
        text: extension_number
    }))
})

export default connect(mapStateToProps)(EditDeviceModal);
