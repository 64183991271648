import React, { useEffect, useState } from "react";
import {
    Modal,
    SearchableOptions,
    VALIDATORS,
    generateResolver,
    yup,
    NestedDropdown,
    Notification,
    STATUS_TYPES,
} from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ringGroupActions from "actions/ring_groups";
import { Form } from "semantic-ui-react";
import CustomOptions from "./subcomponents/customOptions";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import "./index.scss";

const typeOptions = [
    {
        text: "Ring Group",
        value: "ringGroup",
    },
    {
        text: "Queue Call",
        value: "queueCall",
    },
];
const ringModeOptions = [
    {
        text: "Ring All",
        value: "ringall",
    },
    {
        text: "Round Robin",
        value: "roundrobin",
    },
];

const isloading = false;

const RingGroupModal = ({ open, setOpen, close }) => {
    const LIMIT = 15;
    const PAGE = 1;

    const defaultData = {
        type: "ringGroup",
        label: "",
        ring_strategy: "",
        ring_time: "",
        audioWhileRinging: "",
        alias: "",
        view: false,
        members: [],
        destination_id: "",
        destination_parent: "",
    };

    const dispatch = useDispatch();

    const {
        destinations,
        extensionsList,
        isReadingExtensionsList,
        unusedExtensions,
        isReadingUnusedExtensions,
        isReadingDestinations,
        count
    } = useSelector((state) => state.pbx_config);

    const { isAddingMembers } = useSelector((state) => state.ring_group);

    const [users, setUsers] = useState({
        data: [],
        isOpen: false,
        count: 0,
    });
    const [search, setSearch] = useState("");
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [internalExtensionOptionsList, setInternalExtensionOptionsList] = useState([]);
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);

    

    const {
        control,
        formState: { isValid, isDirty },
        getValues,
        handleSubmit,
        reset
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData,
        resolver: generateResolver({
            type: VALIDATORS.TEAM_NAME(true).required("This field is required"),
            label: VALIDATORS.TEAM_NAME(true).required(
                "This field is required"
            ),
            ring_strategy: VALIDATORS.TEAM_NAME(true).required(
                "This field is required"
            ),
            ring_time: yup
                .number()
                .typeError("This field is required")
                .required("This field is required"),
            after_destination_id: yup
                .number()
                .typeError("This field is required")
                .required("This field is required"),
        }),
    });

    const [listExtensionsFilters, setListExtensionsFilters] = useState({
        page: PAGE,
        offset: 0,
    });

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations({ exclude: "ivr" }));
        dispatch(pbxConfigActions.getUnusedExtensions({ type: "alias" }));
    }, [dispatch]);

    useEffect(() => {
        const extensionsOptions = unusedExtensions?.map((extension) => ({
            text: extension,
            value: extension,
        }));
        setInternalExtensionOptionsList(extensionsOptions);
    }, [unusedExtensions]);

    useEffect(() => {
        const formatedExtensionsOptions =
            extensionsList?.length &&
            extensionsList?.map(({ extension_number, id, user_id }) => {
                let isSelected=false;
                getValues("members").forEach((selected) => {
                    if (selected.value === id) {
                        isSelected = true;
                    }
                });
                return {
                id,
                name: extension_number,
                user_id,
                value: extension_number,
                isSelected
            }
        });
        setUsers((prevUsers) => ({
            ...prevUsers,
            data: formatedExtensionsOptions,
            count: count,
        }));
    }, [extensionsList, getValues, count]);

    useEffect(() => {
        if (destinations) {
            const destinationTypesAux = [
                ...PhoneUtil.DESTINATION_TYPES_OPTIONS,
            ];
            const destinationOptionsAux = destinationTypesAux.map(
                (destinationType) => {
                    const { key } = destinationType;
                    const options =
                        destinations[key]?.map(({ destination_id, label }) => {
                            return {
                                key: destination_id,
                                value: destination_id,
                                text: label,
                            };
                        }) || [];
                    return { ...destinationType, options };
                }
            );
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    const searchFunction = async () => {
        callApiToGetContactOptions();
        setIsSearchResultsVisible(true);
        setUsers({
            ...users,
            isOpen: true,
        });
    };
    const cancelFunction = async () => {
        setUsers({ data: [], isOpen: false });
        setSearch("");
        setIsSearchResultsVisible(false);
    };

    const callApiToGetContactOptions = () => {
        if (search.length > 2) {
            const params = {
                search,
                page: PAGE,
                limit: LIMIT,
                active: true,
            };
            dispatch(pbxConfigActions.getListOfExtensions(params));
            setListExtensionsFilters({
                ...listExtensionsFilters,
                page: PAGE,
            });
        }
    };

    const checkSelectedContacts = (
        status,
        selected_contacts,
    ) => {
        const allContacts = [...users.data];
        const selectedContacts = selected_contacts ?? getValues("members");
        allContacts.forEach((contact) => {
            if (!selectedContacts.length) contact.isSelected = false;
            selectedContacts.forEach((selected) => {
                if (selected.value === contact.id) {
                    contact.isSelected = status;
                }
            });
        });

        setUsers({ ...users, data: allContacts });
    };

    const onSubmit = async (data) => {
        try {
            if (getValues("type") === "ringGroup") {
                const formateddata = {
                    ...data,
                    members: data.members.map((member) => member.value),
                };
                await dispatch(
                    ringGroupActions.addRingGroupMembers(
                        [formateddata],
                        null,
                        null
                    )
                );
                Notification.alert(
                    "Successfully created Ring Group!",
                    STATUS_TYPES.SUCCESS,
                    true
                );
                reset();
                setSearch("");
                close(false);
            } else {
                alert("Call Queue coming soon!");
            }
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to create Ring Group",
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            className="RingGroup_Modal"
        >
            <Modal.Header>Create Ring Group/Call Queue</Modal.Header>
            <Modal.Content style={{ minHeight: "27rem" }}>
                <Form size="small" className="callQueue" loading={isloading}>
                    <Form.Group>
                        <Controller
                            name={`type`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Type"
                                    placeholder={`Select type`}
                                    selectOnBlur={false}
                                    options={typeOptions}
                                    width={4}
                                    required
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Controller
                            name={`label`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    placeholder="Enter name"
                                    label="Name"
                                    width={4}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={`ring_strategy`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Ring Mode"
                                    placeholder={`Select Ring Mode`}
                                    selectOnBlur={false}
                                    options={ringModeOptions}
                                    width={4}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={`ring_time`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Ring Duration"
                                    placeholder={`Select Ring Duration`}
                                    selectOnBlur={false}
                                    options={PhoneUtil.RING_DURATION_OPTIONS}
                                    width={4}
                                    required
                                />
                            )}
                        />

                        <Controller
                            name={`destination_parent`}
                            control={control}
                            render={({
                                field: {
                                    name: parentName,
                                    value: parentValue,
                                    onChange: onParentChange,
                                },
                            }) => (
                                <Controller
                                    name={`after_destination_id`}
                                    control={control}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => {
                                        const {
                                            name: childName,
                                            value: childValue,
                                            onChange: onChildChange,
                                        } = field;
                                        return (
                                            <Form.Field
                                                control={NestedDropdown}
                                                child_value={childValue}
                                                parent_value={parentValue}
                                                loading={isReadingDestinations}
                                                nested_options={
                                                    destinationOptions
                                                }
                                                onChange={(
                                                    _,
                                                    {
                                                        parent_value,
                                                        child_value,
                                                    }
                                                ) => {
                                                    onParentChange({
                                                        target: {
                                                            name: parentName,
                                                            value: parent_value,
                                                        },
                                                    });
                                                    onChildChange({
                                                        target: {
                                                            name: childName,
                                                            value: child_value,
                                                        },
                                                    });
                                                }}
                                                placeholder="Select destination"
                                                display_parent
                                                selection
                                                label="No Answer Destination"
                                                pointing="top"
                                                required={true}
                                                error={error?.message}
                                                className="no_answer_destinations_nested_dropdown"
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Controller
                            name={`audioWhileRinging`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Audio While Ringing"
                                    placeholder={`Select Audio`}
                                    selectOnBlur={false}
                                    options={[]}
                                    width={4}
                                />
                            )}
                        />
                        <Controller
                            name={`alias`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    loading={isReadingUnusedExtensions}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Internal Extension"
                                    placeholder={`Select Extension`}
                                    selectOnBlur={false}
                                    options={internalExtensionOptionsList}
                                    width={4}
                                />
                            )}
                        />
                        <Controller
                            name="view"
                            control={control}
                            render={({ field: { onChange, name, value } }) => {
                                return (
                                    <Form.Checkbox
                                        name={name}
                                        className="displayOnOffClass"
                                        checked={value}
                                        toggle
                                        label="Display on Office View"
                                        onChange={(_, { checked }) => {
                                            onChange({
                                                target: {
                                                    name,
                                                    value: checked,
                                                },
                                            });
                                        }}
                                    />
                                );
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="extension-container">
                        <Controller
                            name={`members`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => {
                                return (
                                    <SearchableOptions
                                        label="Extension"
                                        loading={isReadingExtensionsList}
                                        search={search}
                                        searchFunction={searchFunction}
                                        cancelSearchFunction={cancelFunction}
                                        searchPlaceholder={
                                            "Search extensions and users"
                                        }
                                        width={8}
                                        values={value}
                                        onChangeSearch={(_, { value }) => {
                                            setIsSearchResultsVisible(false);
                                            setSearch(value);
                                        }}
                                        onChangeSelected={(
                                            _,
                                            { value: removedContact }
                                        ) => {
                                            checkSelectedContacts(false, [
                                                removedContact,
                                            ]);
                                            onChange({
                                                target: {
                                                    name,
                                                    value: value.filter(
                                                        (item) =>
                                                            item !==
                                                            removedContact
                                                    ),
                                                },
                                            });
                                        }}
                                        isSearchResultsVisible={isSearchResultsVisible}
                                        renderSearchResults={
                                            <CustomOptions
                                                addClick={(extension) => {
                                                    onChange({
                                                        target: {
                                                            name,
                                                            value: [
                                                                ...value,
                                                                extension,
                                                            ],
                                                        },
                                                    });
                                                    checkSelectedContacts(true);
                                                }}
                                                contactsList={users?.data || []}
                                                loading={
                                                    isReadingExtensionsList
                                                }
                                                isVisible={
                                                    search?.length>0 && users?.data?.length > 0
                                                }
                                            />
                                        }
                                        isPaginationVisible={users?.data?.length > 0}
                                        paginationProps={{
                                            boundaryRange: 0,
                                            activePage:
                                                listExtensionsFilters.page,
                                            ellipsisItem: null,
                                            siblingRange: 2,
                                            totalPages: Math.ceil(
                                                users.count / LIMIT
                                            ),
                                            onPageChange: async (
                                                _,
                                                { activePage }
                                            ) => {
                                                setListExtensionsFilters({
                                                    ...listExtensionsFilters,
                                                    page: activePage,
                                                });
                                                dispatch(
                                                    pbxConfigActions.getListOfExtensions(
                                                        {
                                                            page: activePage,
                                                            limit: LIMIT,
                                                        }
                                                    )
                                                );
                                            },
                                        }}
                                    />
                                );
                            }}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>

            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(onSubmit)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: isAddingMembers }}
            />
        </Modal>
    );
};

export default RingGroupModal;
